import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { validateForm } from 'utils/formValidator'
import { createBatch } from 'features/batch/batchAction'
import 'component/dailyRoaster/AddDuty.scss'
import { getFSAZonesForBatch, getUTCTime, convertTimeToSeconds, getOptionsForSelectField, getUserDCOption } from 'utils/common';
import { fetchFSAZonesForBatches, movePackagesToBatch } from 'features/batch/batchAction'
import { getDataCentersList } from 'utils/userAccountHelper'
import { CustomModal } from 'component/common/Modal/CustomModal'
import useFieldChange from 'hooks/useFieldChange';
import './createBatchModal.scss'
import { fetchBusinessDetails } from 'features/manifestManagement/manifestManagementAction';
import { CustomForm } from 'component/common/Form/CustomForm'
import { getPackageStatusForCreateBatch, getBatchDetails, getPackagesNotInBatch } from 'utils/batchHelper'

const formInitialState = {
    capacity: '',
    fsaZone: '',
    bussinessName: '',
    dcName: '',
    routeStartTime: '',
    totalRouteTime: '',
    maxStops: '',
    sla: '',
    highPriority: '',
    highValueItem: '',
    packageStatus: '',
    batchIds: '',
    packages: [],
    radioType: 'create-new-batch'
}

const checkBoxOptions = {
    sla: 'SLA',
    highPriority: 'High Priority',
    highValueItem: 'High Value Item',
};

export const CreateBatch = ({ isModelOpen, handleClose, userTimeZone, selectedBatchIds, type }) => {
    const dispatch = useDispatch();
    const [formValues, setFormValues, handleFieldChange] = useFieldChange(formInitialState);
    const [formError, setFormErrors] = useState({});

    const { loading, fsaZones, fsaZoneBatchesLoading, batchSuccess, bacthPackageDetails } = useSelector(
        (state) => state?.batches
    )

    const { businessNames } = useSelector(
        (state) => state?.manifestManagement
    )
    const { dataCenters } = useSelector(
        (state) => state?.userManagement
    )
    const { user } = useSelector(
        (state) => state?.auth
    )

    const batches = getBatchDetails(bacthPackageDetails)
     const fsaZoneOptions = getFSAZonesForBatch(fsaZones)
    
     useEffect(() => {
        dispatch(fetchBusinessDetails())
    }, [])

    useEffect(() => {
        if (batchSuccess) {
            handleClose()
        }
    }, [batchSuccess]);

    useEffect(() => {
        if(fsaZones?.length){
            setFormValues((prevValues) => ({
                ...prevValues,
                fsaZone: fsaZoneOptions,
            }));
        }
    }, [fsaZones]);

    const handleBusinessNameSelect = () => {
        setFormValues((prevValues) => ({
            ...prevValues,
            fsaZone: '',
        }));
        if (formValues?.bussinessName?.length) {
            const bussinessNames = formValues?.bussinessName?.map(item => item.value)?.join(',')
            dispatch(fetchFSAZonesForBatches({
                businessNames: bussinessNames
            }))
        }
    }

    useEffect(() => {
        handleBusinessNameSelect()
    }, [formValues?.bussinessName])

    const handleDateChange = (date) => {
        setFormValues((prevValues) => ({
            ...prevValues,
            routeStartTime: date,
        }));
    };

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: checked,
        }));
    };

    const optionsWithSelectAll = [
        { label: "Select All", value: "ALL" },
        ...fsaZoneOptions
    ];

    const handleSelectChange = (event) => {
        const { name, value } = event?.target;
        if (!value) {
            setFormValues((prevValues) => ({
                ...prevValues,
                [name]: [],
            }));
            return;
        }

        // Check if "Select All" was chosen
        const isSelectAll = value?.some(option => option.value === "ALL");

        if (isSelectAll) {
            setFormValues((prevValues) => ({
                ...prevValues,
                [name]: fsaZoneOptions,
            }));
        } else {
            setFormValues((prevValues) => ({
                ...prevValues,
                [name]: value,
            }));
        }
    };

    const handleFormSubmit = () => {
        const notRequiredFields = ['totalRouteTime', 'maxStops', 'sla', 'highPriority', 'highValueItem', 'packageStatus', 'dcName'];

        switch (type) {
            case 'merge':
                notRequiredFields.push('capacity', 'dcName', 'bussinessName', 'fsaZone', 'packages', 'batchIds');
                break;
            case 'move':
                notRequiredFields.push('capacity', 'dcName', 'bussinessName', 'fsaZone');
                if (formValues?.radioType === 'create-new-batch') {
                    notRequiredFields.push('batchIds');
                }
                if (formValues?.radioType === 'move-to-existing') {
                    notRequiredFields.push('routeStartTime');
                }
                break;
            case 'create':
                notRequiredFields.push('packages', 'batchIds');
                break;
            default:
                break;
        }

        const errors = validateForm(formValues, notRequiredFields);

        if (parseInt(formValues.maxStops) > parseInt(formValues.capacity)) {
            errors.maxStops = 'Max stops cannot be greater than capacity';
        }
        setFormErrors(errors)
        if (!Object.keys(errors)?.length) {
            setFormErrors({})
            if (type === 'merge') {
                dispatch(createBatch({
                    routeStartTime: getUTCTime(formValues?.routeStartTime, userTimeZone),
                    batchIds: selectedBatchIds,
                    methodType: 'put'
                }))
            }
            else if (type === 'move') {
                const selectedPackages = formValues?.packages?.length ? formValues?.packages?.map(item => item.value) : [];
                if (formValues?.radioType === 'move-to-existing') {
                    const params = {
                        batchId: formValues?.batchIds?.value,
                        trackingNumbers: selectedPackages,
                        isBatchMapView: false
                    }
                    dispatch(movePackagesToBatch(params))
                }
                else {
                    const params = {
                        routeStartTime: getUTCTime(formValues?.routeStartTime, userTimeZone),
                        trackingNumbers: selectedPackages,
                        methodType: 'put'
                    }
                    dispatch(createBatch(params))
                }
            }
            else {
                const fsaZoneList = formValues?.fsaZone?.length ? formValues?.fsaZone?.map(item => item.value) : [];
                const bussinessNameList = formValues?.bussinessName?.length ? formValues?.bussinessName?.map(item => item.value) : [];
                const packageStatusList = formValues?.packageStatus?.length ? formValues?.packageStatus?.map(item => item.value) : [];
                const totalRouteTime = convertTimeToSeconds(formValues?.totalRouteTime)
                const data = {
                    fsaZones: fsaZoneList,
                    bussinessNames: bussinessNameList,
                    vehicleCapacity: parseInt(formValues?.capacity),
                    dcName: getUserDCOption(user)?.value,
                    routeStartTime: getUTCTime(formValues?.routeStartTime, userTimeZone),
                    totalTime: totalRouteTime,
                    maxStops: formValues?.maxStops,
                    sla: formValues.sla ? true : '',
                    highPriority: formValues.highPriority ? true : '',
                    highValueItem: formValues.highValueItem ? true : '',
                    packageStatus: packageStatusList
                }
                dispatch(createBatch(data))
            }

        }
    }


    const formProps = {
        fields: (() => {
            const baseFields = [
                {
                    label: 'Route Start Time',
                    name: 'routeStartTime',
                    required: true,
                    type: 'datetime',
                    value: formValues?.routeStartTime || '',
                    handleFieldChange: handleDateChange,
                    width: '18rem',
                    error: formError?.routeStartTime,
                    timeZone: userTimeZone
                }
            ];

            switch (type) {
                case 'merge':
                    return baseFields;
                case 'move':
                    return [
                        {
                            type: 'radio',
                            name: 'radioType',
                            label: 'Select Operation',
                            options: [
                                { label: 'Create New Batch', value: 'create-new-batch' },
                                { label: 'Move Packages to Existing Batch', value: 'move-to-existing' }
                            ],
                            value: formValues?.radioType,
                            handleChange: handleFieldChange,
                            required: true,
                            error: formError?.radioType
                        },
                        formValues?.radioType === 'move-to-existing' && {
                            label: 'Batch Name',
                            name: 'batchIds',
                            required: true,
                            type: 'select',
                            options: batches,
                            value: formValues?.batchIds || '',
                            handleFieldChange: handleFieldChange,
                            error: formError?.batchIds,
                            width: '18rem',
                            menuPlacement: 'bottom'
                        },
                        formValues?.radioType === 'create-new-batch' && {
                            label: 'Route Start Time',
                            name: 'routeStartTime',
                            required: true,
                            type: 'datetime',
                            value: formValues?.routeStartTime || '',
                            handleFieldChange: handleDateChange,
                            width: '18rem',
                            error: formError?.routeStartTime,
                            timeZone: userTimeZone
                        },
                        {
                            label: 'Packages',
                            name: 'packages',
                            required: true,
                            type: 'select',
                            options: bacthPackageDetails ? getPackagesNotInBatch(bacthPackageDetails, formValues?.batchIds?.value) : [],
                            value: formValues?.packages || '',
                            handleFieldChange: handleFieldChange,
                            error: formError?.packages,
                            width: '18rem',
                            isMultiselect: true,
                            menuPlacement: 'bottom',
                            // menuPosition={'fixed'} 
                            // menuPlacement={'bottom'}
                        }
                    ]
                default:
                    return [
                        ...baseFields,
                        {
                            label: 'Business Name',
                            name: 'bussinessName',
                            type: 'select',
                            options: getOptionsForSelectField(businessNames),
                            value: formValues?.bussinessName || '',
                            handleFieldChange: handleFieldChange,
                            error: formError?.bussinessName,
                            width: '18rem',
                            isMultiselect: true,
                            required: true,
                        },
                        {
                            label: 'FSA Zone',
                            name: 'fsaZone',
                            required: true,
                            type: 'select',
                            options: optionsWithSelectAll,
                            value: formValues?.fsaZone || '',
                            handleFieldChange: handleSelectChange,
                            error: formError?.fsaZone,
                            width: '18rem',
                            isMultiselect: true,
                            isLoading: fsaZoneBatchesLoading,
                            isDisabled: !formValues?.bussinessName
                        },
                        {
                            label: 'DC',
                            name: 'dcName',
                            required: true,
                            type: 'select',
                            options: getDataCentersList(dataCenters),
                            value: getUserDCOption(user),
                            handleFieldChange: handleFieldChange,
                            error: formError?.dcName,
                            width: '18rem',
                            isDisabled: true
                        },
                        {
                            label: 'Status',
                            name: 'packageStatus',
                            type: 'select',
                            options: getPackageStatusForCreateBatch(),
                            value: formValues?.packageStatus || '',
                            handleFieldChange: handleFieldChange,
                            error: formError?.packageStatus,
                            width: '18rem',
                            isMultiselect: true,
                        },
                        {
                            label: 'Capacity',
                            name: 'capacity',
                            value: formValues?.capacity || '',
                            handleFieldChange: handleFieldChange,
                            width: '18rem',
                            error: formError?.capacity,
                            min: "1",
                            type: 'number',
                            step: "1",
                            required: true
                        },
                        {
                            label: 'Max Stops',
                            name: 'maxStops',
                            value: formValues?.maxStops || '',
                            handleFieldChange: handleFieldChange,
                            width: '18rem',
                            error: formError?.maxStops,
                            min: "1",
                            type: 'number',
                            step: "1"
                        },
                        {
                            label: 'Route Time',
                            name: 'totalRouteTime',
                            value: formValues?.totalRouteTime || '',
                            handleFieldChange: handleFieldChange,
                            width: '18rem',
                            error: formError?.totalRouteTime,
                            type: 'text',
                            placeholder: 'eg: 02:12'
                        },
                        {
                            type: 'checkbox',
                            options: checkBoxOptions,
                            value: formValues,
                            handleChange: handleCheckboxChange,
                            width: '18rem'
                        }
                    ];
            }
        })(),
        buttons: [
            {
                label: 'Submit',
                onClick: handleFormSubmit,
                'data-testid': "button-save"
            },
            {
                label: 'Cancel',
                onClick: handleClose,
                color: 'error',
                'data-testid': "button-cancel"
            }
        ],
        loading: loading,
        fieldGap: '0px',
        margin: '0px',
        padding: '0rem 1rem',
        fieldAlignment: 'center',
        allowOverflow: type === 'create' ? 'auto' : false
    };

    return (
        <div>
            <CustomModal
                open={isModelOpen}
                onClose={handleClose}
            >
                <div className='add-duty-modal'>
                    <div><span className='title'>{type === 'create' ? 'Create Batch' : 'Update Batch'}</span></div>
                    <CustomForm {...formProps} />
                </div>
            </CustomModal>
        </div>
    )

}