import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Checkbox, FormControlLabel } from '@mui/material';
import { fetchWarehouseData, resetWarehouseData, fetchWarehouseAsset, freezeOperation } from 'features/warehouseOperation/warehouseOperationAction'
import { Layout } from 'component/common/Layout/Layout';
import { CardDetail } from 'component/dashboard/cardDetail'
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Loader } from 'component/common/Loader/Loader'
import { ResponseModal } from 'component/common/Modal/ResponseModal'
import { PrimaryButton } from 'component/common/Button/Button.jsx'
import { AddAsset } from './AddAsset';
import { AssignAsset } from './AssignAsset';
import { ConfirmationModal } from 'component/common/Modal/ConfirmationModal'
import { resetData } from 'features/warehouseOperation/warehouseOperationSlice'
import WarehouseLayout from './WarehouseLayout';
import './WarehouseOperations.scss'

export const WarehouseOperation = () => {
    const dispatch = useDispatch();
    const { warehouseData, error, loading, success, updateSuccess, warehouseAsset } = useSelector(
        (state) => state?.warehouseOperation
    )
    const [modalProps, setModalProps] = useState(null)
    const [addAssetModalOpen, setAddAssetModalOpen] = useState(null)
    const [assignAssetModalOpen, setAssignAssetModalOpen] = useState(null)
    const [confirmationModalProps, setConfirmationModalProps] = useState(null);

    const fetchData = () => {
        dispatch(fetchWarehouseData())
        dispatch(fetchWarehouseAsset())
    }

    useEffect(() => {
        fetchData()
    }, [])

    useEffect(() => {
        if (error) {
            setModalProps(
                {
                    title: error?.title,
                    message: error?.detail,
                    open: true,
                    type: error
                }
            )
        }
    }, [error]);



    useEffect(() => {
        if (success) {
            fetchData()
            dispatch(resetData())
        }
    }, [success]);

    useEffect(() => {
        if (updateSuccess) {
            setModalProps(
                {
                    title: 'Success',
                    message: 'Assets updated successfully',
                    open: true,
                    type: 'success'
                }
            )
            const redirectTimer = setTimeout(() => {
                if (addAssetModalOpen) {
                    handleAddAssetModalClick()
                }
                if (assignAssetModalOpen) {
                    handleAssignAssetModalClick()
                }
                dispatch(resetData())
                fetchData()
                setModalProps(null)
            }, 2000);
            return () => {
                clearTimeout(redirectTimer);
            }
        }
    }, [updateSuccess]);

    const handleAddAssetModalClick = () => {
        setAddAssetModalOpen(!addAssetModalOpen)
    }

    const handleAssignAssetModalClick = () => {
        setAssignAssetModalOpen(!assignAssetModalOpen)
    }

    const handleCheckBoxClick = (event) => {
        const queryParam = event?.target?.checked ? 'freeze' : 'unfreeze'
        setConfirmationModalProps({
            title: `Freeze Warehouse Layout`,
            message: `Do you want to freeze this layout?`,
            open: true,
            onConfirm: () => {
                dispatch(freezeOperation({ operation: queryParam }));
                handleConfirmationClose();
            },
            onClose: handleConfirmationClose,
        });
    };

    const handleResetClick = () => {
        setConfirmationModalProps({
            title: `Reset Warehouse Layout`,
            message: `Do you want to reset this layout?`,
            open: true,
            onConfirm: () => {
                dispatch(resetWarehouseData());
                handleConfirmationClose();
            },
            onClose: handleConfirmationClose,
        });
    };

    const handleConfirmationClose = () => {
        setConfirmationModalProps(null)
    }

    return (
        <Layout showBackArrow={false} headerTitle={<div className='title-container'>Warehouse Operations
            <div>
                <FormControlLabel
                    label='Freeze'
                    control={
                        <Checkbox
                            checked={warehouseAsset?.isInductionProcessFreeze}
                            onChange={(event) => handleCheckBoxClick(event)}
                            sx={{ p: '5px' }}
                            disabled={warehouseAsset?.isInductionProcessFreeze}
                        />
                    }
                />
            </div>
        </div>} action={<>

            <div><PrimaryButton type="button" variant='primary' label={'Add Asset'} height={'30px'} onClick={handleAddAssetModalClick} /> </div>
            <div><PrimaryButton type="button" variant='primary' label={'Reset'} height={'30px'} onClick={handleResetClick} /> </div>
        </>}>
            <CardDetail>
                <div className='wrapper'>
                    <div className='label-container'><span>West</span>
                        <span style={{ paddingRight: '12rem' }}>North</span>
                        <span>East</span>
                    </div>
                    <DndProvider backend={HTML5Backend}>
                        <WarehouseLayout warehouseData={warehouseData} warehouseAsset={warehouseAsset} />
                    </DndProvider>
                    <div className='label-container'><span>West</span>
                        <span style={{ paddingRight: '12rem' }}>South</span>
                        <span>East</span>
                    </div>
                </div>
                {modalProps ? <ResponseModal {...modalProps}
                handleClose={() => {
                    dispatch(resetData())
                    setModalProps(null);
                  }}
                /> : null}
                {loading ? <Loader isLoading={loading} /> : null}
                {addAssetModalOpen ? <AddAsset isModelOpen={addAssetModalOpen} handleClose={handleAddAssetModalClick} /> : null}
                {assignAssetModalOpen ? <AssignAsset isModelOpen={assignAssetModalOpen} handleClose={handleAssignAssetModalClick} /> : null}

            </CardDetail>
            {confirmationModalProps ? (
                <ConfirmationModal {...confirmationModalProps} />
            ) : null}
        </Layout >
    )
}