import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { firebaseConfig, vapidKey } from 'config/firebase';
import { addFcmMessage } from 'features/notification/notificationSlice';

// Initialize Firebase app
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const initializeFirebaseMessaging = (dispatch) => {
    Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
            console.log('Notification permission granted.');
            getFCMToken();
            setupMessageListener(dispatch);
        } else {
            console.log('Notification permission denied.');
        }
    });
};

export const getFCMToken = async () => {
    try {
        if (!('serviceWorker' in navigator)) {
            console.error('Service workers are not supported in this browser.');
            return;
        }

        const registration = await navigator.serviceWorker.ready;

        const currentToken = await getToken(messaging, {
            vapidKey: vapidKey,
            serviceWorkerRegistration: registration
        });

        if (currentToken) {
            console.log('FCM Token received');
            localStorage.setItem('fcmToken', currentToken);
        } else {
            console.log('No FCM token available.');
        }
    } catch (error) {
        console.error('Error getting FCM token:', error);
    }
};

export const setupMessageListener = (dispatch) => {
    onMessage(messaging, (payload) => {
        const message = {
            data: payload?.data
        }
        dispatch(addFcmMessage(message))
    });

    // Listen for messages from the service worker
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.addEventListener('message', (event) => {
            if (event.data && event.data.type === 'BACKGROUND_NOTIFICATION') {
                const message = {
                    data: event?.data?.payload?.data
                }
                dispatch(addFcmMessage(message));
            }
        });
    }
};

export default messaging;
