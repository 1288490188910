import React from "react";
import { useDrag } from "react-dnd";
import { ItemTypes } from "./ItemTypes";
import { getToteColor } from 'utils/warehouseOperationHelper'
import { styled } from "styled-components";
import Tooltip from "@mui/material/Tooltip";

const DraggableContainer = styled.div`
    opacity: ${(props) => (props.isDragging ? 0.5 : 1)};
    color: #000000;
    padding: 4px 8px;
    margin: 3px;
    border-radius: 4px;
    text-align: center;
    cursor: move;
    display: inline-block;
    font-family: "degular", sans-serif;
    letter-spacing: 0px;
    font-size: 10px;
    font-weight: 700;
    cursor: pointer;
    border: 2px solid #1A1A1A; 
    background-color: ${(props) => (props.bgColor ? props.bgColor : "unset")};
`;

const DraggableItem = ({ data, type, showPackage = false }) => {
    const [{ isDragging }, drag] = useDrag({
        type: type,
        item: data,
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    return (
        <Tooltip title={`${data?.totalPackages} Packages`} open={showPackage ? undefined : false}>
            <DraggableContainer
                ref={drag}
                isDragging={isDragging}
                type={type}
                bgColor={showPackage && getToteColor(data?.toteStatus)}
            >
                {type === ItemTypes?.ROLLER_CAGE ? data?.cageName : data?.toteNumber}
            </DraggableContainer>
        </Tooltip>
    );
};

export default DraggableItem;
