import { createSlice } from '@reduxjs/toolkit';
import { fetchEligibleRollerCages, fetchUnAssignedRollerCages, fetchWarehouseAsset, fetchTotesData, freezeOperation, addTote, resetWarehouseData, updateTote, fetchWarehouseData, fetchEmptyLocations, addRollerCage, relocateTote, relocateRollerCage } from './warehouseOperationAction'
import { logout } from 'features/auth/authAction'

const initialState = {
    unAssignedRollerCages: [],
    totes: [],
    totesCount: 0,
    loading: false,
    error: null,
    success: false,
    toteFilter: {},
    warehouseData: {},
    emptyLocations: [],
    aisel: [],
    eligibleRollerCages:[],
    warehouseAsset : {},
    updateSuccess : false
}

// Redux slice for warehouse operation
const warehouseOperationSlice = createSlice({
    name: 'warehouseOperation',
    initialState,
    reducers: {
        resetData: (state) => {
            state.success = false;
            state.error = null;
            state.updateSuccess = false
        },
        setToteFilter: (state, action) => {
            state.toteFilter = action.payload;
        },
        resetToteFilter: (state, action) => {
            state.toteFilter = {};
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUnAssignedRollerCages.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchUnAssignedRollerCages.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.unAssignedRollerCages = action?.payload
            })
            .addCase(fetchUnAssignedRollerCages.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            })

            .addCase(fetchEligibleRollerCages.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchEligibleRollerCages.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.eligibleRollerCages = action?.payload
            })
            .addCase(fetchEligibleRollerCages.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            })

            .addCase(fetchTotesData.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchTotesData.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.totes = action?.payload?.totes
                state.totesCount = action?.payload?.totalCount
            })
            .addCase(fetchTotesData.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            })

            .addCase(addTote.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(addTote.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.updateSuccess = true
            })
            .addCase(addTote.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            })

            .addCase(resetWarehouseData.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(resetWarehouseData.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.updateSuccess = true
            })
            .addCase(resetWarehouseData.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            })
            
            .addCase(updateTote.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateTote.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.success = true
            })
            .addCase(updateTote.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            })

            //Warehouse data
            .addCase(fetchWarehouseData.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchWarehouseData.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.warehouseData = action?.payload
            })
            .addCase(fetchWarehouseData.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            })

            //Warehouse asset
            .addCase(fetchWarehouseAsset.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchWarehouseAsset.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.warehouseAsset = action?.payload
            })
            .addCase(fetchWarehouseAsset.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            })

            .addCase(freezeOperation.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(freezeOperation.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.updateSuccess = true
            })
            .addCase(freezeOperation.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            })
            
            //Empty location data
            .addCase(fetchEmptyLocations.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchEmptyLocations.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.emptyLocations = action?.payload
            })
            .addCase(fetchEmptyLocations.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            })

            //addrollercage
            .addCase(addRollerCage.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(addRollerCage.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.updateSuccess = action?.payload
            })
            .addCase(addRollerCage.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            })


            //relocateRollerCage
            .addCase(relocateRollerCage.pending, (state) => {
                state.error = null;
            })
            .addCase(relocateRollerCage.fulfilled, (state, action) => {
                state.error = null;
                state.success = action?.payload
            })
            .addCase(relocateRollerCage.rejected, (state, action) => {
                state.error = action?.payload;
            })

            //relocateTote
            .addCase(relocateTote.pending, (state) => {
                state.error = null;
            })
            .addCase(relocateTote.fulfilled, (state, action) => {
                state.error = null;
                state.success = action?.payload
            })
            .addCase(relocateTote.rejected, (state, action) => {
                state.error = action?.payload;
            })

            .addCase(logout.fulfilled, (state) => {
                return initialState;
            })

    }
});

export const { resetData, resetToteFilter, setToteFilter } = warehouseOperationSlice.actions;
export default warehouseOperationSlice.reducer;
