export const customStrings = {
    ORDER_RECEIVED: 'Order details received',
    INDUCTED: 'Parcel recieved at facility',
    PROCESSED_STREET_PERFECT_CORRECTED: 'Address successfully validated and standardized',
    LAT_LNG_RESOLVE_SUCCESS_FROM_HERE: 'Address geocoding was resolved by HERE',
    INPROGRESS: 'Parcel in transit',
    CANCELLED: 'The order was cancelled by customer',
    DELIVERED: 'Parcel delivered to consignee',
    NOT_DELIVERED: 'Parcel could not be delivered',
    PROCESSED_STREET_PERFECT_NOT_CORRECTED: 'Address was correct as per Canada Post',
    PARSED: 'Parcel Parsed',
    PARSE_ERROR_MANIFEST_CLIENT: 'Parse Error Manifest Client',
    PARSE_ERROR_MANIFEST_STREET_PERFECT: 'Parse Error Street Perfect',
    PARSE_ERROR_MANIFEST_STREET_PERFECT_MISMATCH: 'Parse Error Manifest Street Perfect Mismatch',
    DUPLICATE_MANIFEST_CLIENT: 'Duplicate Order',
    DUPLICATE_MANIFEST_STREET_PERFECT: 'Duplicate Street Perfect',
    PROCESSED_STREET_PERFECT_MARKED_BAD: 'Address unsuccessfully validated and standardized',
    ORDER_MANUAL_ADDRESS_PROCESSED: 'Address manually validated and standardized',
    LAT_LNG_RESOLVE_FAILED: 'Address geocoding was unsuccessful HERE',
    PROCESSED_MANUAL_CORRECTED: 'Manual Corrected',
    OVERAGE: 'Overage',
    OVERSIZE_NOT_SCANNED: 'Oversize Not Scanned',
    OVERSIZE_SCANNED: 'Oversize Scanned',
    SEQUENCED: 'Batched',
    ROUTE_ASSIGNED: 'Routed',
    SCAN_AT_ROUTE_STOP: 'Scan At Route Stop',
    SCAN_AT_RESCUE_STOP: 'Scanned At Rescue Stop',
    FINAL_DELIVERY_SCAN: 'Final Delivery Scan',
    // RETURNED: 'Parcel Returned',
    DAMAGED: 'Parcel is Damaged',
    PARCEL_DEL_SUCC_SD: 'Safe Dropped',
    PARCEL_DEL_SUCC_SD_SIDE: 'Safe Dropped at Side Door',
    PARCEL_DEL_SUCC_SD_REAR: 'Safe Dropped at Rear Door',
    PARCEL_DEL_SUCC_SD_LOCKER: 'Safe Dropped in Locker',
    PARCEL_DEL_SUCC_CSGNE: 'Signed by Consignee',
    PARCEL_DEL_SUCC_CSGNE_ADULT: 'Signed by Consignee (Adult)',
    PARCEL_DEL_SUCC_CSGNE_TP: 'Signed by Third Party',
    PARCEL_DEL_SUCC_CSGNE_SEC: 'Signed by Security',
    PARCEL_DEL_SUCC_CSGNE_CRG: 'Signed by Concierge',
    PARCEL_DEL_SUCC_CSGNE_REC: 'Signed by Receptionist',
    RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_SD_VL: 'Safe Drop - Vulnerable Location',
    RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_SD_THEFT: 'Safe Drop - High Theft Probability',
    RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_SD_OTHER: 'Safe Drop - Other',
    RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_LOC_ID: 'Could Not Locate - Missing Unit/Apt/Suite Details',
    RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_LOC_ENTR: 'Could Not Locate - Could Not Find Entrance',
    RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_LOC_ADDRS: 'Could Not Locate - Bad Address Information',
    RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_LOC_NAV: 'Could Not Locate - Bad Navigation Instructions',
    RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_LOC_OTHER: 'Could Not Locate - Other',
    RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_ACC_BLCK: 'Could Not Access - Blocked Entrance',
    RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_ACC_CODE: 'Could Not Access - Missing Buzzer or Call Box Information',
    RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_ACC_PRK: 'Could Not Access - Parking Unavailable',
    RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_ACC_UNSAFE: 'Could Not Access - Unsafe Road Conditions',
    RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_ACC_ENVMT: 'Could Not Access - Unsafe Environmental Conditions',
    RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_ACC_OTHER: 'Could Not Access - Other',
    RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_REJ_RSC_SIG: 'Rejected - Receptionist/Security/Concierge Refused Signature',
    RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_REJ_RSC_PARCEL: 'Rejected - Receptionist/Security/Concierge Refused Parcel',
    RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_REJ_RSC_ID: 'Rejected - Receptionist/Security/Concierge Cannot Find Unit/Apt/Suite Details',
    RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_REJ_RSC_ADDRS: 'Rejected - Receptionist/Security/Concierge Cannot Confirm Customer Details',
    RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_REJ_RSC_OTHER: 'Rejected - Receptionist/Security/Concierge Other',
    RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_REJ_TP_SIG: 'Rejected - Third Party Refused Signature',
    RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_REJ_TP_PARCEL: 'Rejected - Third Party Refused Parcel',
    RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_REJ_TP_DAMAGED: 'Rejected - Third Party Declared Parcel Damage',
    RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_REJ_TP_OTHER: 'Rejected - Third Party Other',
    RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_REJ_CSGNE_ADULT: 'Rejected - Consignee Adult Signature Required',
    RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_REJ_CSGNE_DAMAGED: 'Rejected - Consignee Declared Parcel Damage',
    RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_REJ_CSGNE_OTHER: 'Rejected - Consignee Refused Parcel',
    RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_ATT_TIME: 'Could Not Attempt - Route Service Time Expired',
    RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_ATT_ENVMT: 'Could Not Attempt - Poor Weather Conditions',
    RTERMINAL_ATT_INVALID_PARCEL_DEL_UNSC_ATT_CANCEL: 'Could Not Attempt - Route Cancelled',
    RESCHEDULE: 'Returned Parcel Marked for Next Attempt',
    PARCEL_RTDT: 'Parcel returned to delivery terminal',
    RTERMINAL_INVTGN: 'Parcel Under Investigation',
    RTERMINAL_FDD: 'Order assigned Future Delivery Date',
    RTERMINAL_RTS_T1: 'Staged for RTS',
    RTERMINAL_RTS_T2: 'Returned to Sender',
    RTS_T1_ORDER_CANCELED_CLIENT: 'Staged For RTS -> Canceled as per client request',
    RTS_T1_ORDER_CANCELED_CUSTOMER: 'Staged For RTS -> Canceled as per customer request',
    ORDER_REBATCH_READY: 'Parcel eligible for rebatching',
    ORDER_BATCH_READY: 'Parcel eligible for batching',
    RTS_T1_ORDER_MAX_ATT: 'Staged For RTS -> Maximum service attempts reached',
    PACKAGE_MISSING: 'Parcel is Missing',
    INDUCTION_SHORTAGE_CLIENT_ONE: 'Client shortage 1',
    INDUCTION_SHORTAGE_CLIENT_TWO: 'Client shortage 2',
    INDUCTION_SHORTAGE_CLIENT_THREE: 'Client shortage 3',
    INDUCTION_SCAN_RECEIPT: 'Inducted at Delivery Terminal',
    SCANNED_BY_RINGSCANNER: 'Ringscanner Scanned',
    CORRECT_ROLLER_CAGE_ID_SCANNED: 'Parcel allocated to ',
    INCORRECT_ROLLER_CAGE_ID_SCANNED: 'Incorrect roller cage id scanned',
    HighPriority_true: 'High Priority attribute applied',
    HighPriority_false: 'High Priority attribute removed',
    SLA_true: 'SLA Violation attribute applied',
    SLA_false: 'SLA Violation attribute removed',
    LAT_LNG_RESOLVE_SUCCESS_FROM_MN_TABLE: 'Address geocoding was resolved from Municipality DB',
    LAT_LNG_RESOLVE_SUCCESS_FROM_LC_TABLE: 'Address geocoding was resolved by Local DB',
    PARCEL_DEL_UNSC_SD_VL: 'Safe Drop - Vulnerable Location',
    PARCEL_DEL_UNSC_SD_THEFT: 'Safe Drop - High Theft Probability',
    PARCEL_DEL_UNSC_SD_OTHER: 'Safe Drop - Other',
    PARCEL_DEL_UNSC_LOC_ID: 'Could Not Locate - Missing Unit/Apt/Suite Details',
    PARCEL_DEL_UNSC_LOC_ENTR: 'Could Not Locate - Could Not Find Entrance',
    PARCEL_DEL_UNSC_LOC_ADDRS: 'Could Not Locate - Bad Address Information',
    PARCEL_DEL_UNSC_LOC_NAV: 'Could Not Locate - Bad Navigation Instructions',
    PARCEL_DEL_UNSC_LOC_OTHER: 'Could Not Locate - Other',
    PARCEL_DEL_UNSC_ACC_BLCK: 'Could Not Access - Blocked Entrance',
    PARCEL_DEL_UNSC_ACC_CODE: 'Could Not Access - Missing Buzzer or Call Box Information',
    PARCEL_DEL_UNSC_ACC_PRK: 'Could Not Access - Parking Unavailable',
    PARCEL_DEL_UNSC_ACC_UNSAFE: 'Could Not Access - Unsafe Road Conditions',
    PARCEL_DEL_UNSC_ACC_ENVMT: 'Could Not Access - Unsafe Environmental Conditions',
    PARCEL_DEL_UNSC_ACC_OTHER: 'Could Not Access - Other',
    PARCEL_DEL_UNSC_REJ_RSC_SIG: 'Rejected - Receptionist/Security/Concierge Refused Signature',
    PARCEL_DEL_UNSC_REJ_RSC_PARCEL: 'Rejected - Receptionist/Security/Concierge Refused Parcel',
    PARCEL_DEL_UNSC_REJ_RSC_ID: 'Rejected - Receptionist/Security/Concierge Cannot Find Unit/Apt/Suite Details',
    PARCEL_DEL_UNSC_REJ_RSC_ADDRS: 'Rejected - Receptionist/Security/Concierge Cannot Confirm Customer Details',
    PARCEL_DEL_UNSC_REJ_RSC_OTHER: 'Rejected - Receptionist/Security/Concierge Other',
    PARCEL_DEL_UNSC_REJ_TP_SIG: 'Rejected - Third Party Refused Signature',
    PARCEL_DEL_UNSC_REJ_TP_PARCEL: 'Rejected - Third Party Refused Parcel',
    PARCEL_DEL_UNSC_REJ_TP_DAMAGED: 'Rejected - Third Party Declared Parcel Damage',
    PARCEL_DEL_UNSC_REJ_TP_OTHER: 'Rejected - Third Party Other',
    PARCEL_DEL_UNSC_REJ_CSGNE_ADULT: 'Rejected - Consignee Adult Signature Required',
    PARCEL_DEL_UNSC_REJ_CSGNE_DAMAGED: 'Rejected - Consignee Declared Parcel Damage',
    PARCEL_DEL_UNSC_REJ_CSGNE_OTHER: 'Rejected - Consignee Refused Parcel',
    PARCEL_DEL_UNSC_ATT_TIME: 'Could Not Attempt - Route Service Time Expired',
    PARCEL_DEL_UNSC_ATT_ENVMT: 'Could Not Attempt - Poor Weather Conditions',
    PARCEL_DEL_UNSC_ATT_CANCEL: 'Could Not Attempt - Route Cancelled',
    OUT_OF_SERVICE_AREAL: 'Out Of Service Area',
    PARCEL_DEL_UNSC_REJ_CSGNE: 'Rejected - Consignee Refused Parcel',
    ARCHIVE: 'Archive',
    ON_HOLD_MANIFEST: 'On Hold Manifest',
    ON_HOLD_REMEDIATION: 'On Hold Remediation',
    ORDER_MANUAL_ADDRESS_LATLNG_RESOLVED: 'Address geocode manually validated and standardized',
    NO_INFO_PACKAGE_SCANNED_BY_PATHFINDER: 'No Info Package Scanned By Path Finder',
    HOLD_PACKAGE_SCANNED_BY_PATHFINDER: 'Hold Package Scanned By Path Finder',
    WRONG_DC_PACKAGE_SCANNED_BY_PATHFINDER: 'Wrong DC Package Scanned By Path Finder',
    MISSING_PACKAGE_SCANNED_BY_PATHFINDER: 'Missing Package Scanned By Path Finder',
    ARCHIVED_PACKAGE_SCANNED_BY_PATHFINDER: 'Archived Package Scanned By Path Finder',
    RMD_PACKAGE_SCANNED_BY_PATHFINDER: 'Remediation Package Scanned By Path Finder',
    ROUTED_PACKAGE_SCANNED_BY_PATHFINDER: 'Routed Package Scanned By Path Finder',
    RTERM_PACKAGE_SCANNED_BY_PATHFINDER: 'Returns Terminal Package Scanned By Path Finder',
    RTS_PACKAGE_SCANNED_BY_PATHFINDER: 'Return To Sender Package Scanned By Path Finder',
    RB_RDY_PACKAGE_SCANNED_BY_PATHFINDER: 'Re-Batched Ready Package Scanned By Path Finder',
    RDY_PACKAGE_SCANNED_BY_PATHFINDER: 'Batched Ready Package Scanned By Path Finder',
    SHORT_PACKAGE_SCANNED_BY_PATHFINDER: 'Short Package Scanned By Path Finder',
    BATCHED_PACKAGE_SCANNED_BY_PATHFINDER: 'Batched Package Scanned By Path Finder',
    DAMAGED_PACKAGE_SCANNED_BY_PATHFINDER: 'Damaged Package Scanned By Path Finder',
    UNPROCESSED_PACKAGE_SCANNED_BY_PATHFINDER: 'Unprocessed Package Scanned By Path Finder',
    SEQUENCE_FAILED: 'Sequence Failed',
    INDUCTION_DAMAGED:'Damaged on Receipt',
    PARCEL_DEL_SUCC_OTP_VER:'OTP Verified',
    NOT_SERVICABLE: 'Not Serviceable',
    REATTEMPT: 'Reattempt',
    ORDER_BATCH_MERGED: 'Order merged into Batch',
    ORDER_BATCH_REMOVED: 'Order removed from Batch',
    DRIVER_ASSIGNED: 'Order assigned to driver',
    PARCEL_DEL_UNSC_ATT_RESCHEDULED: 'Could Not Attempt - Route Rescheduled',
    ROUTE_DRIVER_STARTED: 'Driver started route',
    REMEDIATION_PENDING: 'Remediation Pending',    
    LAT_LON_RESOLVE_FAILED_FROM_HERE_LOW_ACCURACY: 'Resolve Failed due to low accuracy'
}