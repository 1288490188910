import { useSelector } from 'react-redux';
import { languages } from 'constants/languageConstants'
import { roles, roleConstantEnum } from 'constants/roleConstants'
import { getDataCentersList, getTenantsList } from 'utils/userAccountHelper'
import { getUserRole, getUserDCOption } from 'utils/common'

export const UserFormFields = ({ formValues, formError, handleFieldChange, handleImageUpload, isEdit = false }) => {
    const { loading, dataCenters, tenants } = useSelector(
        (state) => state?.userManagement
    )
    const { isMobile } = useSelector(
        (state) => state?.viewport
    )
    const { user } = useSelector(
        (state) => state?.auth
    )
    const userRole = getUserRole(user)
    const userDC = getUserDCOption(user)

    return {
        fields: [{
            label: 'First Name',
            name: 'firstName',
            required: true,
            type: 'text',
            value: formValues?.firstName || '',
            handleFieldChange: handleFieldChange,
            error: formError?.firstName,
            maxLength: 50,
            width: isMobile ? '17rem' : '14rem'
        },
        {
            label: 'Last Name',
            name: 'lastName',
            required: true,
            type: 'text',
            value: formValues?.lastName || '',
            handleFieldChange: handleFieldChange,
            error: formError?.lastName,
            maxLength: 50,
            width: isMobile ? '17rem' : '14rem'
        },
        {
            label: 'Email',
            name: 'email',
            required: true,
            type: 'email',
            value: formValues?.email || '',
            handleFieldChange: handleFieldChange,
            error: formError?.email,
            maxLength: 254,
            width: isMobile ? '17rem' : '14rem'
        },
        {
            label: 'User Name',
            name: 'login',
            required: true,
            type: 'text',
            value: formValues?.login || '',
            handleFieldChange: handleFieldChange,
            error: formError?.login,
            maxLength: 50,
            width: isMobile ? '17rem' : '14rem',
            disabled: isEdit ? true : false,
        },
        {
            label: 'Password',
            name: 'tempPassword',
            required: isEdit ? false : true,
            type: 'password',
            value: formValues?.tempPassword || '',
            handleFieldChange: handleFieldChange,
            error: formError?.tempPassword,
            maxLength: 60,
            width: isMobile ? '17rem' : '14rem',
            info:
                <ul>
                    <li>Be at least 8 characters long</li>
                    <li>Contain at least one uppercase letter</li>
                    <li>Contain at least one lowercase letter</li>
                    <li>Contain at least one number</li>
                    <li>Contain at least one special character</li>
                    <li>Not contain spaces</li>
                </ul>
        },
        {
            label: 'Language',
            name: 'langKey',
            required: true,
            type: 'select',
            options: languages,
            value: formValues?.langKey || '',
            handleFieldChange: handleFieldChange,
            error: formError?.langKey,
            width: isMobile ? '17rem' : '14rem'
        },
        {
            label: 'Role',
            name: 'authorities',
            required: true,
            type: 'select',
            options: roles,
            value: formValues?.authorities || '',
            handleFieldChange: handleFieldChange,
            error: formError?.authorities,
            width: isMobile ? '17rem' : '14rem',
            isDisabled: isEdit ? true : false,
        },
        {
            label: 'DC Name',
            name: 'dcName',
            required: true,
            type: 'select',
            options: getDataCentersList(dataCenters, formValues?.authorities?.value, userDC),
            value: formValues?.dcName || '',
            handleFieldChange: handleFieldChange,
            error: formError?.dcName,
            width: isMobile ? '17rem' : '14rem',
            isDisabled: [roleConstantEnum?.ROLE_CSR,  roleConstantEnum?.ROLE_CUSTOMER].includes(userRole) || !formValues?.authorities?.value,
        },
        {
            label: 'Tenant Name',
            name: 'tenantName',
            required: true,
            type: 'select',
            options: getTenantsList(tenants),
            value: formValues?.tenantName || '',
            handleFieldChange: handleFieldChange,
            error: formError?.tenantName,
            width: isMobile ? '17rem' : '14rem',
            isDisabled: [roleConstantEnum?.ROLE_CSR,  roleConstantEnum?.ROLE_CUSTOMER].includes(userRole),
        },
        {
            label: 'Active',
            name: 'activated',
            type: 'toggle',
            value: formValues?.activated,
            handleFieldChange: handleFieldChange,
            isDisabled: userRole !== roleConstantEnum?.ROLE_ADMIN,
            width: isMobile ? '17rem' : '14rem',
        },
        {
            label: 'Enable 2FA',
            name: 'tfaEnabled',
            type: 'toggle',
            value: formValues?.tfaEnabled,
            handleFieldChange: handleFieldChange,
            width: isMobile ? '17rem' : '14rem',
        }
        ],
        padding: isMobile ? '1rem' : '2rem',
        showImageUploader: true,
        loading: loading,
        handleImageUpload: handleImageUpload,
        image: formValues?.userPhoto,
        fieldAlignment: isMobile ? 'center' : 'unset',
        isMobile: isMobile,
        allowOverflow : isMobile? 'auto' : 'unset'
    }
}