import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { Link } from '@mui/material';
import { Layout } from 'component/common/Layout/Layout';
import { fetchCustomersRate } from 'features/rates/rateAction'
import { fetchClients } from 'features/clientManagement/clientManagementAction'
import DataTable from 'component/dataTable/dataTable'
import { Loader } from 'component/common/Loader/Loader'
import { getUserDCOption, getDate,getDateWithTimeStamp, getUserTimeZone } from 'utils/common'
import { PrimaryButton } from 'component/common/Button/Button.jsx'
import { TableHeader } from 'component/dataTable/tableHeader';
import { ResponseModal } from 'component/common/Modal/ResponseModal'
import { AddMSA } from './AddMSA'
import '../userManagement/userAccount/UserAccount.scss'
import { Tooltip } from '@mui/material';
import { resetData } from 'features/rates/rateSlice'
import { urls } from 'utils/urls'

export const RateCard = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const { customersRate, error, loading, success } = useSelector(
        (state) => state?.customersRate
    )
    const { user } = useSelector(
        (state) => state?.auth
    )
    const [sorteData, setSortedData] = useState(customersRate);
    const [updatedColumns, setUpdatedColumns] = useState([]);
    const [modalProps, setModalProps] = useState(null)
    const [addModalOpen, setAddModalOpen] = useState(false)
    const [selectedRate, setSelectedRate] = useState(null)
    const userTimeZone = getUserTimeZone(user?.distributionCenterResponseVm?.timeZone) || 'America/Toronto'

    const handleNavigation = (data) => {
        navigate(`${urls?.CUSTOMER_MSA_DETAIL}?customerId=${data?.customerId}&customerName=${data?.businessName}&clientMSAId=${data?.id}`)
    }

    useEffect(() => {
        dispatch(fetchCustomersRate())
        dispatch(fetchClients({
            page: 0,
            size: 500,
            dcName: getUserDCOption(user)?.value
        }))
    }, [])


    useEffect(() => {
        if (error) {
            setModalProps(
                {
                    title: 'Error Occured!',
                    message: error,
                    open: true,
                    type: error
                }
            )

        }
    }, [error]);

    useEffect(() => {
        if (success) {
            setModalProps(
                {
                    title: 'Success',
                    message: 'Update Done Successfully!',
                    open: true,
                    type: 'success'
                }
            )
            const redirectTimer = setTimeout(() => {
                setModalProps(null)
                handleAddModalClick()
                dispatch(resetData())
                dispatch(fetchCustomersRate())
            }, 2000);
            return () => {
                clearTimeout(redirectTimer);
            }
        }
    }, [success]);

    const tableColumns = [
        {
            accessor: "customerId",
            title: "Customer Id",
            width: "3rem",
            Cell: ({ cell: { row: { original } } }) => <Link id='clientId' style={{ cursor: 'pointer' }} sx={{ textDecoration: 'none' }} onClick={() => handleNavigation(original)}>{original?.customerId}</Link> || '--',
            render: (item) => item?.customerId || '--'
        },
        {
            accessor: "businessName",
            title: "Business Name",
            width: "3rem",
            Cell: ({ cell: { row: { original } } }) => original?.businessName || '--',
            render: (item) => item?.businessName || '--'
        },
        {
          accessor: "baseRate",
          title: "Base Rate",
          width: "3rem",
          Cell: ({ cell: { row: { original } } }) => original?.baseRate || '--',
          render: (item) => item?.baseRate || '--'
        },
        {
          accessor: "baseRateUnit",
          title: "Base Rate Unit",
          width: "3rem",
          Cell: ({ cell: { row: { original } } }) => original?.baseRateUnit || '--',
          render: (item) => item?.baseRateUnit || '--'
        },
        {
          accessor: "dimFactor",
          title: "Dim Factor",
          width: "3rem",
          Cell: ({ cell: { row: { original } } }) => original?.dimFactor || '--',
          render: (item) => item?.dimFactor || '--'
        },
        {
          accessor: "activeTier",
          title: "Active Tier",
          width: "3rem",
          Cell: ({ cell: { row: { original } } }) => original?.activeTier || '--',
          render: (item) => item?.activeTier || '--'
        },
        {
            accessor: "clientSignatoryDesignation",
            title: "Client Signatory Designation",
            width: "3rem",
            Cell: ({ cell: { row: { original } } }) => original?.clientSignatoryDesignation,
            render: (item) => item?.clientSignatoryDesignation
        },
        {
            accessor: "clientSignatoryEmail",
            title: "Client Signatory Email",
            width: "3rem",
            Cell: ({ cell: { row: { original } } }) => original?.clientSignatoryEmail,
            render: (item) => item?.clientSignatoryEmail
        },
        {
            accessor: "clientSignatoryName",
            title: "Client Signatory Name",
            width: "3rem",
            Cell: ({ cell: { row: { original } } }) => original?.clientSignatoryName,
            render: (item) => item?.clientSignatoryName
        },
        {
            accessor: 'msaSignedDate',
            title: "MSA Assign Date",
            width: "3rem",
            Cell: ({ cell: { value } }) => value ? getDate(value) : '--',
            render: (item) => item?.msaSignedDate ? getDate(item?.msaSignedDate) : '--'
        },
        {
            accessor: 'msaExpiryDate',
            title: "MSA Expiry Date",
            width: "3rem",
            Cell: ({ cell: { value } }) => value ? getDate(value) : '--',
            render: (item) => item?.msaExpiryDate ? getDate(item?.msaExpiryDate) : '--'
        },
        {
            accessor: 'lastUpdated',
            title: "Last Updated Date",
            width: "3rem",
            Cell: ({ cell: { value } }) => value ? getDateWithTimeStamp(value, user) : '--',
            render: (item) => item?.lastUpdated ? getDateWithTimeStamp(item?.lastUpdated, user) : '--'
        },
        {
            accessor: 'action',
            isSortable: false,
            title: "Action",
            width: "4rem",
            Cell: ({ cell: { row: { original } } }) => <div className='action' >
                <Tooltip title="Edit">
                    <img src="static/images/edit-icon.svg" alt='edit' onClick={() => handleAddModalClick(original)} /></Tooltip>
            </div>
        }
    ]

    const handleAddModalClick = (data = null) => {
        setAddModalOpen(!addModalOpen)
        setSelectedRate(data)
    }

    return (
        <Layout headerTitle={
            <div className='title-container'>
                Customer Master Agreement
            </div>}
            showBackArrow={false}
            action={<div><PrimaryButton type="button" variant='primary' label={'Add MSA'} height={'30px'} onClick={() => handleAddModalClick()} /></div>}
        >
            <div className='container'>
                <TableHeader columns={updatedColumns} data={sorteData} exportFileName='CustomerMasterAgreement' />
                <div className='content'>
                    <DataTable columns={tableColumns} data={customersRate || []} isCollpsable={false} onSortChange={setSortedData} uniqueKey={'customerId'} setUpdatedColumns={setUpdatedColumns} showPagination={false} />
                </div>
            </div>
            {addModalOpen ?
                <AddMSA isModelOpen={addModalOpen} handleClose={handleAddModalClick} selectedRate={selectedRate} userTimeZone={userTimeZone} /> : null}
            {modalProps ? <ResponseModal {...modalProps} handleClose={() => setModalProps(null)} /> : null}
            {loading ? <Loader isLoading={loading} /> : null}
        </Layout>
    )
}
