import React from 'react';
import Shelf from './Shelf';
import './WarehouseOperations.scss'

const WarehouseContent = ({ warehouseData }) => {
    return (
        <div className="warehouse-container">
            <div className="tote-container">
                {warehouseData?.WEST && Object.entries(warehouseData?.WEST).map(([key, value], index) => (
                    <React.Fragment key={`${key}-${index}`}>
                        <Shelf row={{ rowLabel: key, rollerCageContainers: value, flexDirection: 'row-reverse' }} zone="W" />
                        <div className="spacer"><div className="label">{key}</div></div>
                    </React.Fragment>
                ))}
            </div>

            <div className="roller-cage-image">
                <div className="moving-belt">
                    <img src="/static/images/ConveyorBelt.jpg" className="img" alt="ConveyorBelt" />
                    <img src="/static/images/ConveyorBelt.jpg" className="img" alt="ConveyorBelt" />
                    <img src="/static/images/ConveyorBelt.jpg" alt="ConveyorBelt" />
                </div>
            </div>

            <div className="tote-container">
                {warehouseData?.EAST && Object.entries(warehouseData?.EAST).map(([key, value], index) => (
                    <React.Fragment key={`${key}-${index}`}>
                        <Shelf row={{ rowLabel: key, rollerCageContainers: value }} zone="E" />
                        <div className="spacer"><div className="label">{key}</div></div>
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
};

export default WarehouseContent;
