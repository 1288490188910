import React from 'react';
import { Layout } from './common/Layout/Layout';

const PageNotFound = () => {
    return (
        <Layout>
            <div style={styles.container}>
                <h1 style={styles.header}>404 Page Not Found</h1>
                <p style={styles.paragraph}>The requested page does not exist or you might not have access to this page</p>
            </div>
        </Layout>
    );
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        textAlign: 'center',
        padding: '20px'
    },
    header: {
        fontSize: '2rem',
        marginBottom: '10px',
        color: '#333'
    },
    paragraph: {
        fontSize: '1.2rem',
        color: '#666'
    }
};

export default PageNotFound;
