import React, { useState } from 'react';
import { useDrag } from "react-dnd";
import { useDispatch } from 'react-redux';
import LeftSidebar from './LeftSidebar';
import RightSidebar from './RightSidebar';
import CenterContainer from './CenterContainer';
import { relocateRollerCage, relocateTote } from 'features/warehouseOperation/warehouseOperationAction'
import './WarehouseOperations.scss';
import useDragAutoScroll from "utils/useDragAutoScroll";
import { ItemTypes } from "./ItemTypes";

const WarehouseLayout = ({ warehouseData, warehouseAsset }) => {
    const dispatch = useDispatch();
    useDragAutoScroll();

    const [isLeftOpen, setIsLeftOpen] = useState(false);
    const [isRightOpen, setIsRightOpen] = useState(false);

    // ✅ Parent-level useDrag Hook
    const [{ isDragging }, drag] = useDrag({
        type: "WAREHOUSE_ITEM",
        item: (monitor) => {
            const draggingItem = monitor.getItem();
            return { id: draggingItem?.id, type: draggingItem?.type, data: draggingItem?.data };
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    // ✅ Handle Drop for all containers
    const handleDropItem = ({ item, locationType }) => {
        console.log("Dropped:", item, "locationType:", locationType, item?.toteNumber || item?.type === ItemTypes?.TOTE);
        if (item?.toteNumber || item?.type === ItemTypes?.TOTE) {
            const data = {
                toteNumber: item?.toteNumber,
                locationType: locationType
            }
            dispatch(relocateTote(data))
        }
        else {
            const data = {
                rollerCageName: item?.cageName || item?.id,
                locationType: locationType
            }
            dispatch(relocateRollerCage(data))
        }
    };

    return (
        <div className="warehouse-wrapper">
            <LeftSidebar isOpen={isLeftOpen} toggleOpen={() => setIsLeftOpen(!isLeftOpen)} dragRef={drag} items={warehouseAsset?.loadingBayAssetsVm?.rollerCageResponseVms} onDropItem={handleDropItem} />
            <CenterContainer warehouseData={warehouseData} />
            <RightSidebar isOpen={isRightOpen} toggleOpen={() => setIsRightOpen(!isRightOpen)} items={warehouseAsset?.inventoryStorageAssetsVm} dragRef={drag} onDropItem={handleDropItem} />
        </div>
    );
};

export default WarehouseLayout;
