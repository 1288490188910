import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from "react-router-dom";
import { Layout } from 'component/common/Layout/Layout';
import { useSelector, useDispatch } from 'react-redux';
import { CustomFormWithBox } from 'component/common/Form/CustomFormWithBox'
import { Loader } from 'component/common/Loader/Loader'
import { fetchClientDetails, updateClient } from 'features/clientManagement/clientManagementAction'
import { ResponseModal } from 'component/common/Modal/ResponseModal'
import { resetDriverData } from 'features/driverManagement/driverManagementSlice'
import { validateForm } from 'utils/formValidator'
import { ClientFormFields } from '../userAccount/clientFormFields';
import { resetClientData } from 'features/clientManagement/clientManagementSlice';
import { getFSAZones } from 'utils/common'
import { urls } from 'utils/urls'
import useFieldChange from 'hooks/useFieldChange';

const formInitialState = {
    businessName: '',
    clientCode: '',
    companyCode: '',
    fsaZones: '',
    serviceDays: '',
    deliveryDateBuffer: '',
    eligibilityDay: '',
    addressOne: '',
    addressTwo: '',
    city: '',
    province: '',
    postalCode: '',
    expectedManifests: '',
    manifestCutoffTime: ''
}

export const EditClient = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const userId = queryParams.get('id');
    const isEdit = userId ? true : false
    const [modalProps, setModalProps] = useState('')
    const { addClientSuccess, error, loading, clientDetails } = useSelector(
        (state) => state?.clientManagement
    )
    const { fsaZones } = useSelector(
        (state) => state?.fsaZones
    )

    const [formValues, setFormValues, handleFieldChange] = useFieldChange(formInitialState);
    const [formError, setFormErrors] = useState({});

    useEffect(() => {
        return () => {
            // This code will run when the component unmounts
            dispatch(resetClientData())
        };
    }, [])

    const handleMultiSelectCheckboxClicked = (value) => {
        if (typeof value === 'object') {
            setFormValues((prevValues) => ({
                ...prevValues,
                serviceDays: value,
            }));
        }
        else {
            setFormValues((prevItems) => {
                // Check if the value is already in the list
                if (prevItems.serviceDays?.includes(value)) {
                    // If yes, remove it
                    return {
                        ...prevItems,
                        serviceDays: prevItems?.serviceDays?.filter((item) => item !== value),
                    };
                } else {
                    // If not, add it to the list
                    return {
                        ...prevItems,
                        serviceDays: [...(prevItems?.serviceDays || []), value],
                    };
                }
            });
        }
    }

    const handleFormSubmit = () => {
        const errors = validateForm(formValues, ['addressTwo']);
        if (formValues?.deliveryDateBuffer < formValues?.eligibilityDay) {
            errors['deliveryDateBuffer'] = 'Estimated Delivery Date Buffer should be greater than eligibility day'
        }
        if (Object.keys(errors).length) {
            setFormErrors(errors)
        }
        else {
            setFormErrors({})
            const { fsaZones, ...data } = formValues
            data['serviceFsaZones'] = fsaZones.map(item => item.label);
            data['loginName'] = clientDetails?.loginName
            data['id'] = clientDetails?.id
            dispatch(updateClient(data))
        }
    }

    const handleCancel = () => {
        dispatch(resetClientData())
        navigate(urls?.CLIENT_MANAGEMENT_WIDGET_URL)
    }

    useEffect(() => {
        dispatch(fetchClientDetails(userId))
    }, []);

    useEffect(() => {
        if (clientDetails) {
            const clientFsaZones = fsaZones.filter(zone => clientDetails?.serviceFsaZones?.includes(zone?.fsaZoneName));
            setFormValues({
                businessName: clientDetails?.businessName,
                clientCode: clientDetails?.clientCode,
                companyCode: clientDetails?.companyCode,
                fsaZones: getFSAZones(clientFsaZones),
                serviceDays: clientDetails?.serviceDays,
                deliveryDateBuffer: clientDetails?.deliveryDateBuffer,
                eligibilityDay: clientDetails?.eligibilityDay,
                addressOne: clientDetails?.addressOne,
                addressTwo: clientDetails?.addressTwo,
                city: clientDetails?.city,
                province: clientDetails?.province,
                postalCode: clientDetails?.postalCode,
                expectedManifests: clientDetails?.expectedManifests,
                manifestCutoffTime: clientDetails?.manifestCutoffTime
            });
        }
    }, [clientDetails, fsaZones]);

    useEffect(() => {
        if (error) {
            setModalProps(
                {
                    title: 'Error Occured!',
                    message: error,
                    open: true,
                    type: error
                }
            )

        }
    }, [error]);

    useEffect(() => {
        if (addClientSuccess) {
            setModalProps(
                {
                    title: 'Success',
                    message: 'Client updated successfully',
                    open: true,
                    type: 'success'
                }
            )
            const redirectTimer = setTimeout(() => {
                dispatch(resetDriverData())
                navigate(urls?.CLIENT_MANAGEMENT_WIDGET_URL)
            }, 2000);
            return () => {
                clearTimeout(redirectTimer);
            }
        }
    }, [addClientSuccess]);

    const clientFormProps = ClientFormFields({ handleFieldChange, formError, formValues, handleMultiSelectCheckboxClicked, isEdit, setFormValues });

    const formProps = {
        ...clientFormProps,
        buttons: [
            {
                label: 'Submit',
                onClick: handleFormSubmit
            },
            {
                label: 'Cancel',
                onClick: handleCancel,
                color: 'error'
            }
        ],
        loading: loading,
    }

    return (
        <Layout headerTitle={'Edit Client'}>
            <CustomFormWithBox {...formProps} showFullHeight={true} />
            {modalProps ? <ResponseModal {...modalProps} handleClose={() => setModalProps(null)} /> : ''}
            {loading ? <Loader isLoading={loading} /> : null}
        </Layout>
    )
}