import { createSlice } from '@reduxjs/toolkit';
import { fetchPackages, fetchAssetImages, updatePackage, fetchPackageByTrackingNumber, fetchScannedPackages, updateReturnPackage, reclaimTotes, getStreetPerfectDetails, fetchUnScannedPackages, scanPackage, markHighPriorityAndSLA, updateNotificationStatus, updateRemediatePackage, markOnHold, removeSignatureAttribute, sendSLAEmailViolation } from './packagesAction'
import { getCurrentDate } from 'utils/common'
import { logout } from 'features/auth/authAction'
import { packageStatusEnum } from 'constants/packageStatus';

const initialState = {
    packages: [],
    scannedPackages: [],
    unScannedPackages: [],
    scannedPackageCount: 0,
    unScannedPackageCount: 0,
    loading: false,
    error: null,
    fsaZones: [],
    packageFilter: {
        startDate: getCurrentDate(),
        endDate: getCurrentDate()
    },
    packageCount: 0,
    updatePackageSuccess: false,
    reclaimToteSuccess: false,
    assetImages: null,
    packageTrackingDetails: null,
    streetPerfectDetails: null,
    selectedPackages: [],
    emailConsent: null,
    smsConsent: null,
    successMessage: null
}
// Redux slice for packages
const packagesSlice = createSlice({
    name: 'packagesSlice',
    initialState,
    reducers: {
        setPackageFilter: (state, action) => {
            state.packageFilter = action.payload;
        },
        resetPackageFilter: (state) => {
            state.packageFilter = {
                startDate: getCurrentDate(),
                endDate: getCurrentDate()
            };
        },
        resetSuccessState: (state) => {
            state.updatePackageSuccess = false;
            state.reclaimToteSuccess = false;
            state.scanPackagSuccess = false;
            state.successMessage = null
        },
        resetTrackingData: (state) => {
            state.packageTrackingDetails = null;
            state.assetImages = null;
        },
        resetError: (state) => {
            state.error = null;
        },
        resteStreetPerfectDetails: (state) => {
            state.streetPerfectDetails = null;
        },
        togglePackageSelection: (state, action) => {
            const { routeId, packageIds } = action.payload;
            const routeIndex = state.selectedPackages.findIndex(route => route.routeId === routeId);

            if (routeIndex >= 0) {
                state.selectedPackages[routeIndex].packageIds = packageIds;
            } else {
                // Route does not exist, create new route entry
                state.selectedPackages.push({
                    routeId,
                    packageIds: packageIds
                });
            }
        },
        clearSelectedPackages: (state) => {
            state.selectedPackages = [];
        }
    },
    extraReducers: (builder) => {
        builder
            // packages Slice
            .addCase(fetchPackages.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchPackages.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.packages = action?.payload?.packages
                state.packageCount = action?.payload?.totalCount
            })
            .addCase(fetchPackages.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            })

            //Update package with address
            .addCase(updatePackage.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updatePackage.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.updatePackageSuccess = {
                    title: action?.payload?.streetperfectResponse && action?.payload?.packageStatus === packageStatusEnum?.PROCESSED_STREET_PERFECT_MARKED_BAD ? 'Error' : 'Success',
                    detail: action?.payload?.streetperfectResponse || 'Parcel Updated Successfully!'
                }
            })
            .addCase(updatePackage.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            })


            .addCase(updateNotificationStatus.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateNotificationStatus.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.updatePackageSuccess = true
            })
            .addCase(updateNotificationStatus.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            })

            .addCase(updateRemediatePackage.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateRemediatePackage.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.updatePackageSuccess = {
                    title: 'Success',
                    detail: action?.payload || 'Parcel Updated Successfully!'
                }
            })
            .addCase(updateRemediatePackage.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            })

            //Fetch package mages 
            .addCase(fetchAssetImages.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchAssetImages.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.assetImages = action?.payload
            })
            .addCase(fetchAssetImages.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            })

            //Fetch package tracking details 
            .addCase(fetchPackageByTrackingNumber.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchPackageByTrackingNumber.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.packageTrackingDetails = action?.payload
            })
            .addCase(fetchPackageByTrackingNumber.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            })

            //Scan packages
            .addCase(scanPackage.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(scanPackage.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.updatePackageSuccess = true
            })
            .addCase(scanPackage.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            })

            //Fetch scanned packages
            .addCase(fetchScannedPackages.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchScannedPackages.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.scannedPackages = action?.payload?.packages
                state.scannedPackageCount = action?.payload?.totalCount
            })
            .addCase(fetchScannedPackages.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            })

            //Fetch unscanned packages
            .addCase(fetchUnScannedPackages.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchUnScannedPackages.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.unScannedPackages = action?.payload?.packages
                state.unScannedPackageCount = action?.payload?.totalCount
            })
            .addCase(fetchUnScannedPackages.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            })

            // Update return packages
            .addCase(updateReturnPackage.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateReturnPackage.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;

                const response = action?.payload;
                if (response && Object.keys(response.unsuccessful).length > 0) {
                    state.error = Object.entries(response.unsuccessful)
                        .map(([key, value]) => `${key}: ${value}`)
                        .join(', ');
                }
                else {
                    state.updatePackageSuccess = 'Package Updated Successfully!';
                }

            })
            .addCase(updateReturnPackage.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            })

            // Reclaim totes
            .addCase(reclaimTotes.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(reclaimTotes.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.reclaimToteSuccess = action?.payload
            })
            .addCase(reclaimTotes.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            })

            //Stree perfetc details
            .addCase(getStreetPerfectDetails.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getStreetPerfectDetails.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.streetPerfectDetails = action?.payload
            })
            .addCase(getStreetPerfectDetails.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            })

            //markHighPriorityAndSLA
            .addCase(markHighPriorityAndSLA.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(markHighPriorityAndSLA.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.updatePackageSuccess = 'Package Updated Successfully!'
            })
            .addCase(markHighPriorityAndSLA.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            })

            //markOnHold
            .addCase(markOnHold.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(markOnHold.fulfilled, (state, action) => {
                state.loading = false;
                if (Object.keys(action.payload.unsuccessful).length > 0) {
                    const errorDetails = Object.entries(action.payload.unsuccessful).map(([key, value]) => `${key}: ${value}`).join(', ');
                    state.error = errorDetails;
                    state.updatePackageSuccess = false;
                } else {
                    state.error = null;
                    state.updatePackageSuccess = 'Package Updated Successfully!';
                }
            })
            .addCase(markOnHold.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            })

            .addCase(removeSignatureAttribute.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(removeSignatureAttribute.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.updatePackageSuccess = action?.payload
            })
            .addCase(removeSignatureAttribute.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            })
            .addCase(sendSLAEmailViolation.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(sendSLAEmailViolation.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.updatePackageSuccess = true
                state.successMessage = action?.payload
            })
            .addCase(sendSLAEmailViolation.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload
            })

            .addCase(logout.fulfilled, (state) => {
                return initialState;
            })
    }
});

export const { setPackageFilter, resetPackageFilter, resetSuccessState, resetTrackingData, resetError, resteStreetPerfectDetails, togglePackageSelection, clearSelectedPackages, } = packagesSlice.actions;
export default packagesSlice.reducer;