import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { validateForm } from 'utils/formValidator'
import { resetData } from 'features/warehouseOperation/warehouseOperationSlice'
import { CustomForm } from 'component/common/Form/CustomForm'
import { CustomModal } from 'component/common/Modal/CustomModal'
import useFieldChange from 'hooks/useFieldChange';
import 'component/dailyRoaster/AddDuty.scss'
import { sendNotification } from 'features/notification/notificationAction'

const formInitialState = {
    title: '',
    message: '',
}

export const AddNotification = ({ isModelOpen, handleClose }) => {
    const dispatch = useDispatch();
    const { user } = useSelector(
        (state) => state?.auth
    )

    const [formValues, setFormValues, handleFieldChange] = useFieldChange(formInitialState);
    const [formError, setFormErrors] = useState({});

    const handleFormSubmit = () => {
        const errors = validateForm(formValues);
        if (Object.keys(errors).length) {
            setFormErrors(errors)
        }
        else {
            setFormErrors({})
            const data = {
                title: formValues?.title,
                message: formValues?.message,
                event: 'GENERAL_NOTIFICATION',
                topic: `dc-${user?.distributionCenterResponseVm?.dcName}-tenant-${user?.tenantName}-general`
            }
            // dispatch send notification
            dispatch(sendNotification(data))
        }
    }

    const handleCancel = () => {
        setFormValues(formInitialState)
        dispatch(resetData())
        handleClose()
    }

    const formProps = {
        fields: [
            {
                label: 'Title',
                name: 'title',
                required: true,
                type: 'text',
                value: formValues?.title || '',
                handleFieldChange: handleFieldChange,
                width: '19rem',
                error: formError?.title,
            },
            {
                label: 'Message',
                name: 'message',
                required: true,
                type: 'text',
                value: formValues?.message || '',
                handleFieldChange: handleFieldChange,
                error: formError?.message,
                width: '19rem',
            }
        ],
        buttons: [
            {
                label: 'submit',
                onClick: handleFormSubmit
            },
            {
                label: 'Cancel',
                onClick: handleClose,
                color: 'error'
            }
        ],
        fieldGap: '0px',
        margin: '0px',
        padding: '0rem 1rem'
    }

    return (
        <>
            <CustomModal
                open={isModelOpen}
                onClose={handleCancel}
            >
                <div className='add-duty-modal'>
                    <span className='title'>{'Notification'}</span>
                    <CustomForm {...formProps} />
                </div>
            </CustomModal>
        </>

    )

}