import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { logout } from 'features/auth/authAction.js'
import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { getUserRole } from 'utils/common'
import { roleConstantEnum } from 'constants/roleConstants.js';

import './AppBar.scss'

export const AppBarMenu = ({ messages, handleNotificationClick }) => {
    const dispatch = useDispatch();

    const { user } = useSelector(
        (state) => state?.auth
    )

    const { isMobile } = useSelector(
        (state) => state?.viewport
    )

    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const photo = user?.userPhoto ? `data:image\/(png|jpeg|jpg);base64,${user?.userPhoto}` : ''
    const userRole = getUserRole(user)
    const canAccessNotification = ![roleConstantEnum?.ROLE_CUSTOMER, roleConstantEnum?.ROLE_DRIVER]?.includes(userRole)
    const unreadMessages = messages?.filter(message => !message?.readBy?.includes(user?.login));

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleLogoutClick = () => {
        dispatch(logout())
    };

    return (
        <Box sx={{ flexGrow: 0 }}>
            {canAccessNotification ? <Badge badgeContent={unreadMessages?.length} color="error">
                <NotificationsIcon style={{ cursor: 'pointer', color: isMobile ? '#ffffff' : '#5D48FF' }} data-testid={`notification-button`} onClick={handleNotificationClick} />
            </Badge> : null}
            <Tooltip title="Open Menu">
                <IconButton data-testid="button-profile" onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar
                        sx={{ width: 30, height: 30 }}
                        alt={user?.firstName ? user.firstName?.[0].toUpperCase() : ''}
                        src={photo}>{user?.firstName ? user.firstName?.[0].toUpperCase() : ''}</Avatar>
                </IconButton>

            </Tooltip>
            <Menu
                sx={{ mt: '2rem' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
            >
                <MenuItem key={'name'}>
                    <span className='menuText'>{user?.firstName} {user?.lastName}</span>
                </MenuItem>
                <MenuItem className='menuText' key={'dcName'}>
                    <span>DC : {user?.distributionCenterResponseVm?.dcName}</span>
                </MenuItem>
                <MenuItem className='menuText' key={'logout'} onClick={handleLogoutClick}>
                    <span data-testid="button-logout" >{'Logout'}</span>
                </MenuItem>
            </Menu>
        </Box>)
}