import React from "react";
import RollerCageContainer from "./RollerCageContainer";
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { relocateRollerCage, relocateTote } from 'features/warehouseOperation/warehouseOperationAction'
import {LocationTypes} from './ItemTypes'

const AiselContainer = styled.div.withConfig({
  shouldForwardProp: (prop) =>
    !['flexDirection'].includes(prop),
})`
display: flex;
height: 8rem;
justify-content: space-between;
flex-direction: ${(props) => props?.flexDirection || 'row'};
`;

const getSortedData = (data) => {
  // Step 1: Extract keys and sort them
  const sortedKeys = Object.keys(data).sort();

  // Step 2: Create a new object with sorted keys
  const sortedData = {};
  sortedKeys.forEach(key => {
    sortedData[key] = data[key];
  });
  return sortedData
}

const Aisel = ({ row, zone }) => {
  const dispatch = useDispatch();

  const rollerCageContainers = getSortedData(row?.rollerCageContainers)

  // Move Roller Cage
  const moveRollerCage = ({item, destinationContainer, sourceContainer}) => {
    if (item?.sourceContainer === destinationContainer) return; // No need to move if it's the same place
    const aisel = row?.rowLabel
    const data = {
      rollerCageName: item?.cageName || item?.id,
      destinationLocationId: `${aisel}-${zone}-${destinationContainer}`,
      locationType: LocationTypes?.AISLE
    }
    dispatch(relocateRollerCage(data))
  };

  // Move Tote from one roller cage to another
  const moveTote = ({item, destinationRollerCage}) => {
    if (item?.currentCage === destinationRollerCage) return; // No need to move if it's the same place
    const data = {
      rollerCageName: destinationRollerCage,
      toteNumber: item?.toteNumber,
      locationType: LocationTypes?.AISLE
    }
    dispatch(relocateTote(data))
  };

  return (
    <AiselContainer flexDirection={row?.flexDirection}>
      <DndProvider backend={HTML5Backend}>
        {Object.keys(rollerCageContainers)?.map((containerKey) => {
          const rollerCageContainer = row?.rollerCageContainers?.[containerKey];
          return (
            <RollerCageContainer key={containerKey} containerLabel={containerKey} rollerCageContainer={rollerCageContainer} moveRollerCage={moveRollerCage} moveTote={moveTote} />
          )
        })
        }
      </DndProvider>
    </AiselContainer>);
}

export default Aisel;
