import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Radio, RadioGroup, FormControlLabel, FormControl } from '@mui/material';
import { getRoute, getRouteHistoricData } from 'features/route/routeAction'
import './routeDetailsComponent.scss'
import { InductionParcel } from './inductionParcel'
import { getRouteParcels, canPerformRouteOperation } from 'utils/routeHelper'
import { PrimaryButton } from 'component/common/Button/Button'
import { getTrackingNumbers } from 'component/returnDashboard/ScannedItems'
import './routeDetailsComponent.scss'
import { packageStatusEnum } from 'constants/packageStatus';
import { updateReturnPackage } from 'features/packages/packagesAction'
import { resetSuccessState, resetError } from "features/packages/packagesSlice";
import { ResponseModal } from "component/common/Modal/ResponseModal";

const HEIGHT = '24rem'

const options = [
    { key: 'allParcels', value: 'allParcels', label: 'All Parcels' },
    { key: 'historicData', value: 'historicData', label: 'Historic Data' }
]

export const InductionRouteDetails = React.memo(({ id }) => {
    const dispatch = useDispatch();
    const { data: routeData, historicData, error } = useSelector(
        (state) => state.route
    )
    const { user } = useSelector(
        (state) => state?.auth
    )
    const { updatePackageSuccess, error: packageError } = useSelector(
        (state) => state?.packages
    )

    const [selectedOption, handleOptionChange] = useState('allParcels')
    const [selectedParcels, setSelectedParcels] = useState([])
    const [resetSelectedRows, setResetSelectedRows] = useState(false)
    const [modalProps, setModalProps] = useState(null);

    const routeDetail = selectedOption === 'historicData' ? historicData[id] : routeData[id]

    const fetchData = () => {
        dispatch(selectedOption === 'historicData' ? getRouteHistoricData(id) : getRoute(id))
    }

    useEffect(() => {
        fetchData()
    }, [selectedOption])

    useEffect(() => {
        if (error || packageError) {
            setModalProps({
                title: "Error Occured!",
                message: error || packageError,
                open: true,
                type: error,
            });
        }
    }, [error || packageError]);

    useEffect(() => {
        if (updatePackageSuccess) {
            setModalProps(
                {
                    title: 'Success',
                    message: 'Parcel Updated Successfully!',
                    open: true,
                    type: 'success'
                }
            )
            setResetSelectedRows(true)
            const redirectTimer = setTimeout(() => {
                dispatch(resetSuccessState())
                setSelectedParcels([]);
                setResetSelectedRows(false)
                fetchData()
                setModalProps(null)
            }, 1000);
            return () => {
                clearTimeout(redirectTimer);
            }
        }
    }, [updatePackageSuccess])

    useEffect(() => {
        // this is to fetch data if user refresh pae
        if (Object.keys(historicData).length === 0 && Object.keys(routeData).length === 0) {
            fetchData()
        }
    }, [historicData, routeData])

    useEffect(() => {
        if (updatePackageSuccess) {
            fetchData()
        }
    }, [updatePackageSuccess])

    const handleParcelSelect = (parcels) => {
        setSelectedParcels(parcels);
    };


    const handleModelClose = () => {
        setModalProps(null)
        dispatch(resetError())
    }

    const markDamageParcel = () => {
        const trackingNumbers = getTrackingNumbers(selectedParcels, data);
        const payload = {
            'trackingNumbers': trackingNumbers,
            returnStatus: packageStatusEnum?.INDUCTION_DAMAGED,
            widget: 'INDUCTION'
        }
        dispatch(updateReturnPackage(payload))
    }

    //Convert datain format which helper function accepts, as it is common helper function
    const data = selectedOption === 'historicData' ? getRouteParcels([{ 'packageDetails': routeDetail }], user) : getRouteParcels(routeDetail?.routeStops, user)

    return (
        <>
            <FormControl component="fieldset" sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: 'fit-content' }}>
                <RadioGroup
                    row
                    aria-label="options"
                    name="options"
                    value={selectedOption}
                    onChange={(event) => handleOptionChange(event.target.value)}
                >
                    {options.map((option) => (
                        <FormControlLabel
                            key={option.key}
                            value={option.value}
                            control={<Radio />}
                            label={<span className="text">{option.label}</span>}
                        />
                    ))}
                </RadioGroup>
                {routeDetail && canPerformRouteOperation(routeDetail, 'DAMAGE') ?
                    <PrimaryButton type="button" label={'Mark Damage'} onClick={markDamageParcel} height={'30px'} disabled={!selectedParcels?.length} />
                    : null}
            </FormControl>
            <InductionParcel data={data} height={HEIGHT} selectedOption={selectedOption} handleParcelSelect={handleParcelSelect} resetSelectedRows={resetSelectedRows} />
            {modalProps ? (
                <ResponseModal
                    {...modalProps}
                    handleClose={handleModelClose}
                />
            ) : null}
        </>
    )
})