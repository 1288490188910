import React from 'react';
import WarehouseContent from './WarehouseContent';
import './WarehouseOperations.scss'

const CenterContainer = ({ warehouseData }) => {
    return (
        <div className="center-container">
            {warehouseData && Object.keys(warehouseData)?.length ? (
                <WarehouseContent warehouseData={warehouseData} />
            ) : null}
        </div>
    );
};

export default CenterContainer;
