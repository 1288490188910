import { createSlice } from '@reduxjs/toolkit';
import { fetchCustomersRate, addRate, updateRate, fetchCustomersMSARate, addTier, updateTier } from './rateAction'
import { logout } from 'features/auth/authAction'

 const initialState = {
    customersRate: [],
    loading: false,
    error: null,
    success: false,
    customersMSARate: {}
 }

// Redux slice for warehouse operation
const rateSlice = createSlice({
    name: 'rateSlice',
    initialState,
    reducers: {
        resetData: (state) => {
            state.success = false;
            state.error = null
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCustomersRate.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchCustomersRate.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.customersRate = action?.payload
            })
            .addCase(fetchCustomersRate.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            })

            .addCase(fetchCustomersMSARate.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchCustomersMSARate.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.customersMSARate = action?.payload
            })
            .addCase(fetchCustomersMSARate.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            })
            
            .addCase(addTier.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(addTier.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.success = true
            })
            .addCase(addTier.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            })

            .addCase(updateTier.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateTier.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.success = true
            })
            .addCase(updateTier.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            })

            .addCase(addRate.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(addRate.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.success = true
            })
            .addCase(addRate.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            })

            .addCase(updateRate.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateRate.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.success = true
            })
            .addCase(updateRate.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            })

            .addCase(logout.fulfilled, (state) => {
                return initialState;
            })

    }
});

export const { resetData } = rateSlice.actions;
export default rateSlice.reducer;