
export const getRollerCageLists = (rollerCages) => {
    return rollerCages?.map(cage => ({ label: cage?.rollerCageId || cage?.cageName, value: cage?.cageName }));
};

export const getTotes = (totes) => {
    const data = totes?.map(tote => ({ label: tote?.toteNumber, value: tote?.toteNumber }));
    return data
};

export const getAisleNameList = (aisles) => {
    return aisles?.map(aisle => ({ label: aisle?.aisleName, value: aisle?.aisleName }));
};

export const getToteColor = (status) => {
    switch (status) {
        case "NOT_ASSIGNED":
            return "#1E88E5"; // Gray
        case "ASSIGNED":
            return "#4CAF50"; // Blue
        default:
            return "unset"; // Default to gray
    }
};