import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { Drawer, List, ListItem, ListItemText, Button } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { parseNotificationBody, getRedirectURL, getUserTopics } from 'utils/notificationUtils'
import { sendSOSAcceptance } from 'features/route/routeAction'
import { markNotificationAsRead, fetchNotifications } from 'features/notification/notificationAction';
import { clearSuccessMessage } from 'features/notification/notificationSlice'
import './Layout.scss'
import CircleIcon from '@mui/icons-material/Circle';
import CheckIcon from '@mui/icons-material/Check';
import { Title } from 'component/common/commonStyles'
import { PrimaryButton } from 'component/common/Button/Button'
import CloseIcon from '@mui/icons-material/Close';

const MessageIcon = ({ isRead, onMarkAsRead, data }) => {
    return (
        <>
            <Box
                position="relative"
                display="inline-block"
                width="24px"
                height="24px"
                onClick={() => !isRead && onMarkAsRead({ data: data })}
                style={{
                    cursor: 'pointer', // Makes the icon clickable
                }}
            >
                <CircleIcon
                    style={{
                        fontSize: '20px',
                        color: isRead ? 'green' : 'gray',
                    }}
                />
                {isRead && (
                    <CheckIcon
                        style={{
                            position: 'absolute',
                            top: '3px',
                            left: '3px',
                            fontSize: '13px',
                            color: 'white',
                        }}
                    />
                )}
            </Box>
            <CloseIcon
                style={{
                    fontSize: '20px',
                    color: 'gray',
                    cursor: 'pointer'
                }}
                onClick={() => onMarkAsRead({ data: data, isDelete: true })}
            />
        </>
    );
};


export const NotificationPanel = ({ open, onClose }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { messages, readSuccess } = useSelector(
        (state) => state?.notifications
    )
    const { user } = useSelector(
        (state) => state?.auth
    )
    const { routeSuccess } = useSelector(
        (state) => state?.route
    )
    
    const topics = getUserTopics(user)

    useEffect(() => {
        if (readSuccess || routeSuccess) {
            if (topics?.length) {
                dispatch(fetchNotifications({ topics: topics?.join(','), receiverId: user?.login }))
                dispatch(clearSuccessMessage())                
            }
        }
    }, [readSuccess, routeSuccess])

    const handleAnchorClick = (event, data) => {
        event.preventDefault()
        const url = getRedirectURL(data)
        if (url) {
            navigate(url)
        }
    };


    const handleSOSAction = (action, data) => {
        dispatch(sendSOSAcceptance({
            action,
            routeId: data?.body?.id,
            messageId: data?.notificationId
        }))
    }

    const markAsRead = ({ data, isDelete, readAll, disposeAll }) => {
        let params = null
        if (data) {
            params = {
                receiverId: user?.login,
                messageIds: [data?.notificationId],
                action: isDelete ? 'DISPOSE' : 'READ'
            }
        }
        else {
            params = {
                receiverId: user?.login,
                readAll: readAll,
                topics: topics,
                disposeAll: disposeAll,
                action: disposeAll ? 'DISPOSE' : 'READ'
            }
        }
        dispatch(markNotificationAsRead(params))
    };

    return (
        <Drawer anchor="right" open={open} onClose={onClose} className='notification-drawer'
        >
            <Box sx={{ width: 250 }} onKeyDown={onClose}>
                <div className='notification'>
                    <ListItem className='title'>
                        <Title>Notifications</Title>
                        {messages?.length ?
                            <><Button variant="text" sx={{ textTransform: "none", minWidth: 'inherit', padding: 0 }} onClick={() => markAsRead({ readAll: true })}>Read All</Button>
                                <Button variant="text" sx={{ textTransform: "none", minWidth: 'inherit', padding: 0 }} onClick={() => markAsRead({ disposeAll: true })}>Clear</Button></> : null}
                    </ListItem>
                </div>

                <List className='ulList'>
                    {open && messages?.map((msg, index) => {
                        const parsedJson = parseNotificationBody(msg, user);
                        return (
                            <React.Fragment key={`${index}-${parsedJson?.title}`}>
                                <div className='notification'>
                                    <ListItem className='listItem'>
                                        <div className="listItemHeader">
                                            <ListItemText
                                                primary={parsedJson?.title}
                                            //className="title"
                                            />
                                            <MessageIcon
                                                isRead={parsedJson?.readStatus || false}
                                                onMarkAsRead={markAsRead}
                                                data={parsedJson}
                                            />
                                        </div>

                                        <>
                                            {parsedJson?.body?.isSOS ? (
                                                <div className='message'>
                                                    <span className='description'
                                                        dangerouslySetInnerHTML={{ __html: parsedJson?.body?.message }}
                                                        onClick={(event) => handleAnchorClick(event, parsedJson.body)}
                                                    />
                                                    {!parsedJson?.body?.acceptedBy && !parsedJson?.body?.deniedBy ?
                                                        <div className='action'>
                                                            <PrimaryButton height={'30px'} padding={'11px 15px'} label={'Accept'} onClick={() => handleSOSAction(true, parsedJson)} />
                                                            <PrimaryButton height={'30px'} padding={'11px 15px'} label={'Reject'} onClick={() => handleSOSAction(false, parsedJson)} />
                                                        </div> : null}
                                                </div>
                                            ) : (
                                                <span className='description'
                                                    dangerouslySetInnerHTML={{ __html: parsedJson?.body?.message }}
                                                    onClick={(event) => handleAnchorClick(event, parsedJson.body)}
                                                />
                                            )}
                                        </>
                                    </ListItem>
                                </div>
                                <Divider />
                            </React.Fragment>
                        );
                    })}
                </List>
            </Box>
        </Drawer>
    );
};