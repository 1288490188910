import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from "react-router-dom";
import DataTable from 'component/dataTable/dataTable'
import { ResponseModal } from 'component/common/Modal/ResponseModal'
import { Loader } from 'component/common/Loader/Loader'
import { fetchRoutes, rejectRoute, fetchRouteSummary, breakDownRoute } from 'features/route/routeAction'
import { RouteDetails } from './routeDetailsComponent'
import { Layout } from 'component/common/Layout/Layout';
import { TableHeader } from 'component/dataTable/tableHeader';
import { TableFilter } from 'component/dataTable/tableFilter';
import 'component/userManagement/userAccount/UserAccount.scss'
import { getFSAZones, convertDateRangeToUTC, getUserDCOption, iconStyle, getConstantValue, getDateWithTimeStamp, getCurrentDateForFilter, checkPermission, getGlobalFilter, getMinMaxDate } from 'utils/common'
import { validateForm, validateStartAndEndDate } from 'utils/formValidator'
import DeleteIcon from '@mui/icons-material/Delete';
import { resetSuccessState, resetData } from 'features/route/routeSlice'
import { ConfirmationModal } from 'component/common/Modal/ConfirmationModal'
import { routeStatus, routeStatusEnum, routeStatusToNotAllowedToReject } from 'constants/routeStatus'
import InfoIcon from '@mui/icons-material/Info';
import { setAuditData } from 'features/audit/auditSlice'
import { Audit } from 'component/audit/audit'
import { allowDutyAssign } from 'utils/dailyRoasterHelper'
import { setPackageFilter } from 'features/packages/packagesSlice'
import { setToteFilter } from 'features/warehouseOperation/warehouseOperationSlice'
import { Link, Tooltip } from '@mui/material';
import { DeliveryStatusFilter } from 'component/packages/DeliveryStatusFilter'
import { DeliveryStatusCellRenderer } from 'component/packages/deliveryStatusCellRenderer'
import { RescheduleRoute } from 'component/route/RescheduleRouteModal'
import DriverCellRenderer from './assignDriverCellRenderer'
import { resetError } from 'features/route/routeSlice'
import { resetSuccessData } from 'features/dailyRoaster/dailyRoasterSlice'
import LoopIcon from '@mui/icons-material/Loop';
import { EditRoute } from './EditRoute'
import { urls } from 'utils/urls'
import { canEditMissingTotes, handleExportRouteClick, handleExportToPDFClick, getRouteSummary, canPerformRouteOperation } from 'utils/routeHelper'
import useFieldChange from 'hooks/useFieldChange';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'; // Import the export icon
import PrintIcon from '@mui/icons-material/Print';
import { AccordianComponent } from 'component/common/Accordian/Accordian'
import { TerminateRouteModal } from './TerminateRouteModal';
import CancelIcon from '@mui/icons-material/Cancel';
import CallSplitIcon from '@mui/icons-material/CallSplit';
import { getDeliveryStatusValue } from 'utils/packageHelper'
import { SplitRoute } from './SplitRoute';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import NoBackpackIcon from '@mui/icons-material/NoBackpack';


const date = getCurrentDateForFilter()
const formInitialState = {
    fsaZoneNames: '',
    routeStatus: '',
    routeName: '',
    routeId: '',
    startDate: date,
    endDate: date,
}

export const Routes = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [page, setPage] = useState(0);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const routeId = queryParams.get('routeId');

    const { routes, loading, error, routeCount, routeSuccess, successMessage, rescheduleRouteSuccess, routeSummmary, terminateRouteSuccess } = useSelector(
        (state) => state?.route
    )
    const { error: dailyRosterError } = useSelector(
        (state) => state?.dailyRoaster
    )
    const { isMobile } = useSelector(
        (state) => state?.viewport
    )
    const { user } = useSelector(
        (state) => state?.auth
    )
    const { updateRoasterSuccess } = useSelector(
        (state) => state?.dailyRoaster
    )
    const { sla, highPriority } = useSelector(
        (state) => state?.deliveryStatusManagement
    )
    const { fsaZones } = useSelector(
        (state) => state?.fsaZones
    )
    const [modalProps, setModalProps] = useState(null)
    const [formValues, setFormValues, handleFieldChange] = useFieldChange(formInitialState);
    const [applyFilter, setApplyFilter] = useState(false);
    const [formError, setFormErrors] = useState({});
    const [sorteData, setSortedData] = useState(routes);
    const [updatedColumns, setUpdatedColumns] = useState([]);
    const [confirmationModalProps, setConfirmationModalProps] = useState(null);
    const [selectedRoute, setSelectedRoute] = useState(null)
    const [rescheduleRouteModalOpen, setRescheduleRouteModalOpen] = useState(false)
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [showEditRouteModal, setShowEditRouteModal] = useState(false)
    const [isRouteTerminateModal, setIsRouteTerminateModal] = useState(false)
    const [isRouteSplitModal, setIsRouteSplitModal] = useState(false)
    const [missingToteModal, setMissingToteModal] = useState(false)

    const rowKey = "routeWidgetVM.routeId"
    const hasEditPermission = checkPermission(user, 'ROUTE')

    const fetchData = () => {
        const globalFilter = getGlobalFilter(highPriority, sla)
        const { startDate, endDate } = formValues
        const dateFilter = convertDateRangeToUTC(startDate, endDate, "YYYY-MM-DD", false, endDate ? false : true)

        const data = {}
        if (formValues?.routeName) {
            data['routeName'] = formValues?.routeName?.trim() || null
        }
        if (formValues?.fsaZoneNames?.label) {
            data['fsaZoneNames'] = formValues?.fsaZoneNames?.label?.trim() || null
        }
        if (formValues?.routeStatus?.value) {
            data['routeStatus'] = formValues?.routeStatus?.value?.trim() || null
        }
        if (formValues?.routeId || routeId) {
            data['routeId'] = formValues?.routeId?.trim() || routeId
        }
        data['widget'] = 'ROUTES'
        dispatch(resetData())
        dispatch(fetchRoutes({
            page: page,
            size: rowsPerPage,
            ...data,
            ...globalFilter,
            ...dateFilter,
            dcName: getUserDCOption(user)?.value
        }))
        dispatch(fetchRouteSummary({
            widget: 'ROUTES',
            ...dateFilter,
            ...globalFilter,
            dcName: getUserDCOption(user)?.value
        }))
    }


    useEffect(() => {
        fetchData()
    }, [page, applyFilter, routeId, rowsPerPage, highPriority, sla])

    useEffect(() => {
        if (updateRoasterSuccess) {
            dispatch(resetData())
            fetchData()
        }
    }, [updateRoasterSuccess])

    useEffect(() => {
        if (routeSuccess || rescheduleRouteSuccess || terminateRouteSuccess) {
            setModalProps(
                {
                    title: 'Success',
                    message: successMessage ? successMessage : 'Route update successfully done!',
                    open: true,
                    type: 'success'
                }
            )
            const redirectTimer = setTimeout(() => {
                setConfirmationModalProps(null)
                setModalProps(null)
                setShowEditRouteModal(false)
                setIsRouteTerminateModal(false)
                setIsRouteSplitModal(false)
                setMissingToteModal(false)
                if (rescheduleRouteSuccess) {
                    setRescheduleRouteModalOpen()
                }
                dispatch(resetSuccessState())
                fetchData()
            }, 1000);
            return () => {
                clearTimeout(redirectTimer);
            }
        }
    }, [routeSuccess, rescheduleRouteSuccess, terminateRouteSuccess]);

    useEffect(() => {
        return () => {
            // This code will run when the component unmounts
            dispatch(resetError())
            dispatch(resetSuccessData())
        };
    }, [])

    useEffect(() => {
        if (error || dailyRosterError) {
            setModalProps(
                {
                    title: 'Error Occured!',
                    message: error || dailyRosterError,
                    open: true,
                    type: 'error'
                }
            )
        }
    }, [error || dailyRosterError]);

    useEffect(() => {
        //If user is on last page and deleted all records from that page, then page should be reset to page-1
        const pageCount = Math.ceil(routeCount / 10)
        if (routeCount && (page > 1) && (pageCount < page)) {
            setPage(page - 1)
        }
    }, [routeCount])

    const handlePageChange = (event, page) => {
        setPage(page)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event?.target?.value, 10));
        setPage(0);
    };

    const handleResetFilter = () => {
        setFormValues({
            fsaZoneNames: '',
            routeStatus: '',
            routeName: '',
            startDate: '',
            endDate: '',
            routeId: ''
        }
        )
    }

    const handleRejectRouteClick = (routeId) => {
        if (routeId) {
            setConfirmationModalProps(
                {
                    title: 'Reject Route',
                    message: 'Are you sure, you want to reject this route?',
                    open: true,
                    onConfirm: handleRejectRoute,
                    selectedId: routeId,
                    onClose: handleConfirmationClose
                }
            )
        }
    }

    const handleConfirmationClose = () => {
        setConfirmationModalProps(null)
    }

    const handleRejectRoute = (routeId) => {
        dispatch(rejectRoute(routeId))
        handleConfirmationClose()
    }

    const handleRescheduleRouteClick = (route = null) => {
        setSelectedRoute(route)
        setRescheduleRouteModalOpen(!rescheduleRouteModalOpen)
    }

    const handleApplyFilterClick = () => {
        const notRequiredFields = ['routeId', 'startDate', 'endDate', 'routeName', 'fsaZoneNames', 'routeStatus']
        const errors = validateForm(formValues, notRequiredFields);
        const dateErrors = validateStartAndEndDate(formValues?.startDate, formValues?.endDate);
        if (Object.keys(errors).length || Object.keys(dateErrors).length) {
            setFormErrors(Object.keys(errors).length ? errors : dateErrors)
        }
        else {
            setFormErrors({})
            setApplyFilter(!applyFilter)
            setPage(0)
        }
    }

    const handleNavigation = (params) => {
        const { showTote, ...rest } = params
        if (showTote) {
            dispatch(setToteFilter(rest))
            navigate(urls?.TOTES_WIDGET_URL)
        }
        else {
            dispatch(setPackageFilter(rest))
            navigate(urls?.PARCEL_WIDGET_URL)
        }
    }

    const handleRouteEditCick = (data) => {
        if (!showEditRouteModal) {
            setSelectedRoute(data)
            setShowEditRouteModal(true)
        }
        else {
            setSelectedRoute(null)
            setShowEditRouteModal(false)
        }
    }

    const handleTerminateRouteClick = (route) => {
        setSelectedRoute(route)
        setIsRouteTerminateModal(!isRouteTerminateModal)
    }

    const handleSplitRoute = (route) => {
        if (route) {
            setConfirmationModalProps(null)
        }
        setSelectedRoute(route)
        setIsRouteSplitModal(!isRouteSplitModal)
    }

    const handleMissingToteModal = (route = null) => {
        setSelectedRoute(route)
        setMissingToteModal(!missingToteModal)
    }

    // const handleRouteTerminate = () => {
    //     setIsRouteTerminateModal(!isRouteTerminateModal)
    // }

    const handleSplitRouteClick = (route) => {
        if (route) {
            setConfirmationModalProps({
                title: `Split Route`,
                message: `Do you really want to split route ${route?.routeWidgetVM?.routeId}?`,
                open: true,
                onConfirm: () => handleSplitRoute(route),
                onClose: handleConfirmationClose
            });
        }
    }

    const handleBreakdownRouteClick = (route) => {
        if (route) {
            setConfirmationModalProps({
                title: `Break Down Route`,
                message: `Do you really want to break down this route ${route?.routeWidgetVM?.routeId}?`,
                open: true,
                onConfirm: () => handleRouteBreakdown({ routeId: route?.routeWidgetVM?.routeId, status: routeStatusEnum?.ROUTE_BREAKDOWN }),
                onClose: handleConfirmationClose
            });
        }
    }

    const handleRouteBreakdown = ({ routeId, status }) => {
        dispatch(breakDownRoute({ routeId: routeId, status: status }))
        handleConfirmationClose()
    }

    const handleModelClose = () => {
        setModalProps(null)
        dispatch(resetError())
    }
    //Column props should be json, so that any extra property can be added
    const columns = [

        {
            accessor: "routeWidgetVM.routeId",
            title: "Route ID",
            width: "2rem",
            Cell: ({ cell: { row: { original } } }) => original?.routeWidgetVM?.routeId || '--',
            render: (item) => item?.routeWidgetVM?.routeId || '--'
        },
        {
            accessor: "routeWidgetVM.routeName",
            title: "Route Name",
            width: "4rem",
            Cell: ({ cell: { row: { original } } }) => original?.routeWidgetVM?.routeName || '--',
            render: (item) => item?.routeWidgetVM?.routeName || '--'
        },
        {
            accessor: "routeWidgetVM.dailyRouteStatus",
            title: "Route Status",
            width: "4rem",
            Cell: ({ cell: { row: { original } } }) => original?.routeWidgetVM?.dailyRouteStatus ? getConstantValue(routeStatus, original?.routeWidgetVM?.dailyRouteStatus) : '--',
            render: (item) => item?.routeWidgetVM?.dailyRouteStatus ? getConstantValue(routeStatus, item?.routeWidgetVM?.dailyRouteStatus) : '--'
        },
        {
            accessor: 'deliveryStatus',
            title: "Route Attributes",
            width: "5rem",
            Cell: ({ cell: { row: { original } } }) => <DeliveryStatusCellRenderer key={original?.routeWidgetVM?.routeId} isHighPriority={original?.routeWidgetVM?.highPriorityCount} isSLA={original?.routeWidgetVM?.routeSLA} />,
            render: (item) => getDeliveryStatusValue(item?.routeWidgetVM?.highPriorityCount, item?.routeWidgetVM?.routeSLA),
            isSortable: false
        },
        {
            accessor: 'routeWidgetVM.totalTotes',
            title: "Total Totes",
            width: "2rem",
            Cell: ({ cell: { row: { original } } }) => original?.routeWidgetVM?.totalTotes ? <Link id='dailyRouteId' onClick={() => handleNavigation({
                routeId: original?.routeWidgetVM?.routeId,
                showTote: true
            })} style={iconStyle} sx={{ textDecoration: 'none' }}>{original?.routeWidgetVM?.totalTotes}</Link > : '--',
            render: (item) => item?.routeWidgetVM?.totalTotes || '--'
        },
        {
            accessor: 'routeWidgetVM.totalPackages',
            title: "Total Parcels",
            width: "2rem",
            Cell: ({ cell: { row: { original } } }) => original?.routeWidgetVM?.totalPackages ? <Link id='routetId' style={{ cursor: 'pointer' }} sx={{ textDecoration: 'none' }} onClick={() => handleNavigation({
                routeId: original?.routeWidgetVM?.routeId
            })}>{original?.routeWidgetVM?.totalPackages}</Link> : original?.routeWidgetVM?.totalPackages,
            render: (item) => item?.routeWidgetVM?.totalPackages,
        },
        {
            accessor: 'routeWidgetVM.totalStops',
            title: "Total Stops",
            width: "2rem",
            Cell: ({ cell: { row: { original } } }) => original?.routeWidgetVM?.totalStops || '--',
            // Cell: ({ cell: {  original } }) => value,
            render: (item) => item?.routeWidgetVM?.totalStops
        },
        {
            accessor: 'routeWidgetVM.driverId',
            title: "Driver ID",
            width: "2rem",
            Cell: ({ cell: { row: { original } } }) => original?.routeWidgetVM?.driverId || '--',
            render: (item) => item?.routeWidgetVM?.driverId
        },
        {
            accessor: 'routeWidgetVM.driverName',
            title: "Driver Name",
            width: "3rem",
            Cell: ({ cell: { row: { original } } }) => allowDutyAssign(original?.routeWidgetVM?.dailyRouteStatus) ? <DriverCellRenderer key={original?.routeWidgetVM?.routeId} route={original} /> : original?.routeWidgetVM?.driverName ? original?.routeWidgetVM?.driverName : '--',
            render: (item) => item?.routeWidgetVM?.driverName || '--'
        },
        {
            accessor: 'routeWidgetVM.primaryFsaZone',
            title: "Primary FSA Zone",
            width: "3rem",
            Cell: ({ cell: { row: { original } } }) => original?.routeWidgetVM?.primaryFsaZone || '--',
            render: (item) => item?.routeWidgetVM?.primaryFsaZone || '--'
        },
        {
            accessor: 'distributionCenterResponseVm.dcName',
            title: "DC Name",
            width: "3rem",
            Cell: ({ cell: { row: { original } } }) => original?.distributionCenterResponseVm?.dcName || '--',
            render: (item) => item?.distributionCenterResponseVm?.dcName || '--'
        },
        {
            accessor: "routeWidgetVM.routeStartDate",
            title: "Route Start Date",
            width: "4rem",
            Cell: ({ cell: { row: { original } } }) => original?.routeWidgetVM?.routeStartDate ? getDateWithTimeStamp(original?.routeWidgetVM?.routeStartDate, user) : '--',
            render: (item) => item?.routeWidgetVM?.routeStartDate ? getDateWithTimeStamp(item?.routeWidgetVM?.routeStartDate, user) : '--'
        },
        {
            accessor: 'routeWidgetVM.createDate',
            title: "Created At",
            width: "3rem",
            Cell: ({ cell: { row: { original } } }) => original?.routeWidgetVM?.createDate ? getDateWithTimeStamp(original?.routeWidgetVM?.createDate, user) : '--',
            render: (item) => item?.routeWidgetVM?.createDate ? getDateWithTimeStamp(item?.routeWidgetVM?.createDate, user) : '--'
        },
        hasEditPermission && {
            accessor: 'action',
            title: "Action",
            isSortable: false,
            width: "2rem",
            Cell: ({ cell: { row: { original } } }) => <div className='action'>
                {canEditMissingTotes(original) ? <Tooltip title="Edit"><img src="static/images/edit-icon.svg" alt='edit' onClick={() => handleRouteEditCick(original)} /></Tooltip> : null}
                {
                    !routeStatusToNotAllowedToReject?.includes(original?.routeWidgetVM?.dailyRouteStatus) ?
                        <Tooltip title="Reject">
                            <DeleteIcon style={iconStyle} fontSize="small" onClick={() => handleRejectRouteClick(original?.routeWidgetVM?.routeId)} />
                        </Tooltip>
                        : null}
                <Tooltip title="Export Route"> <CloudDownloadIcon style={iconStyle} fontSize="small" onClick={() => handleExportRouteClick(original, user)} />
                </Tooltip>
                <Tooltip title="Print Route"> <PrintIcon style={iconStyle} fontSize="small" onClick={() => handleExportToPDFClick(original, user)} />
                </Tooltip>

                {canPerformRouteOperation(original, 'RESCHEDULE') ?
                    <Tooltip title="Reschedule"><LoopIcon style={iconStyle} fontSize="small" onClick={() => handleRescheduleRouteClick(original?.routeWidgetVM?.routeId)} /></Tooltip> : null
                }
                {canPerformRouteOperation(original, 'SPLIT') ? <Tooltip title="Split route"><CallSplitIcon style={iconStyle} fontSize="small" onClick={() => handleSplitRouteClick(original)} /></Tooltip> : null}

                {
                    canPerformRouteOperation(original, 'MISSING_TOTE') ? <Tooltip title="Missing Totes"><NoBackpackIcon style={iconStyle} fontSize='small' onClick={() => handleMissingToteModal(original)} />
                    </Tooltip> : null
                }

                {canPerformRouteOperation(original, 'TERMINATE') ?
                    <Tooltip title="Terminate Route"><CancelIcon style={iconStyle} fontSize="small" onClick={() => handleTerminateRouteClick(original?.routeWidgetVM?.routeId)} /></Tooltip> : null
                }
                {canPerformRouteOperation(original, 'BREAK_DOWN') ? <Tooltip title="Break Down Route"><BrokenImageIcon style={iconStyle} fontSize="small" onClick={() => handleBreakdownRouteClick(original)} /></Tooltip> : null}

                <Tooltip title="Info"><InfoIcon style={iconStyle} fontSize="small" onClick={() => dispatch(setAuditData({ 'entityId': original?.routeWidgetVM?.routeId, 'entityType': 'daily_route' }))} /></Tooltip>
            </div>
        }
    ]?.filter(Boolean)

    const tableFilterProps = {
        fields: [
            {
                label: 'Route ID',
                value: formValues?.routeId,
                handleFieldChange: handleFieldChange,
                type: 'text',
                width: isMobile ? '9rem' : '15rem',
                name: 'routeId',
                placeholder: "Route ID",
                error: formError?.routeId
            },
            {
                label: 'Route Name',
                value: formValues?.routeName,
                handleFieldChange: handleFieldChange,
                type: 'text',
                width: isMobile ? '9rem' : '15rem',
                name: 'routeName',
                placeholder: 'Route Name'
            },
            {
                label: 'Route Status',
                value: formValues?.routeStatus,
                handleFieldChange: handleFieldChange,
                type: 'select',
                width: isMobile ? '9rem' : '15rem',
                name: 'routeStatus',
                options: routeStatus,
            },
            {
                label: 'FSA Zone',
                value: formValues?.fsaZoneNames,
                handleFieldChange: handleFieldChange,
                type: 'select',
                width: isMobile ? '9rem' : '15rem',
                options: getFSAZones(fsaZones),
                name: 'fsaZoneNames'
            },
            {
                label: 'Start Date',
                value: formValues?.startDate || "",
                handleFieldChange: handleFieldChange,
                type: 'date',
                width: isMobile ? '9rem' : '13rem',
                name: 'startDate',
                placeholder: 'DD-MM-YYYY',
                error: formError?.startDate,
                ...(formValues?.startDate ? {
                    min: getMinMaxDate()?.minDate
                } : {})
            },
            {
                label: 'End Date',
                value: formValues?.endDate,
                handleFieldChange: handleFieldChange,
                type: 'date',
                width: isMobile ? '9rem' : '13rem',
                name: 'endDate',
                placeholder: 'DD-MM-YYYY',
                error: formError?.endDate,
                ...(formValues?.endDate ? {
                    min: getMinMaxDate()?.minDate
                } : {})
            }],
        buttons: [
            {
                label: 'Apply',
                variant: 'contained',
                color: 'white',
                backgroundColor: 'black',
                onClick: handleApplyFilterClick
            },
            {
                label: 'Reset',
                variant: 'contained',
                color: 'white',
                backgroundColor: 'blue',
                onClick: handleResetFilter
            }
        ],
        margin: '0px',
        padding: '10px',
        borderRadius: '10px'
    }

    return (
        <Layout headerTitle={routeId ? 'Route' : 'Routes'} globalFilter={!routeId ? <DeliveryStatusFilter /> : null} showBackArrow={false}>
            {!routeId ? <AccordianComponent data={getRouteSummary(routeSummmary, 'ROUTES')} isMobile={isMobile} /> : null}
            <div className='container'>
                {!routeId ? <TableHeader columns={updatedColumns} data={sorteData} exportFileName='Routes' onRefreshClick={handleApplyFilterClick} /> : null}
                {!routeId ? <TableFilter {...tableFilterProps} /> : null}
                <div className='content'>
                    <DataTable columns={columns} data={routes?.length ? routes : []} showPagination={routes?.length ? true : false} rowKey={rowKey} childComponent={RouteDetails} isCollpsable={true} page={page} totalRowsCount={routeCount} onPageChange={handlePageChange} onSortChange={setSortedData} uniqueKey={'routeWidgetVM.routeId'} setUpdatedColumns={setUpdatedColumns} handleChangeRowsPerPage={handleChangeRowsPerPage} rowsPerPage={rowsPerPage} />
                </div>
            </div>
            {modalProps ? <ResponseModal {...modalProps} handleClose={handleModelClose} /> : null}
            {loading ? <Loader isLoading={loading} /> : null}
            {confirmationModalProps ? <ConfirmationModal {...confirmationModalProps} /> : null}
            <Audit />
            {rescheduleRouteModalOpen ? <RescheduleRoute isModelOpen={rescheduleRouteModalOpen} handleClose={handleRescheduleRouteClick} route={selectedRoute} user={user} tabName={'Reschedule_Route'} /> : null}
            {showEditRouteModal ? <EditRoute isModelOpen={showEditRouteModal} handleClose={handleRouteEditCick} route={selectedRoute} /> : null}
            {/* {isRouteTerminateModal ? <TerminateRouteModal isModelOpen={isRouteTerminateModal} handleClose={handleRouteTerminate} route={selectedRoute} /> : null} */}
            {isRouteSplitModal ? <SplitRoute isModelOpen={isRouteSplitModal} handleClose={handleSplitRoute} route={selectedRoute} type='split' title='Select totes to keep in this route' /> : null}
            {missingToteModal ? <SplitRoute isModelOpen={missingToteModal} handleClose={handleMissingToteModal} route={selectedRoute} type='missing' title='Select missing totes from route' /> : null}
            {isRouteTerminateModal ? <TerminateRouteModal isModelOpen={isRouteTerminateModal} handleClose={handleTerminateRouteClick} route={selectedRoute} /> : null}
        </Layout>
    )
}