import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Layout } from 'component/common/Layout/Layout';
import DataTable from 'component/dataTable/dataTable'
import { Loader } from 'component/common/Loader/Loader'
import { convertToDate, getDateWithTimeStamp, iconStyle } from 'utils/common'
import { PrimaryButton } from 'component/common/Button/Button.jsx'
import { TableHeader } from 'component/dataTable/tableHeader';
import { ResponseModal } from 'component/common/Modal/ResponseModal'
import '../userManagement/userAccount/UserAccount.scss'
import { fetchNotificationsForGeneral } from 'features/notification/notificationAction'
import { AddNotification } from './AddNotification'
import { clearSuccessMessage } from 'features/notification/notificationSlice'

export const Notification = () => {
    const dispatch = useDispatch();

    const { user } = useSelector(
        (state) => state?.auth
    )

    const { broadcastMessages, error, loading, success } = useSelector(
        (state) => state?.notifications
    )

    const [sorteData, setSortedData] = useState([]);
    const [updatedColumns, setUpdatedColumns] = useState([]);
    const [modalProps, setModalProps] = useState(null)
    const [addModalOpen, setAddModalOpen] = useState(false)

    const fetchData = () => {
        dispatch(fetchNotificationsForGeneral({
            'topics': `dc-${user?.distributionCenterResponseVm?.dcName}-tenant-${user?.tenantName}-general`,
            receiverId: user?.login
        }))
    }

    useEffect(() => {
        fetchData()
    }, [])

    useEffect(() => {
        if (error) {
            setModalProps(
                {
                    title: 'Error Occured!',
                    message: error,
                    open: true,
                    type: error
                }
            )

        }
    }, [error]);

    useEffect(() => {
        if (success) {
            setModalProps(
                {
                    title: 'Success',
                    message: 'Notification sent successfully',
                    open: true,
                    type: 'success'
                }
            )
            const redirectTimer = setTimeout(() => {
                handleAddModalClick()
                setModalProps(null)
                dispatch(clearSuccessMessage())
                fetchData()
            }, 2000);
            return () => {
                clearTimeout(redirectTimer);
            }
        }
    }, [success]);


    const handleAddModalClick = () => {
        setAddModalOpen(!addModalOpen)
    }

    const columns = [
        {
            accessor: "title",
            title: "Title",
            width: "3rem",
            Cell: ({ cell: { row: { original } } }) => original?.data?.title || '--',
            render: (item) => item?.data?.title || '--'
        },
        {
            accessor: "message",
            title: "Message",
            width: "3rem",
            Cell: ({ cell: { row: { original } } }) => original?.data?.body || '--',
            render: (item) => item?.data?.body || '--'
        },
        {
            accessor: 'timestamp',
            title: "Created Date",
            width: "2rem",
            Cell: ({ cell: { value } }) => value ? getDateWithTimeStamp(convertToDate(value), user) : '--',
            render: (item) => item?.timestamp ? getDateWithTimeStamp(convertToDate(item?.timestamp), user) : '--'
        }
    ]

    return (
        <Layout headerTitle={'Notifications'} showBackArrow={false} action={<div><PrimaryButton type="button" variant='primary' label={'Send Notification'} onClick={() => setAddModalOpen(true)} height={'30px'} /></div>}
        >
            <div className='container'>
                <TableHeader columns={updatedColumns} data={sorteData} exportFileName='Broadcast Messages' />
                <div className='content'>
                    <DataTable columns={columns} data={broadcastMessages || []} isCollpsable={false} onSortChange={setSortedData} uniqueKey={'notificationId'} setUpdatedColumns={setUpdatedColumns} showPagination={false} />
                </div>
            </div>
            {modalProps ? <ResponseModal {...modalProps} handleClose={() => setModalProps(null)} /> : null}
            {loading ? <Loader isLoading={loading} /> : null}
            {addModalOpen ? <AddNotification isModelOpen={addModalOpen} handleClose={handleAddModalClick} /> : null}
        </Layout>
    )
}
