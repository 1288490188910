import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import { ListComponent } from "component/common/List/List";
import { Title } from 'component/common/commonStyles';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const LiveTrackingSummary = ({ data, isMobile }) => {
    const [expanded, setExpanded] = React.useState(false);

    // Splitting the data into three parts
    const dataParts = [
        data.slice(0, 6),
        data.slice(6, 12),
        data.slice(12)
    ];

    return (
        <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)} sx={{ width: '100%', backgroundColor: '#f2f2f2' }}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
            >
                <Title>Metrics</Title>
            </AccordionSummary>

            <AccordionDetails sx={{ display: 'flex', gap: isMobile ? '0rem' : '2rem', flexDirection: isMobile ? 'column' : 'row' }}>
                {dataParts?.map((part, index) => (
                    <ListComponent
                        key={index}
                        data={part}
                        height="30px"
                        flexDirection="column"
                    />
                ))}
            </AccordionDetails>
        </Accordion>
    );
}
