import React, { useState, useEffect } from 'react';
import { Stepper, Step, StepLabel, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { AddUser } from './AddUser';
import { AddDriver } from './AddDriver';
import { AddClient } from './AddClient';
import { Layout } from 'component/common/Layout/Layout';
import { getDCObject } from 'utils/common'
import { validateForm } from 'utils/formValidator'
import { addUser } from 'features/userManagement/userManagementAction'
import { addDriver } from 'features/driverManagement/driverManagementAction'
import { resetUserData } from 'features/userManagement/userManagementSlice'
import { ResponseModal } from 'component/common/Modal/ResponseModal'
import { resetDriverData } from 'features/driverManagement/driverManagementSlice'
import { resetClientData } from 'features/clientManagement/clientManagementSlice'
import { Loader } from 'component/common/Loader/Loader'
import { styled } from 'styled-components';
import './UserAccount.scss'
import { addClient } from 'features/clientManagement/clientManagementAction';
import { fetchTenants } from 'features/userManagement/userManagementAction'
import { roleConstantEnum } from 'constants/roleConstants';
import { urls } from 'utils/urls'

const userFormData = {
    firstName: '',
    lastName: '',
    email: '',
    login: '',
    langKey: '',
    dcName: '',
    tenantName: '',
    authorities: '',
    userPhoto: null,
    tempPassword: '',
    activated: true,
    tfaEnabled: true
}

const driverFormData = {
    licenseNumber: '',
    driverType: '',
    inServiceStatus: 'ACTIVE',
    ratePerHr: '',
    currencyUnit: '',
    vehicle: '',
    vehicleType: '',
    onBoardingDate: new Date(),
    workEmail: '',
    workPhone: '',
    homePhone: '',
    addressOne: '',
    addressTwo: '',
    city: '',
    province: '',
    postalCode: ''
}

const clientFormData = {
    businessName: '',
    clientCode: '',
    companyCode: '',
    fsaZones: '',
    serviceDays: '',
    deliveryDateBuffer: '',
    eligibilityDay: '',
    addressOne: '',
    addressTwo: '',
    city: '',
    province: '',
    postalCode: '',
    expectedManifests: '',
    manifestCutoffTime: ''
}

const ButtonContainer = styled.div`
    display: flex;
    gap: 1rem;
    height:40px;
    align-self:center;
    margin: 1rem; 
    @media (min-width: 820px) {
        margin-left: auto;
    }
`

const FormContainer = styled.div`
    margin: 1rem;
    width: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    height: -webkit-fill-available;
`

export const UserStepperForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [activeStep, setActiveStep] = useState(0);
    const [userFormValues, setUserFormValues] = useState(userFormData)
    const [driverFormValues, setDriverFormValues] = useState(driverFormData)
    const [clientFormValues, setClientFormValues] = useState(clientFormData)
    const [modalProps, setModalProps] = useState('')
    const [formError, setFormErrors] = useState({});
    const { addUserSuccess, dataCenters, tenants, addedUser, error, loading } = useSelector(
        (state) => state?.userManagement
    )
    const { addDriverSuccess, error: driverError, loading: driverLoading } = useSelector(
        (state) => state?.driverManagement
    )

    const { addClientSuccess, error: clientError } = useSelector(
        (state) => state?.clientManagement
    )

    useEffect(() => {
        if (!tenants?.length) {
            dispatch(fetchTenants())
        }
        return () => {
            // This code will run when the component unmounts
            dispatch(resetClientData())
            dispatch(resetDriverData())
            dispatch(resetUserData())
        };
    }, [])

    const handleFieldChange = (event) => {
        if (event?.target?.name === 'activated' || event?.target?.name === 'tfaEnabled') {
            const name = event?.target?.name
            const value = event?.target?.checked
            setUserFormValues((prevValues) => ({
                ...prevValues,
                [name]: value,
            }));
        }
        else {
            const { name, value } = event.target;
            if (activeStep === 0) {
                setUserFormValues((prevValues) => ({
                    ...prevValues,
                    [name]: value,
                }));
            }
            else {
                if (userFormValues?.authorities?.value === roleConstantEnum?.ROLE_DRIVER) {
                    setDriverFormValues((prevValues) => ({
                        ...prevValues,
                        [name]: value,
                    }));
                }
                if (userFormValues?.authorities?.value === roleConstantEnum?.ROLE_CUSTOMER) {
                    setClientFormValues((prevValues) => ({
                        ...prevValues,
                        [name]: value,
                    }));
                }
            }
        }
    };

    const handleMultiSelectCheckboxClicked = (value) => {
        if (typeof value === 'object') {
            setClientFormValues((prevValues) => ({
                ...prevValues,
                serviceDays: value,
            }));
        }
        else {
            setClientFormValues((prevItems) => {
                // Check if the value is already in the list
                if (prevItems.serviceDays?.includes(value)) {
                    // If yes, remove it
                    return {
                        ...prevItems,
                        serviceDays: prevItems?.serviceDays?.filter((item) => item !== value),
                    };
                } else {
                    // If not, add it to the list
                    return {
                        ...prevItems,
                        serviceDays: [...(prevItems?.serviceDays || []), value],
                    };
                }
            });
        }
    }

    const handleImageUpload = (file) => {
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64Data = reader.result;
                setUserFormValues((prevValues) => ({
                    ...prevValues,
                    'userPhoto': base64Data
                }));
            };

            reader.readAsDataURL(file);
        }
    }

    useEffect(() => {
        if (addUserSuccess) {
            if (userFormValues?.authorities?.value === roleConstantEnum?.ROLE_DRIVER) {
                handleDriverSubmit()
            }
            else if (userFormValues?.authorities?.value === roleConstantEnum?.ROLE_CUSTOMER) {
                handleClientSubmit()
            }
            else {
                setModalProps(
                    {
                        title: 'Success',
                        message: 'User created successfully',
                        open: true,
                        type: 'success'
                    }
                )
                const redirectTimer = setTimeout(() => {
                    setModalProps(null)
                    dispatch(resetUserData())
                    navigate(urls?.USER_MANAGEMENT_WIDGET_URL)
                }, 2000);
                return () => {
                    clearTimeout(redirectTimer);
                }

            }
        }
    }, [addedUser]);


    useEffect(() => {
        if (error || driverError || clientError) {
            setModalProps(
                {
                    title: 'Error Occured!',
                    message: error || driverError || clientError,
                    open: true,
                    type: error
                }
            )

        }
    }, [error, driverError || clientError]);

    useEffect(() => {
        if (addDriverSuccess || addClientSuccess) {
            setModalProps(
                {
                    title: 'Success',
                    message: addDriverSuccess ? 'Driver created successfully' : 'Client created successfully',
                    open: true,
                    type: 'success'
                }
            )
            const redirectTimer = setTimeout(() => {
                setModalProps(null)
                dispatch(resetUserData())
                dispatch(resetDriverData())
                dispatch(resetClientData())
                navigate(urls?.USER_MANAGEMENT_WIDGET_URL)
            }, 2000);
            return () => {
                clearTimeout(redirectTimer);
            }
        }
    }, [addDriverSuccess || addClientSuccess]);

    const handleNext = () => {
        let errors = {}
        // Validate form values 
        if (activeStep === 0) {
            const notRequiredFields = ['userPhoto', 'activated']
            errors = validateForm(userFormValues, notRequiredFields);
        }
        if (activeStep === 1) {
            if (userFormValues?.authorities?.value === roleConstantEnum?.ROLE_DRIVER) {
                const notRequiredFields = ['vehicle', 'addressTwo']
                errors = validateForm(driverFormValues, notRequiredFields);
            }
            if (userFormValues?.authorities?.value === roleConstantEnum?.ROLE_CUSTOMER) {
                const notRequiredFields = ['addressTwo']
                errors = validateForm(clientFormValues, notRequiredFields);
                if (clientFormValues?.deliveryDateBuffer < clientFormValues?.eligibilityDay) {
                    errors['deliveryDateBuffer'] = 'Estimated Delivery Date Buffer should be greater than eligibility day'
                }
            }
        }

        // If validaion error, set it else if role in customer or driver, go to next step else handle user submit
        if (Object.keys(errors)?.length) {
            setFormErrors(errors)
        }
        else {
            setFormErrors({})
            if (activeStep === 0) {
                if (![roleConstantEnum?.ROLE_CUSTOMER, roleConstantEnum?.ROLE_DRIVER]?.includes(userFormValues?.authorities?.value)) {
                    handleUserSubmit();
                }
                else {
                    setActiveStep((prevStep) => prevStep + 1);
                }
            }
            if (activeStep === 1) {
                handleUserSubmit();
            }
        }
    }

    const handleUserSubmit = () => {
        if (!addedUser) {
            const { authorities, userPhoto, langKey, tenantName, dcName, activated, ...data } = userFormValues
            data['authorities'] = [authorities?.value]
            data['langKey'] = langKey?.value
            data['tenantName'] = tenantName?.value
            if (dcName?.value) {
                data['distributionCenterResponseVm'] = getDCObject(dcName?.value, dataCenters)
            }
            data['activated'] = activated
            if (userPhoto) {
                data['userPhoto'] = userPhoto?.split(',')?.[1];
            }
            dispatch(addUser(data))
        }
        else {
            if (userFormValues?.authorities?.value === roleConstantEnum?.ROLE_DRIVER) {
                handleDriverSubmit()
            }
            else if (userFormValues?.authorities?.value === roleConstantEnum?.ROLE_CUSTOMER) {
                handleClientSubmit()
            }
        }
    };

    const handleDriverSubmit = () => {
        const { currencyUnit, driverType, vehicle, inServiceStatus, vehicleType, ...data } = driverFormValues
        data['currencyUnit'] = currencyUnit?.value
        data['driverType'] = driverType?.value
        data['vehicleType'] = vehicleType?.value
        data['inServiceStatus'] = inServiceStatus?.value
        if (vehicle) {
            data['vehicleId'] = vehicle?.value
        }
        data['loginName'] = addedUser?.login
        dispatch(addDriver(data))
    };

    const handleClientSubmit = () => {
        const { fsaZones, ...data } = clientFormValues
        data['serviceFsaZones'] = fsaZones.map(item => item.label);
        data['loginName'] = addedUser?.login
        dispatch(addClient(data))
    }

    const handleBack = () => {
        setActiveStep((prevStep) => prevStep - 1);
    };

    const getStepContent = (stepIndex) => {
        switch (stepIndex) {
            case 0:
                return <AddUser handleFieldChange={handleFieldChange} formValues={userFormValues} handleImageUpload={handleImageUpload} formError={formError} />;
            case 1:
                return userFormValues?.authorities?.value === roleConstantEnum?.ROLE_DRIVER ? <AddDriver handleFieldChange={handleFieldChange} formValues={driverFormValues} formError={formError} loading={driverLoading} /> : userFormValues?.authorities?.value === roleConstantEnum?.ROLE_CUSTOMER ? <AddClient handleFieldChange={handleFieldChange} handleMultiSelectCheckboxClicked={handleMultiSelectCheckboxClicked} formValues={clientFormValues} formError={formError} setFormValues={setClientFormValues}/> : null;
            default:
                return 'Unknown step';
        }
    };

    return (
        <Layout headerTitle={'Add User'}>
            <FormContainer>
                <Stepper activeStep={activeStep} alternativeLabel>
                    <Step>
                        <StepLabel>Create User</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>Add Details</StepLabel>
                    </Step>
                </Stepper>
                {getStepContent(activeStep)}
                <ButtonContainer>
                    <Button variant="outlined" disabled={activeStep === 0} onClick={handleBack}>
                        Back
                    </Button>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => handleNext()}
                    >
                        {activeStep === 1 || ![roleConstantEnum?.ROLE_DRIVER, roleConstantEnum?.ROLE_CUSTOMER]?.includes(userFormValues?.authorities?.value) ? 'Finish' : 'Next'}
                    </Button>
                </ButtonContainer>
            </FormContainer>
            {modalProps ? <ResponseModal {...modalProps} handleClose={() => setModalProps(null)} /> : ''}
            {loading ? <Loader isLoading={loading} /> : null}
        </Layout>
    );
}