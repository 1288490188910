import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import DataTable from 'component/dataTable/dataTable'
import { ResponseModal } from 'component/common/Modal/ResponseModal'
import { Loader } from 'component/common/Loader/Loader'
import { fetchRoutes, removeRoute } from 'features/route/routeAction'
import { Layout } from 'component/common/Layout/Layout';
import { TableHeader } from 'component/dataTable/tableHeader';
import { TableFilter } from 'component/dataTable/tableFilter';
import 'component/userManagement/userAccount/UserAccount.scss'
import { convertDateRangeToUTC, iconStyle, getCurrentDateForFilter, getUserDCOption, checkPermission, getConstantValue, getGlobalFilter, getPercent, getDateWithTimeStamp, getMinMaxDate } from 'utils/common'
import { validateForm, validateStartAndEndDate } from 'utils/formValidator'
import CloseIcon from '@mui/icons-material/Close';
import { resetSuccessState, resetData } from 'features/route/routeSlice'
import { ConfirmationModal } from 'component/common/Modal/ConfirmationModal'
import InfoIcon from '@mui/icons-material/Info';
import { setAuditData } from 'features/audit/auditSlice'
import { Audit } from 'component/audit/audit'
import { setPackageFilter } from 'features/packages/packagesSlice'
import { setToteFilter } from 'features/warehouseOperation/warehouseOperationSlice'
import { Link, Tooltip } from '@mui/material';
import { DeliveryStatusFilter } from 'component/packages/DeliveryStatusFilter'
import { resetError } from 'features/route/routeSlice'
import { resetSuccessData } from 'features/dailyRoaster/dailyRoasterSlice'
import { urls } from 'utils/urls'
import { getInductionBarColor, totalParcelInTote } from 'utils/routeHelper'
import useFieldChange from 'hooks/useFieldChange';
import { ProgressBar } from 'component/common/ProgressBar/ProgressBar';
import { PrimaryButton } from 'component/common/Button/Button'
import { routeStatus, allowRouteShortage } from 'constants/routeStatus'
import { InductionRouteDetails } from './inductionRouteDetails'
import { DeliveryStatusCellRenderer } from 'component/packages/deliveryStatusCellRenderer'
import { getDeliveryStatusValue } from 'utils/packageHelper'

const date = getCurrentDateForFilter()
const formInitialState = {
    routeId: '',
    startDate: date,
    endDate: date,
}

export const Induction = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [page, setPage] = useState(0);

    const { routes, loading, error, routeCount, routeSuccess, successMessage } = useSelector(
        (state) => state?.route
    )
    const { isMobile } = useSelector(
        (state) => state?.viewport
    )
    const { user } = useSelector(
        (state) => state?.auth
    )
    const { sla, highPriority } = useSelector(
        (state) => state?.deliveryStatusManagement
    )

    const [modalProps, setModalProps] = useState(null)
    const [formValues, setFormValues, handleFieldChange] = useFieldChange(formInitialState);
    const [applyFilter, setApplyFilter] = useState(false);
    const [formError, setFormErrors] = useState({});
    const [sorteData, setSortedData] = useState(routes);
    const [updatedColumns, setUpdatedColumns] = useState([]);
    const [confirmationModalProps, setConfirmationModalProps] = useState(null);
    const [selectedRoute, setSelectedRoute] = useState(null)
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [resetSelectedRows, setResetSelectedRows] = useState(false)

    const rowKey = "inductionWidgetVM.routeId"
    const hasEditPermission = checkPermission(user, 'ROUTE')

    const fetchData = () => {
        const globalFilter = getGlobalFilter(highPriority, sla)
        const { startDate, endDate } = formValues
        const dateFilter = convertDateRangeToUTC(startDate, endDate, "YYYY-MM-DD", false, endDate ? false : true)
        
        const data = {}
        if (formValues?.routeId) {
            data['routeId'] = formValues?.routeId?.trim();
        }
        data['widget'] = 'INDUCTION'

        //clear redux state, to remove old data from route details
        dispatch(resetData())
        dispatch(fetchRoutes({
            page: page,
            size: rowsPerPage,
            ...data,
            ...globalFilter,
            ...dateFilter,
            dcName: getUserDCOption(user)?.value
        }))

    }
    useEffect(() => {
        fetchData()
    }, [page, applyFilter, rowsPerPage, highPriority, sla])

    useEffect(() => {
        if (routeSuccess) {
            setModalProps(
                {
                    title: 'Success',
                    message: successMessage ? successMessage : 'Route update successfully done!',
                    open: true,
                    type: 'success'
                }
            )
            setResetSelectedRows(true)
            const redirectTimer = setTimeout(() => {
                setConfirmationModalProps(null)
                setModalProps(null)
                dispatch(resetSuccessState())
                fetchData()
                setResetSelectedRows(false)
            }, 1000);
            return () => {
                clearTimeout(redirectTimer);
            }
        }
    }, [routeSuccess]);

    useEffect(() => {
        return () => {
            // This code will run when the component unmounts
            dispatch(resetError())
            dispatch(resetSuccessData())
        };
    }, [])

    useEffect(() => {
        if (error) {
            setModalProps(
                {
                    title: 'Error Occured!',
                    message: error,
                    open: true,
                    type: 'error'
                }
            )

        }
    }, [error]);

    useEffect(() => {
        //If user is on last page and deleted all records from that page, then page should be reset to page-1
        const pageCount = Math.ceil(routeCount / 10)
        if (routeCount && (page > 1) && (pageCount < page)) {
            setPage(page - 1)
        }
    }, [routeCount])

    const handlePageChange = (event, page) => {
        setPage(page)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event?.target?.value, 10));
        setPage(0);
    };

    const handleResetFilter = () => {
        setFormValues({
            routeId: '',
            startDate: '',
            endDate: '',
        }
        )
    }

    const handleRouteShortingClick = (routeId) => {
        const routeIds = routeId ? [routeId] : selectedRoute
        if (routeIds) {
            setConfirmationModalProps(
                {
                    title: 'Short Route',
                    message: 'Are you sure, you want to short this route?',
                    open: true,
                    onConfirm: handleRemoveRoute,
                    selectedId: routeIds,
                    onClose: handleConfirmationClose
                }
            )
        }
    }

    const handleConfirmationClose = () => {
        setConfirmationModalProps(null)
    }

    const handleRemoveRoute = (routeId) => {
        dispatch(removeRoute(routeId))
        handleConfirmationClose()
    }

    const handleApplyFilterClick = () => {
        const notRequiredFields = ['startDate', 'endDate', 'routeId']
        const errors = validateForm(formValues, notRequiredFields);
        const dateErrors = validateStartAndEndDate(formValues?.startDate, formValues?.endDate);
        if (Object.keys(errors).length || Object.keys(dateErrors).length) {
            setFormErrors(Object.keys(errors).length ? errors : dateErrors)
        }
        else {
            setFormErrors({})
            setApplyFilter(!applyFilter)
            setPage(0)
        }
    }

    const handleNavigation = (params) => {
        const { showTote, ...rest } = params
        if (showTote) {
            dispatch(setToteFilter(rest))
            navigate(urls?.TOTES_WIDGET_URL)
        }
        else {
            dispatch(setPackageFilter(rest))
            navigate(urls?.PARCEL_WIDGET_URL)
        }
    }

    const handleModelClose = () => {
        setModalProps(null)
        dispatch(resetError())
    }

    const handleRouteSelect = (routeIds) => {
        setSelectedRoute(routeIds);
    };

    //Column props should be json, so that any extra property can be added
    const columns = [
        {
            accessor: "inductionWidgetVM.routeId",
            title: "Route ID/Batch ID",
            width: "2rem",
            Cell: ({ cell: { row: { original } } }) => original?.inductionWidgetVM?.routeId || '--',
            render: (item) => item?.inductionWidgetVM?.routeId || '--'
        },
        {
            accessor: "inductionWidgetVM.dailyRouteStatus",
            title: "Route Status",
            width: "4rem",
            Cell: ({ cell: { row: { original } } }) => original?.inductionWidgetVM?.dailyRouteStatus ? getConstantValue(routeStatus, original?.inductionWidgetVM?.dailyRouteStatus) : '--',
            render: (item) => item?.inductionWidgetVM?.dailyRouteStatus ? getConstantValue(routeStatus, item?.inductionWidgetVM?.dailyRouteStatus) : '--'
        },
        {
            accessor: 'routeScanned',
            title: "Route Scanned",
            width: "10rem",
            isSortable: false,
            Cell: ({ cell: { row: { original } } }) => {
                const { color, backgroundImage } = getInductionBarColor();
                const pathFinderPercent = original?.inductionWidgetVM?.pathfinderScanCount && original?.inductionWidgetVM?.totalPackages ? getPercent(original?.inductionWidgetVM?.pathfinderScanCount, original?.inductionWidgetVM?.totalPackages) : 0;
                const ringScannerPercent = original.inductionWidgetVM?.ringScannerScanCount && original?.inductionWidgetVM?.totalPackages ? getPercent(original.inductionWidgetVM?.ringScannerScanCount, original?.inductionWidgetVM?.totalPackages) : 0;
                return (
                    <div style={{ width: '90%' }}>
                        <ProgressBar
                            totalStops={original?.inductionWidgetVM?.totalPackages}
                            completedStops={original?.inductionWidgetVM?.pathfinderScanCount}
                            remainingStops={original?.inductionWidgetVM?.ringScannerScanCount}
                            color={color}
                            backgroundImage={backgroundImage}
                            showText={false}
                            backgroundImagePercent={ringScannerPercent}
                            colorPercent={pathFinderPercent}
                        />
                    </div>
                );
            },
            render: (item) => {
                const totalPackages = item?.inductionWidgetVM?.totalPackages || 0;
                const pathfinderScanCount = item?.inductionWidgetVM?.pathfinderScanCount || 0;
                const ringScannerScanCount = item?.inductionWidgetVM?.ringScannerScanCount || 0;
                return `Total parcels:${totalPackages}, Pathfinder:${pathfinderScanCount}, Ringscanner:${ringScannerScanCount}`;
            }
        },
        {
            accessor: 'inductionWidgetVM.totalTotes',
            title: "Total Totes",
            width: "2rem",
            Cell: ({ cell: { row: { original } } }) => original?.inductionWidgetVM?.totalTotes ? <Link id='dailyRouteId' onClick={() => handleNavigation({
                routeId: original?.inductionWidgetVM?.routeId,
                showTote: true
            })} style={iconStyle} sx={{ textDecoration: 'none' }}>{original?.inductionWidgetVM?.totalTotes}</Link > : '--',
            render: (item) => item?.inductionWidgetVM?.totalTotes || '--'
        },
        {
            accessor: 'inductionWidgetVM.totalPackagesInTotes',
            title: "Total Parcels In Each Tote",
            width: "2rem",
            Cell: ({ cell: { row: { original } } }) => {
                const totalPackagesInTotes = original?.inductionWidgetVM?.totalPackagesInTotes;
                return totalParcelInTote(totalPackagesInTotes);
            },
            render: (item) => {
                const totalPackagesInTotes = item?.inductionWidgetVM?.totalPackagesInTotes;
                return totalParcelInTote(totalPackagesInTotes);
            }
        },
        {
            accessor: 'inductionWidgetVM.totalPackages',
            title: "Total Parcels",
            width: "2rem",
            Cell: ({ cell: { row: { original } } }) => original?.inductionWidgetVM?.totalPackages ? <Link id='routetId' style={{ cursor: 'pointer' }} sx={{ textDecoration: 'none' }} onClick={() => handleNavigation({
                routeId: original?.inductionWidgetVM?.routeId
            })}>{original?.inductionWidgetVM?.totalPackages}</Link> : original?.inductionWidgetVM?.totalPackages,
            render: (item) => item?.inductionWidgetVM?.totalPackages,
        },

        {
            accessor: 'inductionWidgetVM.driverId',
            title: "Driver Id",
            width: "3rem",
            Cell: ({ cell: { row: { original } } }) => original?.inductionWidgetVM?.driverId || '--',
            render: (item) => item?.inductionWidgetVM?.driverId || '--'
        },
        {
            accessor: 'inductionWidgetVM.driverName',
            title: "Driver Name",
            width: "3rem",
            Cell: ({ cell: { row: { original } } }) => original?.inductionWidgetVM?.driverName || '--',
            render: (item) => item?.inductionWidgetVM?.driverName || '--'
        },
        {
            accessor: 'inductionWidgetVM.largestFsaZone',
            title: "Largest FSA Zone",
            width: "3rem",
            Cell: ({ cell: { row: { original } } }) => original?.inductionWidgetVM?.largestFsaZone || '--',
            render: (item) => item?.inductionWidgetVM?.largestFsaZone || '--'
        },
        {
            accessor: "inductionWidgetVM.routeStartDate",
            title: "Route Start Date",
            width: "4rem",
            Cell: ({ cell: { row: { original } } }) => original?.inductionWidgetVM?.routeStartDate ? getDateWithTimeStamp(original?.inductionWidgetVM?.routeStartDate, user) : '--',
            render: (item) => item?.inductionWidgetVM?.routeStartDate ? getDateWithTimeStamp(item?.inductionWidgetVM?.routeStartDate, user) : '--'
        },
        {
            accessor: 'deliveryStatus',
            title: "Route Attributes",
            width: "5rem",
            Cell: ({ cell: { row: { original } } }) => <DeliveryStatusCellRenderer key={original?.inductionWidgetVM?.routeId} isHighPriority={original?.inductionWidgetVM?.highPriorityCount} isSLA={original?.inductionWidgetVM?.routeSLA} />,
            render: (item) => getDeliveryStatusValue(item?.inductionWidgetVM?.highPriorityCount, item?.inductionWidgetVM?.routeSLA),
            isSortable: false
        },
        {
            accessor: 'inductionWidgetVM.shortedAt',
            title: "Shorted At",
            width: "3rem",
            Cell: ({ cell: { row: { original } } }) => original?.inductionWidgetVM?.shortedAt ? getDateWithTimeStamp(original?.inductionWidgetVM?.shortedAt, user) : '--',
            render: (item) => item?.shortedAt ? getDateWithTimeStamp(item?.inductionWidgetVM?.shortedAt, user) : '--'
        },
        {
            accessor: 'inductionWidgetVM.createDate',
            title: "Created At",
            width: "3rem",
            Cell: ({ cell: { row: { original } } }) => original?.inductionWidgetVM?.createDate ? getDateWithTimeStamp(original?.inductionWidgetVM?.createDate, user) : '--',
            render: (item) => item?.inductionWidgetVM?.createDate ? getDateWithTimeStamp(item?.inductionWidgetVM?.createDate, user) : '--'
        },
        hasEditPermission && {
            accessor: 'action',
            title: "Action",
            width: "2rem",
            isSortable: false,
            Cell: ({ cell: { row: { original } } }) => <div className='action'>
                {allowRouteShortage?.includes(original?.inductionWidgetVM?.dailyRouteStatus) ?
                    <Tooltip title="Short"><CloseIcon data-testid="shorting-button" style={iconStyle} fontSize="small" onClick={() => handleRouteShortingClick(original?.inductionWidgetVM?.routeId)} /></Tooltip> : null
                }
                <Tooltip title="Info"><InfoIcon style={iconStyle} fontSize="small" onClick={() => dispatch(setAuditData({ 'entityId': original?.inductionWidgetVM?.routeId, 'entityType': 'daily_route' }))} /></Tooltip>
            </div>
        }
    ]?.filter(Boolean)

    const tableFilterProps = {
        fields: [
            {
                label: 'Route Id',
                value: formValues?.routeId,
                handleFieldChange: handleFieldChange,
                type: 'text',
                width: isMobile ? '9rem' : '15rem',
                name: 'routeId',
                placeholder: 'Route Id'
            },
            {
                label: 'Start Date',
                value: formValues?.startDate,
                handleFieldChange: handleFieldChange,
                type: 'date',
                width: isMobile ? '9rem' : '13rem',
                name: 'startDate',
                placeholder: 'DD-MM-YYYY',
                error: formError?.startDate,
                ...(formValues?.startDate ? {
                    min: getMinMaxDate()?.minDate,
                    max: getMinMaxDate()?.maxDate
                } : {})
            },
            {
                label: 'End Date',
                value: formValues?.endDate,
                handleFieldChange: handleFieldChange,
                type: 'date',
                width: isMobile ? '9rem' : '13rem',
                name: 'endDate',
                placeholder: 'DD-MM-YYYY',
                error: formError?.endDate,
            }],
        buttons: [
            {
                label: 'Apply',
                variant: 'contained',
                color: 'white',
                backgroundColor: 'black',
                onClick: handleApplyFilterClick
            },
            {
                label: 'Reset',
                variant: 'contained',
                color: 'white',
                backgroundColor: 'blue',
                onClick: handleResetFilter
            }
        ],
        margin: '0px',
        padding: '10px',
        borderRadius: '10px'
    }

    return (
        <Layout headerTitle={'Induction'} globalFilter={<DeliveryStatusFilter />} showBackArrow={false}
            action={<div><PrimaryButton type="button" label={'Short Route'} height={'30px'} onClick={() => handleRouteShortingClick()} disabled={!selectedRoute?.length} /></div>}>
            <div className='container'>
                <TableHeader columns={updatedColumns} data={sorteData} exportFileName='Induction' onRefreshClick={handleApplyFilterClick}
                />
                <TableFilter {...tableFilterProps} />
                <div className='content'>
                    <DataTable columns={columns} data={routes?.length ? routes : []} showPagination={routes?.length ? true : false} rowKey={rowKey} isCollpsable={true} page={page} totalRowsCount={routeCount} onPageChange={handlePageChange} onSortChange={setSortedData} setUpdatedColumns={setUpdatedColumns} handleChangeRowsPerPage={handleChangeRowsPerPage} rowsPerPage={rowsPerPage} allowSelect={true} onSelectClick={handleRouteSelect} resetSelectedRows={resetSelectedRows} uniqueKey={'inductionWidgetVM.routeId'} childComponent={InductionRouteDetails} />
                </div>
            </div>
            {modalProps ? <ResponseModal {...modalProps} handleClose={handleModelClose} /> : null}
            {loading ? <Loader isLoading={loading} /> : null}
            {confirmationModalProps ? <ConfirmationModal {...confirmationModalProps} /> : null}
            <Audit />
        </Layout>
    )
}