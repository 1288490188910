import { environment } from "../environments/environment.jsx";
import api from './api.js';
import { getHeader } from './authUtils.js'

const apiEndpoint = environment.webservices.endpoint;

export class NotificationServices {
    fetchNotifications(queryParams) {
        const endpoint = `${apiEndpoint}/api/notification`
        return api.get(endpoint, {
            headers: getHeader(),
            params: queryParams
        });
    }

    sendNotification(formData) {
        const endpoint = `${apiEndpoint}/api/notification/topic`;
        return api.post(endpoint, formData , {
            headers: getHeader(),
        });
    }

    subscribeToNotifications(formData) {
        const {fcmToken, topics} = formData
        const endpoint = `${apiEndpoint}/api/notification/subscribe?fcmToken=${fcmToken}`
        return api.put(endpoint, topics ,{
            headers: getHeader()
        });
    }

    markNotificationAsRead(formData) {
        const endpoint = `${apiEndpoint}/api/notification/mark-read`;
        return api.put(endpoint, formData , {
            headers: getHeader(),
        });
    }
    
}


