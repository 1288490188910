import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { validateForm } from 'utils/formValidator'
import { addTier, updateTier } from 'features/rates/rateAction'
import { CustomForm } from 'component/common/Form/CustomForm'
import { CustomModal } from 'component/common/Modal/CustomModal'
import useFieldChange from 'hooks/useFieldChange';
import 'component/dailyRoaster/AddDuty.scss'
import { tierType } from './CustomerMSADetail';

const formInitialState = {
    dimSurCharge: '',
    dimentionUnit: "CUBIC_INCH",
    lowerBound: '',
    tierName: '',
    tierType: '',
    upperBound: '',
    volDiscount: '',
    weightSurcharge: '',
    weightUnit: 'POUNDS',
}

export const AddTier = ({ isModelOpen, handleClose, selectedRate, customersMSARate, clientMSAId }) => {
    const dispatch = useDispatch();
    const [formValues, setFormValues, handleFieldChange] = useFieldChange(formInitialState);
    const [formError, setFormErrors] = useState({});

    useEffect(() => {
        if (selectedRate) {
            setFormValues((prevValues) => ({
                ...prevValues,
                dimSurCharge: selectedRate?.dimSurCharge,
                lowerBound: selectedRate?.lowerBound,
                tierName: selectedRate?.tierName,
                tierType: selectedRate?.tierType,
                upperBound: selectedRate?.upperBound,
                volDiscount: selectedRate?.volDiscount,
                weightSurcharge: selectedRate?.weightSurcharge,

            }))
        }
    }, [selectedRate])

    const handleFormSubmit = () => {
        const notRequiredFields = []
        if(selectedRate?.tierType === tierType?.VOLUME){
            notRequiredFields.push('dimentionUnit', 'dimSurCharge','weightSurcharge', 'weightUnit')
        } else if (selectedRate?.tierType === tierType?.DIMENTION) {
            notRequiredFields.push('volDiscount', 'weightSurcharge', 'weightUnit')
        } else {
            notRequiredFields.push('volDiscount','dimentionUnit','dimSurCharge')
        }
        
        const errors = validateForm(formValues, notRequiredFields);
        if (Object.keys(errors).length) {
            setFormErrors(errors)
        }
        else {
            setFormErrors({})
            const data = { ...formValues }
            data['businessName'] = customersMSARate?.businessName
            data['customerId'] = customersMSARate?.clientId
            data['clientMsaId'] = clientMSAId
            dispatch(selectedRate?.tierName ? updateTier(data) : addTier(data))
        }
    }

    const handleCancel = () => {
        setFormValues(formInitialState)
        handleClose()
    }

    const formProps = {
        fields: [
            {
                label: 'Tier Name',
                name: 'tierName',
                required: true,
                type: 'text',
                value: formValues?.tierName || '',
                handleFieldChange: handleFieldChange,
                width: '19rem',
                error: formError?.tierName,
                disabled : selectedRate?.tierName
            },
            {
                label: 'Lower Bound',
                name: 'lowerBound',
                required: true,
                type: 'text',
                value: formValues?.lowerBound || '',
                handleFieldChange: handleFieldChange,
                width: '19rem',
                error: formError?.lowerBound,
            },
            {
                label: 'Upper Bound',
                name: 'upperBound',
                required: true,
                type: 'text',
                value: formValues?.upperBound || '',
                handleFieldChange: handleFieldChange,
                width: '19rem',
                error: formError?.upperBound,
            },
            selectedRate?.tierType === tierType?.DIMENTION && {
                label: 'Dimesion Surcharge',
                name: 'dimSurCharge',
                required: true,
                type: 'text',
                value: formValues?.dimSurCharge || '',
                handleFieldChange: handleFieldChange,
                width: '19rem',
                error: formError?.dimSurCharge,
            },
            selectedRate?.tierType === tierType?.DIMENTION && {
                label: 'Dimension Unit',
                name: 'dimentionUnit',
                required: true,
                type: 'text',
                value: formValues?.dimentionUnit || '',
                handleFieldChange: handleFieldChange,
                width: '19rem',
                error: formError?.dimentionUnit,
                disabled: true
            },
            selectedRate?.tierType === tierType?.VOLUME && {
                label: 'Volume Discount',
                name: 'volDiscount',
                required: true,
                type: 'text',
                value: formValues?.volDiscount || '',
                handleFieldChange: handleFieldChange,
                width: '19rem',
                error: formError?.volDiscount,
            },
            selectedRate?.tierType === tierType?.WEIGHT && {
                label: 'Weight Surcharge',
                name: 'weightSurcharge',
                required: true,
                type: 'text',
                value: formValues?.weightSurcharge || '',
                handleFieldChange: handleFieldChange,
                width: '19rem',
                error: formError?.weightSurcharge,
            },
            selectedRate?.tierType === tierType?.WEIGHT && {
                label: 'Weight Unit',
                name: 'weightUnit',
                required: true,
                type: 'text',
                value: formValues?.weightUnit || '',
                handleFieldChange: handleFieldChange,
                width: '19rem',
                error: formError?.weightUnit,
                disabled: true
            }
        ]?.filter(Boolean),
        buttons: [
            {
                label: 'submit',
                onClick: handleFormSubmit
            },
            {
                label: 'Cancel',
                onClick: handleClose,
                color: 'error'
            }
        ],
        fieldGap: '0px',
        margin: '0px',
        padding: '0rem 1rem',
        fieldAlignment: 'center',
    }

    return (
        <>
            <CustomModal
                open={isModelOpen}
                onClose={handleCancel}
            >
                <div className='add-duty-modal'>
                    <span className='title'>{selectedRate?.tierName ? 'Update Tier' : 'Add Tier'}</span>
                    <CustomForm {...formProps} />
                </div>
            </CustomModal>
        </>

    )

}