import { createAsyncThunk } from '@reduxjs/toolkit'
import { WarehouseOperationService } from 'services/warehouseOperationService'
const warehouseOperationService = new WarehouseOperationService();

export const fetchTotesData = createAsyncThunk(
    'warehouseOperation/fetchTotesData',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await warehouseOperationService.getTotesData(formData)
            return { totes: response?.data, totalCount: response?.headers?.get('X-Total-Count') }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
)

export const addTote = createAsyncThunk(
    'warehouseOperation/addTote',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await warehouseOperationService.addTote(formData)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
)

export const updateTote = createAsyncThunk(
    'warehouseOperation/updateTote',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await warehouseOperationService.updateTote(formData)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
)

export const fetchWarehouseAsset = createAsyncThunk(
    'warehouseOperation/fetchWarehouseAsset',
    async (_, { rejectWithValue }) => {
        try {
            const response = await warehouseOperationService.getWarehouseAsset()
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    })

export const fetchWarehouseData = createAsyncThunk(
    'warehouseOperation/fetchWarehouseData',
    async (_, { rejectWithValue }) => {
        try {
            const response = await warehouseOperationService.getWarehouseData()
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
)

export const freezeOperation = createAsyncThunk(
    'warehouseOperation/freezeOperation',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await warehouseOperationService.freezeOperation(formData)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
)

export const resetWarehouseData = createAsyncThunk(
    'warehouseOperation/resetWarehouseData',
    async (_, { rejectWithValue }) => {
        try {
            const response = await warehouseOperationService.resetWarehouseData()
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
)

export const fetchEmptyLocations = createAsyncThunk(
    'warehouseOperation/fetchEmptyLocations',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await warehouseOperationService.fetchEmptyLocations(formData)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
)

export const fetchEligibleRollerCages = createAsyncThunk(
    'warehouseOperation/fetchEligibleRollerCages',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await warehouseOperationService.fetchRollerCages(formData)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
)

export const fetchUnAssignedRollerCages = createAsyncThunk(
    'warehouseOperation/fetchUnAssignedRollerCages',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await warehouseOperationService.fetchRollerCages(formData)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
)

export const addRollerCage = createAsyncThunk(
    'warehouseOperation/addRollerCage',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await warehouseOperationService.addRollerCage(formData)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
)

export const relocateRollerCage = createAsyncThunk(
    'warehouseOperation/relocateRollerCage',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await warehouseOperationService.relocateRollerCage(formData)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
)

export const relocateTote = createAsyncThunk(
    'warehouseOperation/relocateTote',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await warehouseOperationService.relocateTote(formData)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
)