import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';
import { CustomModal } from 'component/common/Modal/CustomModal'
import { TextField } from 'component/common/Input/TextField'
import 'component/dailyRoaster/AddDuty.scss'

export const InternalCommentModal = ({ isModelOpen, handleClose, handleOnHold, parcel, status }) => {
    const [internalComment, setInternalComment] = useState('');

    const { isMobile } = useSelector(
        (state) => state?.viewport
    )

    const handleFieldChange = (event) => {
        const { value } = event.target;
        setInternalComment(value)
    };

    const handleFormSubmit = () => {
        handleOnHold(parcel, status, internalComment)
        handleClose()
    }

    return (
        <CustomModal
            open={isModelOpen}
            onClose={handleClose}
        >
            <div className='add-duty-modal'>
                <div><span className='title'>{'Mark On Hold'}</span></div>
                <TextField type="text" label='Internal Comments' placeholder="Add internal Comments" name="internalComment" value={internalComment} onChange={handleFieldChange} required={true} width={isMobile ? '100%' : '18rem'} />
                <div className='button-container'>
                    <Button onClick={handleFormSubmit} disabled={!internalComment?.trim()?.length} variant='outlined'>Save</Button>
                    <Button onClick={handleClose} variant='outlined'>Cancel</Button>
                </div>
            </div>
        </CustomModal>
    )

}