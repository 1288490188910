import React, { useState } from 'react';
import { ErrorMessage, FieldWrapper } from '../SelectField'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import "./DateRangePickerField.css";

export const DateRangePickerField = ({ label, lableColor, error, handleFieldChange, info, placeholder, required, width, timeZone, min, max, ...rest }) => {
    const [dateRange, setDateRange] = useState([new Date(), new Date()]);
    const [startDate, endDate] = dateRange;

    const handeleDateUpdate = () => {
        if (startDate && endDate) {
            const formattedStarDate = startDate?.toISOString().split('T')[0];
            const formattedEndDate = endDate?.toISOString().split('T')[0];
            handleFieldChange(formattedStarDate, formattedEndDate)
        }
    }

    return (
        <FieldWrapper width={width}>
            <div>
                <DatePicker
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(update) => {
                        setDateRange(update);
                    }}
                    dateFormat="dd-MM-yyyy"
                    isClearable={false}
                    onCalendarClose={handeleDateUpdate}
                    placeholderText="Select Date"
                    minDate={min}
                    maxDate={max}
                />
            </div>
            {required && (!startDate || !endDate) && (
                <ErrorMessage width={width}>Enter valid date</ErrorMessage>
            )}
            {error && <ErrorMessage width={width}>{error}</ErrorMessage>}
        </FieldWrapper>
    );
}
