import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { openDrawer, closeDrawer } from 'features/drawer/drawerAction'
import { NotificationPanel } from './NotificationPanel.jsx'
import { AppBarMenu } from './AppBarMenu.jsx'
import './Layout.scss'

const drawerWidth = 230;
const drawerMinHeight = '50px';

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    backgroundColor: 'white',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

export const WebLayout = ({ action, headerTitle, showBackArrow, children, handleBackClick, handleBackArrowClick, globalFilter }) => {
    const dispatch = useDispatch();
    const [notificationOpen, setNotificationOpen] = useState(false);

    const { isDrawerOpen } = useSelector(
        (state) => state?.drawer
    )

    const { user } = useSelector(
        (state) => state?.auth
    )

    const { messages } = useSelector(
        (state) => state?.notifications
    )

    const handleDrawerOpen = () => {
        dispatch(openDrawer())
    };

    const handleDrawerClose = () => {
        dispatch(closeDrawer())
    };

    const handleNotificationClick = () => {
        setNotificationOpen(!notificationOpen);
    };

    const handleCloseNotification = () => {
        setNotificationOpen(false);
    };


    const faviconUrl = `${process.env.PUBLIC_URL}/favicon.png`;

    return (
        <Box sx={{ display: 'flex' }} className='layout-container'>
            <CssBaseline />
            <AppBar position="fixed" open={isDrawerOpen}>
                <Toolbar sx={{ backgroundColor: 'white', display: 'flex', justifyContent: 'space-between' }} style={{ minHeight: drawerMinHeight }}>
                    <div className='title-wrapper'>
                        <IconButton
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{
                                ...(isDrawerOpen && { display: 'none' }),
                            }}
                        >
                            <img src={faviconUrl} style={{ height: '25px', width: '25px' }} alt='favicon' />
                            <ChevronRightIcon />
                        </IconButton>
                        {showBackArrow ?
                            <IconButton aria-label="back" color='primary' onClick={handleBackClick || handleBackArrowClick}>
                                <img src="/static/images/arrow-left-long-solid.svg" alt='Home' />
                            </IconButton> : null}
                        <Typography className='title' data-testid={headerTitle}>
                            {headerTitle}
                        </Typography>
                        {globalFilter}
                    </div>
                    <div className='action'>
                        {action}
                        <AppBarMenu messages={messages} handleNotificationClick={handleNotificationClick} />
                    </div>
                </Toolbar>
            </AppBar>
            <NotificationPanel open={notificationOpen} onClose={handleCloseNotification} />
            <Box component="main" sx={{ flexGrow: 1, padding: `${drawerMinHeight} 0px 0px 0px` }}>
                {children}
            </Box>
        </Box>
    );
}
