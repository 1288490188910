import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { fetchEmptyLocations, addRollerCage, addTote, fetchEligibleRollerCages } from 'features/warehouseOperation/warehouseOperationAction'
import { resetData } from 'features/warehouseOperation/warehouseOperationSlice'
import { CustomForm } from 'component/common/Form/CustomForm'
import { getOptionsForSelectField } from 'utils/common'
import { CustomModal } from 'component/common/Modal/CustomModal'
import useFieldChange from 'hooks/useFieldChange';
import 'component/dailyRoaster/AddDuty.scss'
import { getRollerCageLists } from 'utils/warehouseOperationHelper'

const formInitialState = {
    assetType: 'tote',
    locationId: '',
    aisleName: '',
    rollerCage: '',
    totalCount: 1
}

export const AddAsset = ({ isModelOpen, handleClose }) => {
    const dispatch = useDispatch();
    const { loading, emptyLocations, eligibleRollerCages } = useSelector(
        (state) => state?.warehouseOperation
    )

    const [formValues, setFormValues, handleFieldChange] = useFieldChange(formInitialState);
    const [formError, setFormErrors] = useState({});

    useEffect(() => {
        dispatch(fetchEmptyLocations())
        dispatch(fetchEligibleRollerCages({
            rollerCageType: 'ELIGIBLE_FOR_TOTE'
        }))
    }, [])

    useEffect(() => {
        if (formValues?.locationId) {
            setFormValues((prevValues) => ({
                ...prevValues,
                totalCount: 1
            }));
        }
    }, [formValues?.locationId, formValues?.rollerCage])

    const handleFormSubmit = () => {
        const data = {};
        const errors = {}; // Corrected typo from "errros" to "errors"
    
        // Validate formValues based on assetType
        if (formValues?.assetType === 'roller-cage' && formValues?.totalCount > 10) {
            errors['totalCount'] = 'Value must be between 1 to 10';
        } 
        else if (formValues?.assetType === 'tote') {
            if (formValues?.rollerCage && formValues?.totalCount > 4) {
                errors['totalCount'] = 'Value must be between 1 to 4';
            } else if (formValues?.totalCount > 10) {
                errors['totalCount'] = 'Value must be between 1 to 10';
            }
        }
    
        // Set errors and return early if any exist
        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);
            return;
        }
    
        // Clear errors since values are valid
        setFormErrors({});
    
        // Prepare data based on asset type
        if (formValues?.assetType === 'roller-cage') {
            if (formValues?.locationId) {
                data['locationId'] = formValues?.locationId?.value;
            }
            data['count'] = formValues?.totalCount;
            dispatch(addRollerCage(data));
        } 
        else if (formValues?.assetType === 'tote') {
            if (formValues?.rollerCage) {
                data['rollerCageName'] = formValues?.rollerCage?.value;
            }
            data['toteCapacityUnit'] = 'LT';
            data['toteCapacity'] = 20;
            data['count'] = formValues?.totalCount;
            dispatch(addTote(data));
        }
    };    

    const handleCancel = () => {
        setFormValues(formInitialState)
        dispatch(resetData())
        handleClose()
    }

    const formProps = {
        fields: [
            {
                type: 'radio',
                name: 'assetType',
                label: 'Select Asset',
                options: [
                    { label: 'Tote', value: 'tote' },
                    { label: 'Roller Cage', value: 'roller-cage' }
                ],
                value: formValues?.assetType,
                handleChange: handleFieldChange,
                width: '18rem',
                error: formError?.assetType
            },
            formValues?.assetType === 'tote' &&
            {
                label: 'Roller Cage',
                name: 'rollerCage',
                type: 'select',
                options: getRollerCageLists(eligibleRollerCages),
                value: formValues?.rollerCage || '',
                handleFieldChange: handleFieldChange,
                error: formError?.rollerCage,
                width: '18rem',
                position: 'static',
            },
            formValues?.assetType === 'roller-cage' &&
            {
                label: 'Location',
                name: 'locationId',
                type: 'select',
                options: getOptionsForSelectField(emptyLocations),
                value: formValues?.locationId || '',
                handleFieldChange: handleFieldChange,
                error: formError?.locationId,
                width: '18rem',
                position: 'static',
            },
            {
                label: 'Count',
                name: 'totalCount',
                type: 'text',
                value: formValues?.totalCount || '',
                handleFieldChange: handleFieldChange,
                width: '18rem',
                error: formError?.totalCount,
                min: 1,
                max: 10,
                disabled: formValues?.locationId
            }
        ],
        buttons: [
            {
                label: 'submit',
                onClick: handleFormSubmit
            },
            {
                label: 'Cancel',
                onClick: handleClose,
                color: 'error'
            }
        ],
        loading: loading,
        fieldGap: '0px',
        margin: '0px',
        padding: '0rem 1rem'
    }

    return (
        <>
            <CustomModal
                open={isModelOpen}
                onClose={handleCancel}
            >
                <div className='add-duty-modal'>
                    <span className='title'>{'Add Asset'}</span>
                    <CustomForm {...formProps} />                </div>
            </CustomModal>
        </>

    )

}