const routeStatusToAllowUpdate = ['ROUTE_CREATED', 'DRIVER_ASSIGNED', 'DRIVER_DECLINED']

/*
If driver have reported and route not started, show green color - as it can be change
If driver have reported and route started, show red color - as it can not be change
If driver have not reported show rgray color
*/

export const getRoasterStausColor = (roaster) => {
    const dateTime = roaster?.reportingTime ? new Date(roaster?.reportingTime) : null
    const currentDate = new Date();
    if (dateTime) {
        if ((dateTime < currentDate) && !routeStatusToAllowUpdate.includes(roaster?.assignedRouteStatus) && roaster?.dutyAssigned === 'ALLOTTED') {
            return 'red'
        }
        return 'green'
    }
    if (!dateTime) {
        return 'gray'
    }
}

export const allowDutyAssign = (status) => {
    if (status) {
        return routeStatusToAllowUpdate.includes(status)
    }
    return true
}