import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { validateForm } from 'utils/formValidator'
import { updateRemediatePackage } from 'features/packages/packagesAction'
import 'component/dailyRoaster/AddDuty.scss'
import { CustomForm } from 'component/common/Form/CustomForm'
import { getUserTimeZone, getUTCTime } from 'utils/common'
import { resetError } from 'features/packages/packagesSlice'
import { CustomModal } from 'component/common/Modal/CustomModal'
import useFieldChange from 'hooks/useFieldChange';
import { getPackageIds } from 'utils/packageHelper';

const formInitialState = {
    businesstype: '',
    businessStartTime: '',
    businessEndTime: '',
}

export const UpdateBusiness = ({ isModelOpen, handleClose, user }) => {
    const dispatch = useDispatch();
    const userTimeZone = getUserTimeZone(user?.distributionCenterResponseVm?.timeZone) || 'America/Toronto'
    const { selectedPackages } = useSelector(
        (state) => state?.packages
    )


    const { isMobile } = useSelector(
        (state) => state?.viewport
    )

    const [formValues, setFormValues, handleFieldChange] = useFieldChange(formInitialState);
    const [formError, setFormErrors] = useState({});
    
    useEffect(() => {
        return () => {
            // This code will run when the component unmounts
            dispatch(resetError())
        };
    }, [])

    const handleFormSubmit = () => {
        let notRequiredFields = [];
        if (formValues?.businesstype !== 'yes') {
            notRequiredFields = ['businessStartTime', 'businessEndTime'];
        }
        const errors = validateForm(formValues, notRequiredFields);
        setFormErrors(errors);
        
        if (!Object.keys(errors).length) {
            setFormErrors({});
            
            const data = {
                trackingNumber: getPackageIds(selectedPackages),
                startTime: formValues?.businesstype === 'yes' ? getUTCTime(formValues?.businessStartTime, userTimeZone) : null,
                endTime: formValues?.businesstype === 'yes' ? getUTCTime(formValues?.businessEndTime, userTimeZone) : null,
                isLocationVerified: formValues.businesstype === 'yes',
                widgetName : 'REMEDIATION_T2'
            };
            
            dispatch(updateRemediatePackage(data));
        }
    };
    
    const handleDateChange = (name, date) => {
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: date,
            
        }));
    };

    const handleRadioChange = (event) => {
        const { name, value } = event.target;
        setFormValues((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const formProps = {
        fields: [
            {
                type: 'radio',
                name: 'businesstype',
                label: 'Business Type',
                options: [
                    { label: 'Yes', value: 'yes' },
                    { label: 'No', value: 'no' }
                ],
                value: formValues?.businesstype,
                handleChange: handleRadioChange,
                required: true,
                error: formError?.businesstype
            },
            formValues?.businesstype === 'yes' && {
                label: 'Start Time',
                name: 'businessStartTime',
                required: true,
                type: 'datetime',
                value: formValues?.businessStartTime || '',
                handleFieldChange: (date) => handleDateChange('businessStartTime', date),                
                width: '18rem',
                error: formError?.businessStartTime,
                showOnlyTime: true
            },
            formValues?.businesstype === 'yes' && {
                label: 'End Time',
                name: 'businessEndTime',
                required: true,
                type: 'datetime',
                value: formValues?.businessEndTime || '',
                handleFieldChange: (date) => handleDateChange('businessEndTime', date),
                width: '18rem',
                error: formError?.businessEndTime,
                showOnlyTime: true
            },
            ],
        buttons: [
            {
                label: 'submit',
                onClick: handleFormSubmit
            },
            {
                label: 'Cancel',
                onClick: handleClose,
                color: 'error'
            }
        ],
        fieldGap: '0.5rem',
        margin: '0px',
        padding: '0rem 1rem',
        fieldAlignment: isMobile ? 'center' : 'unset'
    }

    return (
        <CustomModal
            open={isModelOpen}
            onClose={handleClose}
        >
            <div className='add-duty-modal'>
                <div><span className='title'>Business Hours</span></div>
                <CustomForm {...formProps} allowOverflow='unset'/>
            </div>
        </CustomModal>
    )

}