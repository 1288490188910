import { environment } from "../environments/environment.jsx";
import { getHeader } from './authUtils.js'
import api from './api';

const apiEndpoint = environment.webservices.endpoint;

export class WarehouseOperationService {
    getRollerCages() {
        const endpoint = `${apiEndpoint}/api/vehicles`;
        return api.get(endpoint, {
            headers: getHeader(),
        });
    }

    getTotesData(queryparams) {
        const endpoint = `${apiEndpoint}/api/totes`;
        return api.get(endpoint, {
            headers: getHeader(),
            params: queryparams
        });
    }

    addTote(formData) {
        const endpoint = `${apiEndpoint}/api/totes/addtote`;
        return api.post(endpoint, formData, {
            headers: getHeader(),
        });

    }
    
    updateTote(formData) {
        const { id, ...rest } = formData
        const endpoint = `${apiEndpoint}/api/totes/${id}`;
        return api.put(endpoint, rest, {
            headers: getHeader(),
        });
    }

    addRollerCage(formData) {
        const endpoint = `${apiEndpoint}/api/roller-cage/addrollercage`;
        return api.post(endpoint, formData, {
            headers: getHeader(),
        });
    }

    relocateRollerCage(formData) {
        const endpoint = `${apiEndpoint}/api/roller-cage/relocate  `;
        return api.put(endpoint, formData, {
            headers: getHeader(),
        });
    }

    relocateTote(formData) {
        const endpoint = `${apiEndpoint}/api/totes/updateTote?operation=RelocateTote`;
        return api.put(endpoint, formData, {
            headers: getHeader(),
        });
    }

    freezeOperation(formData) {
        const endpoint = `${apiEndpoint}/api/data-centers?operation=${formData?.operation}`;
        return api.put(endpoint, null, {
            headers: getHeader(),
        });
    }

    getWarehouseData() {
        const endpoint = `${apiEndpoint}/api/totes/getlocationmap`;
        return api.get(endpoint, {
            headers: getHeader(),
        });
    }

    getWarehouseAsset() {
        const endpoint = `${apiEndpoint}/api/totes/inductionfloorassets`;
        return api.get(endpoint, {
            headers: getHeader(),
        });
    }

    getAisel() {
        const endpoint = `${apiEndpoint}/api/aisle`;
        return api.get(endpoint, {
            headers: getHeader(),
        });
    }

    resetWarehouseData() {
        const endpoint = `${apiEndpoint}/api/totes/resetInductionFloor`;
        return api.put(endpoint, null, {
            headers: getHeader(),
        });
    }
    

    fetchEmptyLocations() {
        const endpoint = `${apiEndpoint}/api/location/emptylocations`;
        return api.get(endpoint, {
            headers: getHeader(),
        });
    }

    fetchRollerCages(formData) {
        // Create URLSearchParams from queryParams object
        const queryString = new URLSearchParams(formData).toString();
        const endpoint = queryString ? `${apiEndpoint}/api/roller-cage?${queryString}` : `${apiEndpoint}/api/roller-cage`;
        return api.get(endpoint, {
            headers: getHeader(),
        });
    }


}