import { useSelector } from 'react-redux';
import { getFSAZones, getUserTimeZone, getUTCTime } from 'utils/common'
import { convertToManifestCutoffTime, convertToLocalDateTime } from 'utils/userAccountHelper'

const serviceDays = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY']

export const ClientFormFields = ({ formValues, formError, handleFieldChange, handleMultiSelectCheckboxClicked, isEdit = false, setFormValues }) => {
    const { isMobile } = useSelector(
        (state) => state?.viewport
    )
    const { fsaZones } = useSelector(
        (state) => state?.fsaZones
    )

    const { user } = useSelector(
        (state) => state?.auth
    )

    const userTimeZone = getUserTimeZone(user?.distributionCenterResponseVm?.timeZone) || 'America/Toronto'

    const handleDateChange = (date) => {
        const cutOffTime = convertToManifestCutoffTime(getUTCTime(date, userTimeZone))
        setFormValues((prevValues) => ({
            ...prevValues,
            manifestCutoffTime: cutOffTime
        }));
    };

    return {
        fieldList: [
            {
                title: 'Business Details',
                fields: [{
                    label: 'Business Name',
                    name: 'businessName',
                    required: true,
                    type: 'text',
                    value: formValues?.businessName || '',
                    handleFieldChange: handleFieldChange,
                    error: formError?.businessName,
                    maxLength: 50,
                    width: isMobile ? '17rem' : '14rem',
                    disabled: isEdit
                },
                {
                    label: 'Client Code',
                    name: 'clientCode',
                    required: true,
                    type: 'text',
                    value: formValues?.clientCode || '',
                    handleFieldChange: handleFieldChange,
                    error: formError?.clientCode,
                    maxLength: 50,
                    width: isMobile ? '17rem' : '14rem'
                },
                {
                    label: 'Company Code',
                    name: 'companyCode',
                    required: true,
                    type: 'text',
                    value: formValues?.companyCode || '',
                    handleFieldChange: handleFieldChange,
                    error: formError?.companyCode,
                    maxLength: 50,
                    width: isMobile ? '17rem' : '14rem'
                },
                {
                    label: 'FSA Zones',
                    name: 'fsaZones',
                    required: true,
                    type: 'select',
                    options: getFSAZones(fsaZones),
                    value: formValues?.fsaZones || '',
                    handleFieldChange: handleFieldChange,
                    error: formError?.fsaZones,
                    width: isMobile ? '17rem' : '14rem',
                    isMultiselect: true
                }]
            },
            {
                title: 'Service Details',
                fields: [
                    {
                        label: 'Service Days',
                        name: 'serviceDays',
                        required: true,
                        type: 'multi-checkbox',
                        options: serviceDays,
                        value: formValues?.serviceDays,
                        handleFieldChange: handleMultiSelectCheckboxClicked,
                        error: formError?.serviceDays,
                        maxLength: 30,
                        width: isMobile ? '17rem' : '14rem',
                        showSelectAll: true
                    },
                    {
                        label: 'Estimated Delivery Date Buffer',
                        name: 'deliveryDateBuffer',
                        required: true,
                        type: 'text',
                        value: formValues?.deliveryDateBuffer,
                        handleFieldChange: handleFieldChange,
                        error: formError?.deliveryDateBuffer,
                        maxLength: 30,
                        width: isMobile ? '17rem' : '14rem'
                    },
                    {
                        label: 'Eligibility Day',
                        name: 'eligibilityDay',
                        required: true,
                        type: 'text',
                        value: formValues?.eligibilityDay,
                        handleFieldChange: handleFieldChange,
                        error: formError?.eligibilityDay,
                        maxLength: 30,
                        width: isMobile ? '17rem' : '14rem'
                    },
                    {
                        label: 'Expected Manifests',
                        name: 'expectedManifests',
                        required: true,
                        type: 'text',
                        value: formValues?.expectedManifests,
                        handleFieldChange: handleFieldChange,
                        error: formError?.expectedManifests,
                        maxLength: 30,
                        width: isMobile ? '17rem' : '14rem'
                    },
                    {
                        label: 'Manifest Cutoff Time',
                        name: 'manifestCutoffTime',
                        required: true,
                        type: 'datetime',
                        value: convertToLocalDateTime(formValues?.manifestCutoffTime, userTimeZone),
                        handleFieldChange: handleDateChange,
                        width: '18rem',
                        error: formError?.manifestCutoffTime,
                        timeZone: userTimeZone,
                        showOnlyTime: true
                    }
                ]
            },
            {
                title: 'Address Details',
                fields: [
                    {
                        label: 'Address One',
                        name: 'addressOne',
                        required: true,
                        type: 'text',
                        value: formValues?.addressOne || '',
                        handleFieldChange: handleFieldChange,
                        width: isMobile ? '17rem' : '12rem',
                        error: formError?.addressOne,
                    },
                    {
                        label: 'Address Two',
                        name: 'addressTwo',
                        required: false,
                        type: 'text',
                        value: formValues?.addressTwo || '',
                        handleFieldChange: handleFieldChange,
                        width: isMobile ? '17rem' : '12rem',
                        error: formError?.addressTwo,
                    },
                    {
                        label: 'City',
                        name: 'city',
                        required: true,
                        type: 'text',
                        value: formValues?.city || '',
                        handleFieldChange: handleFieldChange,
                        width: isMobile ? '17rem' : '12rem',
                        error: formError?.city,
                    },
                    {
                        label: 'Province',
                        name: 'province',
                        required: true,
                        type: 'text',
                        value: formValues?.province || '',
                        handleFieldChange: handleFieldChange,
                        width: isMobile ? '17rem' : '12rem',
                        error: formError?.province,
                    },
                    {
                        label: 'Postal Code',
                        name: 'postalCode',
                        required: true,
                        type: 'text',
                        value: formValues?.postalCode || '',
                        handleFieldChange: handleFieldChange,
                        width: isMobile ? '17rem' : '12rem',
                        error: formError?.postalCode,
                    }
                ]
            }
        ],
        padding: isMobile ? '1rem' : '2rem',
    }
}