import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import styled from 'styled-components';
import Toolbar from '@mui/material/Toolbar';
import './Layout.scss'
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import BackArrow from 'icons/BackArrow'
import { AppBarMenu } from './AppBarMenu.jsx'
import CustomMobileDrawer from './CustomMobileDrawer.jsx';
import { NotificationPanel } from './NotificationPanel.jsx'

const drawerWidth = 230;

const ActionContainer = styled.div`
 padding: 10px;
 display: flex;
 gap:0.5rem;
 flex-wrap: wrap;`

const Title = styled(Typography)`
font-family: "degular", sans-serif;
letter-spacing: 0px;
color: $black-color;
opacity: 1;
font-size: 20px !important;
font-weight: 700 !important;
display: flex;
align-items: center;
gap: 0.2rem;
`

export const MobileLayout = ({ action, headerTitle, showBackArrow, children, handleBackClick, handleBackArrowClick, window }) => {
    const { messages } = useSelector(
        (state) => state?.notifications
    )
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [notificationOpen, setNotificationOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const container = window !== undefined ? () => window().document.body : undefined;

    const handleNotificationClick = () => {
        setNotificationOpen(!notificationOpen);
    };

    return (
        <>
            <Box sx={{ display: 'flex' }} className="layout-container">
                <CssBaseline />
                <CustomMobileDrawer container={container} mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} />
                <Box
                    className='box-container'
                >
                    <AppBar
                        position="fixed"
                        sx={{
                            width: { sm: `calc(100% - ${drawerWidth}px)` },
                            ml: { sm: `${drawerWidth}px` },
                        }}
                    >
                        <Toolbar disableGutters mr={12} sx={{ display: 'flex', flexDirection: 'column', margin: '0px 30px', alignItems: 'flex-start' }}>
                            <div className='title-wrapper'>
                                <IconButton
                                    aria-label="open drawer"
                                    onClick={handleDrawerToggle}
                                    edge="start"
                                    sx={{ mr: 2, display: { sm: 'none' }, color: "white" }}
                                >
                                    <MenuIcon />
                                </IconButton>
                                {showBackArrow ?
                                    <IconButton aria-label="back" sx={{ color: "white" }}
                                        onClick={handleBackClick || handleBackArrowClick}>
                                        <BackArrow fill='white' />
                                    </IconButton> : null}
                                <Title>
                                    {headerTitle}
                                </Title>

                            </div>
                            <AppBarMenu messages={messages} handleNotificationClick={handleNotificationClick} />
                        </Toolbar>
                        <NotificationPanel open={notificationOpen} onClose={handleNotificationClick} />
                    </AppBar>
                    <Toolbar />
                    <NotificationPanel open={notificationOpen} onClose={handleNotificationClick} />
                    {action ?
                        <ActionContainer>
                            {action}
                        </ActionContainer> : null}
                    {children}
                </Box>
            </Box>
        </>
    );
}