import { environment } from "../environments/environment.jsx";
import { getHeader } from './authUtils.js'
import api from './api';

const apiEndpoint = environment.webservices.endpoint;

export class CustomersRateService {
    getRate(param) {
        const endpoint = `${apiEndpoint}/api/rate/all-msa`;
        return api.get(endpoint, {
            headers: getHeader(),
        });
    }
    getCustomersMSARate(param) {
        const endpoint = `${apiEndpoint}/api/rate/customer-rate/${param}`;
        return api.get(endpoint, {
            headers: getHeader(),
        });
    }
    
    addRate(formData) {
        const endpoint = `${apiEndpoint}/api/rate/create-msa`;
        return api.post(endpoint, formData, {
            headers: getHeader(),
        });

    }

    addTier(formData) {
        const endpoint = `${apiEndpoint}/api/rate/create`;
        return api.post(endpoint, formData, {
            headers: getHeader(),
        });
    }

    updateTier(formData) {
        const endpoint = `${apiEndpoint}/api/rate/update`;
        return api.put(endpoint, formData, {
            headers: getHeader(),
        });
    }

    updateRate(formData) {
        const endpoint = `${apiEndpoint}/api/rate/update-msa`;
        return api.put(endpoint, formData, {
            headers: getHeader(),
        });

    }
    
}