import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './updatePackage.scss';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import { useLocation } from "react-router-dom";
import { EventServive } from '../../services/eventService';
import { customStrings } from '../../properties/stringFile.jsx';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { Layout } from 'component/common/Layout/Layout';
import { CardDetail } from 'component/dashboard/cardDetail'
import { TextField } from 'component/common/Input/TextField'
import { PrimaryButton } from 'component/common/Button/Button'
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { ResponseModal } from 'component/common/Modal/ResponseModal'
import { ImageCarousel } from 'component/common/carousal/ImageCarousel';
import { fetchAssetImages, fetchPackageByTrackingNumber } from 'features/packages/packagesAction'
import { resetTrackingData, resetError } from 'features/packages/packagesSlice'
import { getDateWithTimeStamp, getAPIFilterAsPerRole, getUserDCOption, getDCObject } from 'utils/common'
import { getRoute } from 'features/route/routeAction'
import { getDeliveryStatusValue, getRouteStopSequence, getPackageAddresDetails, checkOnHoldAndAllowEdit } from 'utils/packageHelper'
import { LiveTrackingMap } from "component/liveTracking/liveTrackingMap1";
import debounce from 'lodash/debounce';
import { packageStatusEnum } from 'constants/packageStatus'

export default function UpdatePackage(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(location.search);
  const trackerId = queryParams.get('tracker-id');
  const [status, setStatus] = useState({
    tracking: trackerId
  });
  const [modalProps, setModalProps] = useState('')
  const [timeline, setTimeline] = useState(false);
  const [myEvents, setMyEvents] = useState([]);
  const [showImages, setShwoImages] = useState(false)
  const [isFetchingEvents, setIsFetchingEvents] = useState(false);

  const { assetImages, packageTrackingDetails, error, loading } = useSelector(
    (state) => state?.packages
  )

  const { isMobile } = useSelector(
    (state) => state?.viewport
  )

  const { dataCenters } = useSelector(
    (state) => state?.userManagement
  )

  const { user, userProfile } = useSelector(
    (state) => state?.auth
  )

  const { data: routeData } = useSelector(
    (state) => state.route
  )

  const routeDetail = routeData[packageTrackingDetails?.routeId]
  const filter = getAPIFilterAsPerRole(user, userProfile)
  const userDc = getUserDCOption(user)
  const dcCorodinates = getDCObject(userDc?.value, dataCenters)

  const eventService = new EventServive();
  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setStatus({
      ...status,
      [name]: value
    });
  }

  useEffect(() => {
    setTimeline(false)
    if (packageTrackingDetails) {
      handleShowDetail()
      if (packageTrackingDetails?.routeId) {
        dispatch(getRoute(packageTrackingDetails?.routeId))
      }
    }
    if (packageTrackingDetails?.imageAsset) {
      handleShowImages()
    }
  }, [packageTrackingDetails])

  useEffect(() => {
    // This function will run when the component is unmounting
    return () => {
      setStatus(null)
      dispatch(resetTrackingData())
      dispatch(resetError())
    };
  }, []); // The empty dependency array ensures this effect only runs on unmount

  useEffect(() => {
    if (error) {
      setModalProps(
        {
          title: 'Error Occured!',
          message: error,
          open: true,
          type: 'error'
        }
      )
    }
  }, [error])

  const fetchData = () => {
    setShwoImages(false)
    dispatch(resetTrackingData())
    dispatch(fetchPackageByTrackingNumber({
      trackingNumber: status?.tracking?.trim(),
      parcelTracking: true,
      ...filter,
      dcName: getUserDCOption(user)?.value
    }));
  }

  useEffect(() => {
    if (trackerId) {
      fetchData()
    }
  }, [trackerId])

  const handleClick = (e) => {
    if (e.target.name === 'tracking') {
      // Call search only if tracking id is present
      if (status?.tracking) {
        debouncedFetchData()
      }
    }
  };

  const handleShowDetail = useCallback(() => {
    if (!timeline && !isFetchingEvents) {
      setIsFetchingEvents(true);
      eventService.getPublicEventsByTrackingNumber(packageTrackingDetails?.trackingNumber, packageTrackingDetails?.postalCode)
        .then((response) => {
          const data = response?.data?.packageStatusVM?.sort((a, b) => new Date(a?.serverEventCreateDateTime) - new Date(b?.serverEventCreateDateTime));
          setMyEvents(data);
        })
        .finally(() => {
          setIsFetchingEvents(false);
        });
    }
    setTimeline(!timeline);
  }, [timeline, packageTrackingDetails]);

  const debouncedFetchData = useCallback(
    debounce(fetchData, 500),
    [status?.tracking, filter]
  );

  const handleShowImages = () => {
    if (!showImages) {
      if (!assetImages) {
        dispatch(fetchAssetImages(status?.tracking))
      }
      setShwoImages(true)
    }
    else {
      setShwoImages(false);
    }
  };

  const handleModelClose = () => {
    setModalProps(null)
    dispatch(resetError())
  }

  const stopSequenceNumber = getRouteStopSequence(routeDetail, packageTrackingDetails?.trackingNumber)

  const memoizedComponent = useMemo(() => {
    const center = { dcLat: routeDetail?.distributionCenterResponseVm?.dcLat || dcCorodinates?.dcLat, dcLon: routeDetail?.distributionCenterResponseVm?.dcLon || dcCorodinates?.dcLon }
    return <LiveTrackingMap height={isMobile ? '400px' : '850px'} routeStops={routeDetail?.routeStops || []} showStops={true} center={center} hideInfo={true} packageStopSequence={stopSequenceNumber} />
  }, [routeDetail])

  const packageAddressDetails = getPackageAddresDetails(packageTrackingDetails)?.[0]?.value
  const { isOnHold } = checkOnHoldAndAllowEdit(packageTrackingDetails, packageStatusEnum);

  return (
    <Layout headerTitle={'Parcel Tracking'} showBackArrow={false}>
      <CardDetail>
        <div className='tracking-content'>
          <div className='tracking-container'>
            <div className='search-field'>
              <TextField type="text" label='Tracking ID' placeholder="Tracking ID" name="tracking" defaultValue={packageTrackingDetails ? packageTrackingDetails.trackingNumber : status?.tracking} onChange={handleChange} width={isMobile ? '100%' : '12rem'} />
              <div className='button-wrapper'>
                <PrimaryButton name="tracking" type="button" variant='primary' label={'Search'} height={'30px'} onClick={handleClick} />
              </div>
            </div>
            {
              packageTrackingDetails === undefined ?
                <div className='info-text'> No data available for above Tracking ID</div>
                : null
            }
            {
              packageTrackingDetails &&
              <div className='card-container'>
                <div className='card-module-container'>
                  <Card className='cardModule'>
                    <CardHeader title='Manifest' className='fill-blue'>
                    </CardHeader>
                    <CardContent className='card-content'>
                      <div>{packageTrackingDetails?.addressOne}</div>
                    </CardContent>
                  </Card>
                  <div className='icon-container'><DoubleArrowIcon /></div>
                  <Card className='cardModule'>
                    <CardHeader title='Street Perfect' className='fill-blue'>
                    </CardHeader>
                    <CardContent className='card-content'>
                      {packageTrackingDetails?.ssAddress}
                    </CardContent>
                  </Card>
                </div>

                <div className='card-module-container'>
                  <Card className='cardModule'>
                    <CardHeader title='Parcel Details' className='fill-blue' data-testid='parcel-details'>
                    </CardHeader>
                    <CardContent className='card-content'>
                      <div data-testid='consignee-name'>Consignee Name : {packageTrackingDetails?.consignee || '--'}</div>
                      <div data-testid='consginee-address'>Address: {packageAddressDetails}</div>
                      <div data-testid='special-instructions'>Special Instructions : {packageTrackingDetails?.specialInstructions || '--'}</div>
                      <div data-testid='internal-comments'>Internal Comments : {packageTrackingDetails?.internalComment || '--'}</div>
                      <div data-testid='cumulative-attempt-count'>Cumulative Attempt Count : {packageTrackingDetails?.cumulativeAttemptCount ?? '--'}</div>
                      <div data-testid='client-code'>Client Code : {packageTrackingDetails?.clientCode || '--'}</div>
                      <div data-testid='company-code'>Company Code : {packageTrackingDetails?.companyCode || '--'}</div>
                      <div data-testid='attributes'>Attributes : {getDeliveryStatusValue(packageTrackingDetails?.highPriority, packageTrackingDetails?.sla, packageTrackingDetails?.highValueItem, isOnHold)}</div>
                    </CardContent>
                  </Card>
                </div>

                <div className='card-module-container'>
                  <Card className='cardModule'>
                    <CardHeader title='Stop Details' className='fill-blue'>
                    </CardHeader>
                    {routeDetail ? <CardContent className='card-content'>
                      <div>Route ID : {routeDetail?.dailyRouteScanSummaryVM?.routeId}</div>
                      <div>Route Name : {routeDetail?.dailyRouteScanSummaryVM?.routeName}</div>
                      <div>Stop Sequence Number : {stopSequenceNumber}</div>
                      <div>Parcel Sequence Number : {packageTrackingDetails?.packageStopSequence}</div>
                    </CardContent> : <div className='noDataContent'> No data available for above Tracking ID</div>}
                  </Card>
                </div>
              </div>
            }
            {timeline &&
              <Card className='details-container'>
                <CardHeader title='Event Description' className='fill-blue'>
                </CardHeader>
                {myEvents?.length ? <Timeline className="trackingModuleWithTransition">
                  {myEvents?.map((oneEvent, index) => {
                    const [statusKey, additionalInfo] = oneEvent?.packageStatus?.split('-');
                    return (<TimelineItem position="right" key={`${index}-${customStrings?.[oneEvent?.packageStatus]}`}>
                      <TimelineOppositeContent sx={{ width: '47%' }}>
                        {getDateWithTimeStamp(oneEvent?.serverEventCreateDateTime, user)}<br></br>
                        Modified By: {oneEvent?.modifiedBy}
                      </TimelineOppositeContent>
                      <TimelineSeparator sx={{ width: '6%' }}>
                        <TimelineDot />
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent sx={{ width: '47%' }}>{customStrings[statusKey]} {additionalInfo}</TimelineContent>
                    </TimelineItem>)
                  })}</Timeline> :
                  <div className='noDataContent'> No data available for above Tracking ID</div>
                }
              </Card>
            }
            {
              showImages ? <ImageCarousel images={assetImages} user={user} loading={loading} /> : null
            }
          </div>
          <div className='map-container'>
            {memoizedComponent}
          </div>
        </div>
      </CardDetail>
      {modalProps ? <ResponseModal {...modalProps} handleClose={handleModelClose} /> : ''}
    </Layout>
  )
};

