import React, { useState } from 'react'
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/system';
import { InternalCommentModal } from './InternalCommentPopup'
import { allowChipEditStatus, canUpdateSignatureOnlyAttribute, investigationStatus } from 'utils/packageHelper';

const CustomChip = styled(Chip)({
    fontSize: 14,
    fontFamily: 'Degular, sans-serif'
});

export const DeliveryStatusCellRenderer = ({ allowEditChip = false, onClick, isHighPriority, isSLA, isHighValueItem, parcel, user, handleOnHold, isOnHold, allowOnHoldEdit = false, handleSignatureAttribute, isSignatureRequired }) => {
    const [showInternalModal, setShowInternalModal] = useState(false)
    const isOrderBatchReady = allowChipEditStatus.includes(parcel?.packageStatus)
    const isParcelUnderInvestigation = investigationStatus.includes(parcel?.packageStatus)
    const isAllowToUpdateSignatureOnlyAttribute = canUpdateSignatureOnlyAttribute(parcel, user)

    const chips = []

    const togglePopUp = () => {
        setShowInternalModal(!showInternalModal)
    }

    if (allowEditChip && isOrderBatchReady) {
        chips.push({
            label: 'High Priority',
            icon: isHighPriority ? <CloseIcon data-testid={`${'HighPriority'}-CloseIcon`} />
                : <AddIcon data-testid={`${'HighPriority'}-AddIcon`} />,
            onDelete: () => onClick(parcel, 'highPriority', !isHighPriority),
        });
        chips.push({
            label: 'SLA',
            icon: isSLA ? <CloseIcon data-testid={`${'SLA'}-CloseIcon`} />
                : <AddIcon data-testid={`${'SLA'}-AddIcon`} />,
            onDelete: () => onClick(parcel, 'sla', !isSLA),
        });
        if (isHighValueItem) {
            chips.push({ label: 'High Value' });
        }
    }

    else if (allowEditChip && isParcelUnderInvestigation) {
        chips.push({
            label: 'SLA',
            icon: isSLA ? <CloseIcon data-testid={`${'SLA'}-CloseIcon`} />
                : <AddIcon data-testid={`${'SLA'}-AddIcon`} />,
            onDelete: () => onClick(parcel, 'sla', !isSLA),
        });
        if (isHighPriority) {
            chips.push({ label: 'High Priority' });
        }
        if (isHighValueItem) {
            chips.push({ label: 'High Value' });
        }
    }

    else {
        if (isSLA) {
            chips.push({ label: 'SLA' });
        }
        if (isHighValueItem) {
            chips.push({ label: 'High Value' });
        }
        if (isOnHold && !allowOnHoldEdit) {
            chips.push({ label: 'On Hold' });
        }
        if (isHighPriority) {
            chips.push({ label: 'High Priority' });
        }
    }

    if (allowOnHoldEdit) {
        chips.push({
            label: 'On Hold',
            icon: isOnHold ? <CloseIcon on data-testid={`${'ON-HOLD'}-CloseIcon`} /> : <AddIcon data-testid={`${'ON-HOLD'}-AddIcon`} />,
            onDelete: () => togglePopUp(),
        });
    }

    if (isSignatureRequired) {
        if (isAllowToUpdateSignatureOnlyAttribute) {
            chips.push({
                label: 'Signature Only',
                icon: <CloseIcon />,
                onDelete: () => handleSignatureAttribute(parcel),
            });
        }
        else {
            chips.push({ label: 'Signature Only' });
        }
    }

    return (
        <>
            {chips?.length ?
                <Stack direction="row" spacing={1}>
                    {chips?.map((chip, index) => {
                        return (
                            <CustomChip
                                key={`${index}-${chip.label}`}
                                label={chip.label}
                                color="warning"
                                variant="outlined"
                                size="small"
                                deleteIcon={chip.icon}
                                onDelete={chip.onDelete}
                            />)
                    })
                    }
                </Stack> : '--'}
            {showInternalModal ? <InternalCommentModal isModelOpen={showInternalModal} handleClose={togglePopUp} parcel={parcel} handleOnHold={handleOnHold} status={isOnHold ? 'REMOVE_FROM_HOLD' : 'PLACE_ON_HOLD'}/> : null}
        </>
    );
};