import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { validateForm } from 'utils/formValidator'
import { fetchEmptyLocations, fetchEligibleRollerCages, fetchUnAssignedRollerCages, fetchTotesData, relocateRollerCage, relocateTote } from 'features/warehouseOperation/warehouseOperationAction'
import { resetData } from 'features/warehouseOperation/warehouseOperationSlice'
import { CustomForm } from 'component/common/Form/CustomForm'
import { getOptionsForSelectField } from 'utils/common'
import { CustomModal } from 'component/common/Modal/CustomModal'
import { getRollerCageLists, getTotes } from 'utils/warehouseOperationHelper'
import useFieldChange from 'hooks/useFieldChange';
import 'component/dailyRoaster/AddDuty.scss'

const formInitialState = {
    assetType: 'tote',
    tote: '',
    rollerCage: '',
    location: ''
}

export const AssignAsset = ({ isModelOpen, handleClose }) => {
    const dispatch = useDispatch();
    const { loading, emptyLocations, totes, eligibleRollerCages, unAssignedRollerCages } = useSelector(
        (state) => state?.warehouseOperation
    )

    const [formValues, setFormValues, handleFieldChange] = useFieldChange(formInitialState);
    const [formError, setFormErrors] = useState({});

    const fetchData = () => {
        dispatch(fetchEmptyLocations())
        dispatch(fetchEligibleRollerCages({
            rollerCageType: 'ELIGIBLE_FOR_TOTE'
        }))
        dispatch(fetchUnAssignedRollerCages({
            rollerCageType: 'UNALLOCATED'
        }))
        dispatch(fetchTotesData({
            widget: 'INDUCTION_FLOOR'
        }))
    }

    useEffect(() => {
        fetchData()
    }, [])

    useEffect(() => {
        if (formValues?.assetType) {
            setFormValues({
                ...formInitialState, // Spread all initial values
                assetType: formValues.assetType // Retain the existing assetType
            });
        }
    }, [formValues?.assetType])

    const handleFormSubmit = () => {
        const notRequiredFields = []
        if (formValues?.assetType === 'roller-cage') {
            notRequiredFields.push('tote')
        }
        if (formValues?.assetType === 'tote') {
            notRequiredFields.push('location')
        }
        const errors = validateForm(formValues, notRequiredFields);
        if (Object.keys(errors)?.length) {
            setFormErrors(errors)
        }

        else {
            setFormErrors({})
            let data = null;
            if (formValues?.assetType === 'tote') {
                data = {
                    rollerCageName: formValues?.rollerCage?.value,
                    toteNumber: formValues?.tote?.value
                }
                dispatch(relocateTote(data))
            }
            if (formValues?.assetType === 'roller-cage') {
                data = {
                    rollerCageName: formValues?.rollerCage?.value,
                    destinationLocationId: formValues?.location?.value
                }
                dispatch(relocateRollerCage(data))
            }
        }
    }

    const handleCancel = () => {
        setFormValues(formInitialState)
        dispatch(resetData())
        handleClose()
    }

    const formProps = {
        fields: [
            {
                type: 'radio',
                name: 'assetType',
                label: 'Select Asset',
                options: [
                    { label: 'Tote', value: 'tote' },
                    { label: 'Roller Cage', value: 'roller-cage' },
                ],
                value: formValues?.assetType,
                handleChange: handleFieldChange,
                required: true,
                error: formError?.assetType
            },
            formValues?.assetType === 'tote' &&
            {
                label: 'Totes',
                name: 'tote',
                required: true,
                type: 'select',
                options: getTotes(totes),
                value: formValues?.tote || '',
                handleFieldChange: handleFieldChange,
                error: formError?.tote,
                width: '18rem',
                position: 'static'
            },
            {
                label: 'Roller Cage',
                name: 'rollerCage',
                required: true,
                type: 'select',
                options: getRollerCageLists(formValues?.assetType === 'tote' ? eligibleRollerCages : unAssignedRollerCages),
                value: formValues?.rollerCage || '',
                handleFieldChange: handleFieldChange,
                error: formError?.rollerCage,
                width: '18rem',
                position: 'static'
            },
            formValues?.assetType === 'roller-cage' && {
                label: 'Location',
                name: 'location',
                required: true,
                type: 'select',
                options: getOptionsForSelectField(emptyLocations),
                value: formValues?.location || '',
                handleFieldChange: handleFieldChange,
                error: formError?.location,
                width: '18rem',
                position: 'static'
            }
        ],
        buttons: [
            {
                label: 'submit',
                onClick: handleFormSubmit
            },
            {
                label: 'Cancel',
                onClick: handleClose,
                color: 'error'
            }
        ],
        loading: loading,
        fieldGap: '0px',
        margin: '0px',
        padding: '0rem 1rem'
    }

    return (
        <>
            <CustomModal
                open={isModelOpen}
                onClose={handleCancel}
            >
                <div className='add-duty-modal'>
                    <span className='title'>{'Assign Asset'}</span>
                    <CustomForm {...formProps} />                </div>
            </CustomModal>
        </>

    )

}