import React from 'react';
import { IconButton } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import './WarehouseOperations.scss'
import styled from 'styled-components';
import { useDrop } from "react-dnd";
import DraggableItem from "./DraggableItem"; // Item Component
import { ItemTypes, LocationTypes } from "./ItemTypes";

export const LocationLabel = styled.span`
  font-family: "degular", sans-serif;
  letter-spacing: 0px;
  opacity: 1;
  font-size: 10px;
  font-weight: 700;
  color: #5D48FF;
  cursor: pointer;
  padding: 4px 8px;
  border: 2px solid #1A1A1A; 
  border-radius: 4px; 
  display: inline-block;
margin: 5px;
`;

const LeftSidebar = ({ isOpen, toggleOpen, items, dragRef, onDropItem }) => {
    const [{ isOver }, drop] = useDrop({
        accept: [ItemTypes?.ROLLER_CAGE],
        drop: (item) => onDropItem({ item: item, locationType: LocationTypes?.LOADING_BAY }),
        collect: (monitor) => ({
            isOver: monitor.isOver(),
        }),
    });

    return (
        <div ref={drop} className={`sidebar ${isOver ? "highlight" : ""}`} style={{ width: isOpen ? '15%' : '3%' }}>
            <IconButton onClick={toggleOpen}>
                {isOpen ? <ChevronLeft /> : <ChevronRight />}
            </IconButton>
            {!isOpen && (
                <div className="accordian-label">
                    {"LOADING BAY".split("").map((letter, index) => (
                        <span key={index}>{letter}</span>
                    ))}
                </div>
            )}
            {isOpen && (
                <div className="sidebar-labels">
                    {items?.map((item, index) => (
                        <div className='loading-bay-roller-cage-container'>
                            <DraggableItem key={index} data={item?.rollercageVm} type={ItemTypes?.ROLLER_CAGE} dragRef={dragRef} />
                            (<span className='route-name'>{item?.rollercageVm?.routeName}</span>)
                             -
                            {item?.toteVm?.map((tote, index) => (
                                <DraggableItem key={index} data={tote} type={ItemTypes?.TOTE} dragRef={dragRef} showPackage={true} />
                            ))}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default LeftSidebar;
