import thunkMiddleware from 'redux-thunk'
import { configureStore } from '@reduxjs/toolkit';
import authSliceReducer from './features/auth/authSlice';
import dashbboardReducer from './features/dashboard/dashboardSlice'
import routeSliceReducer from 'features/route/routeSlice';
import userManagementReducer from 'features/userManagement/userManagementSlice';
import viewportReducer from 'features/viewportSlice';
import driverManagementReducer from 'features/driverManagement/driverManagementSlice';
import manifestManagementReducer from 'features/manifestManagement/manifestManagementSlice';
import packageReducer from 'features/packages/packagesSlice';
import dailyRoasterReducer from 'features/dailyRoaster/dailyRoasterSlice';
import vehicleReducer from 'features/vehicle/vehicleSlice';
import batchReducer from 'features/batch/batchSlice';
import auditReducer from 'features/audit/auditSlice';
import warehouseOperationReducer from 'features/warehouseOperation/warehouseOperationSlice';
import drawerReducer from 'features/drawer/drawerReducer';
import clientManagementReducer from 'features/clientManagement/clientManagementSlice';
import deliveryStatusReducer from 'features/deliveryStatusManagement/deliveryStatusManagementSlice';
import routeOperationSlice from 'features/routeOperation/routeOperationSlice';
import notificationSlice from 'features/notification/notificationSlice';
import fsaZoneSlice from 'features/fsaZone/fsaZoneSlice';
import reportingSlice from 'features/reporting/reportingSlice';
import rateSlice from 'features/rates/rateSlice';

const store = configureStore({
  reducer: {
    auth: authSliceReducer,
    dashboardData: dashbboardReducer,
    route: routeSliceReducer,
    userManagement: userManagementReducer,
    viewport: viewportReducer,
    driverManagement: driverManagementReducer,
    manifestManagement: manifestManagementReducer,
    packages: packageReducer,
    dailyRoaster: dailyRoasterReducer,
    vehicles: vehicleReducer,
    batches: batchReducer,
    audit: auditReducer,
    warehouseOperation: warehouseOperationReducer,
    drawer: drawerReducer,
    clientManagement: clientManagementReducer,
    deliveryStatusManagement: deliveryStatusReducer,
    routeOperation : routeOperationSlice,
    notifications: notificationSlice,
    fsaZones : fsaZoneSlice,
    reporting : reportingSlice,
    customersRate : rateSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(thunkMiddleware),
});

export default store;
