import React, { useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import styled from 'styled-components';
import Tote from "./Tote";
import { ItemTypes } from "./ItemTypes";

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: ${props => props?.justifyContent || 'center'};

  position: relative;
  padding: 8px;
  height: 8rem;
  border: 1px solid #ddd;
  background-color: lightgray;
  margin: 0px 5px;
  width: 6rem;
`;


const ToteContainer = styled.div`
   display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  overflow: auto;
}
`;

export const BoxLabel = styled.span`
font-family: "degular", sans-serif;
letter-spacing: 0px;
opacity: 1;
font-size: 10px;
font-weight: 700;
color: ${props => props?.color || '#1A1A1A'};
cursor: pointer;
`

export const LocationLabel = styled.span`
  font-family: "degular", sans-serif;
  letter-spacing: 0px;
  opacity: 1;
  font-size: 10px;
  font-weight: 700;
  color: #1A1A1A;
  cursor: pointer;
  padding: 4px 8px;
  border: 3px solid yellow; 
  border-radius: 4px; 
  display: inline-block;
    position: absolute;
  bottom: 8px;
`;


const RollerCageContainer = ({ rollerCageContainer, containerLabel, moveRollerCage, moveTote }) => {
    const [showSmallBoxes, setShowSmallBoxes] = useState(true); // Initial state is true (small boxes are visible)

    const rollerCageLabel = Object.keys(rollerCageContainer)?.length ? Object.keys(rollerCageContainer)?.[0] : null

    const totes = rollerCageLabel ? rollerCageContainer[rollerCageLabel] : []

    const sortedTotes = [...totes]?.sort((a, b) => a?.toteNumber - b?.toteNumber);

    const toggleSmallBoxes = () => {
        setShowSmallBoxes((prevState) => !prevState); // Toggle the state
    };

    // Enable dragging of the Roller Cage Label
    const [{ isDragging }, drag] = useDrag({
        type: ItemTypes.ROLLER_CAGE,
        item: { id: rollerCageLabel, sourceContainer: containerLabel, type: ItemTypes.ROLLER_CAGE },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    });

    const [{ isOver }, drop] = useDrop({
        accept: [ItemTypes.ROLLER_CAGE, ItemTypes.TOTE],  // Accept both types
        drop: (item) => {
            if (item?.toteNumber || item?.type === ItemTypes?.TOTE) {
                moveTote({ item: item, destinationRollerCage: rollerCageLabel });
            } else {
                moveRollerCage({ item: item, destinationContainer: containerLabel });
            }
        },
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    });

    return (
        <Box ref={drop} isOver={isOver} justifyContent={showSmallBoxes ? 'flex-start' : 'center'} data-test-id={`container-${containerLabel || "empty"}`}>
            {rollerCageLabel && (
                <BoxLabel ref={drag} style={{ opacity: isDragging ? 0.5 : 1 }} onClick={toggleSmallBoxes} color="#5D48FF">
                    {rollerCageLabel}
                </BoxLabel>
            )}
            {showSmallBoxes ?
                <ToteContainer>
                    {
                        sortedTotes?.map((tote, index) => {
                            return (<Tote key={`${tote}-${index}`} tote={tote} currentCage={rollerCageLabel} />)
                        })
                    }
                </ToteContainer> : null}

            {containerLabel ? <LocationLabel>
                {containerLabel}
            </LocationLabel> : null}
        </Box>
    );

}

export default RollerCageContainer;
