import React from 'react';
import { Button, FormControl, Checkbox, FormControlLabel, FormGroup, RadioGroup, Radio } from '@mui/material';
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { TextField } from 'component/common/Input/TextField'
import { TextArea } from 'component/common/Input/TextArea'
import { SwitchButton } from 'component/common/Input/SwitchButton'
import { SelectField, ErrorMessage, StyledLabel } from 'component/common/Input/SelectField'
import StarIcon from '@mui/icons-material/Star';
import { DateTimePicker } from 'component/common/Input/DateTimePicker'

function convertToProperCase(name) {
    return name.charAt(0) + name.slice(1).toLowerCase();
}

export const FieldRenderer = ({ field, lableColor, isMobile }) => {
    const fileInputRef = React.useRef(null);

    const handleFileUploadButtonClick = () => {
        fileInputRef.current.click();

        // Reset the input value to allow re-uploading the same file
        fileInputRef.current.value = '';

    };

    if (field?.type === 'text' || field?.type === 'email' || field?.type === 'password' || field?.type === 'date' || field?.type === 'number') {
        return (
            <TextField key={field?.name} {...field} lableColor={lableColor} />
        );
    } else if (field?.type === 'select') {
        return (
            <SelectField key={field?.name} {...field} lableColor={lableColor} />
        );
    }
    else if (field?.type === 'textarea') {
        return (
            <TextArea key={field?.name} {...field} lableColor={lableColor} />
        )
    }
    else if (field?.type === 'toggle') {
        return (
            <SwitchButton key={field?.name} {...field} />
        )
    }
    else if (field?.type === 'file') {
        return (
            <FormControl sx={{ width: field?.width || '15rem' }} key={field?.name}>
                <StyledLabel data-testid={field.dataTestId ? `label-${field?.dataTestId}` : `label-${field?.name}`} >{field?.label}
                    {field?.required && <StarIcon style={{ color: 'red', fontSize: '8px', verticalAlign: 'middle', marginLeft: '5px' }} />}
                </StyledLabel>
                <input type="file" data-testid={`file-${field?.dataTestId}`} ref={fileInputRef}
                    accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    hidden onChange={field?.handleFileUpload} className='textField' />
                <Button
                    data-testid={`button-${field?.name}`}
                    component="label"
                    variant="outlined"
                    startIcon={<UploadFileIcon />}
                    onClick={handleFileUploadButtonClick}
                >
                    Upload File

                </Button>
                {field?.fileName}
                <ErrorMessage id={field?.name}>{field?.error}</ErrorMessage>
            </FormControl>
        );
    }
    else if (field?.type === 'checkbox') {
        return (
            <div style={{ width: field?.width }}>
                {Object.keys(field?.options)?.map((key) => (
                    <FormControl key={key} sx={{ display: 'flex', flexDirection: 'column' }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={field?.value[key] ?? false}
                                    data-testid={key}
                                    onChange={field?.handleChange}
                                    name={key}
                                    disabled={field?.disabled} 
                                />
                            }
                            label={field?.options?.[key]}
                            className="checkbox-label"
                        />
                    </FormControl >
                ))}
            </div>
        );
    }
    else if (field?.type === 'multi-checkbox') {
        return (
            <FormControl key={field?.name}>
                <StyledLabel>{field?.label}
                    {field?.required && <StarIcon style={{ color: 'red', fontSize: '8px', verticalAlign: 'middle', marginLeft: '5px' }} />}
                </StyledLabel>
                <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
                    {field?.showSelectAll && (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={field?.value?.length === field?.options?.length}
                                    onChange={() => {
                                        if (field?.value?.length === field?.options?.length) {
                                            // If all checkboxes are already selected, unselect all
                                            field?.handleFieldChange([]);
                                        } else {
                                            // Otherwise, select all checkboxes
                                            field?.handleFieldChange([...field?.options]);
                                        }
                                    }}
                                    name="selectAll"
                                />
                            }
                            label={<StyledLabel>Select All</StyledLabel>}
                        />
                    )}
                    {field?.options?.map((option) => {
                        return (<FormControlLabel key={option}
                            control={
                                <Checkbox
                                    checked={field?.value?.includes(option)}
                                    onChange={() => field?.handleFieldChange(option)}
                                    name={option}
                                />
                            }
                            label={<StyledLabel>{convertToProperCase(option)}</StyledLabel>}
                        />)
                    })}
                </FormGroup>
                {field?.error && <ErrorMessage width={field?.width}>{field?.error}</ErrorMessage>}
            </FormControl>
        );
    }
    else if (field?.type === 'button') {
        return (
            <Button onClick={field?.onClick} variant={field?.variant}>{field?.label}</Button>
        )
    }
    else if (field?.type === 'datetime') {
        return (
            <DateTimePicker
                key={field?.name}
                label={field?.label}
                lableColor={lableColor}
                error={field?.error}
                handleFieldChange={field?.handleChange}
                info={field?.info}
                placeholder={field?.placeholder}
                required={field?.required}
                width={field?.width}
                timeZone={field?.timeZone}
                showOnlyTime={field?.showOnlyTime}
                {...field}
            />
        );
    }
    else if (field?.type === 'radio') {
        return (
            <FormControl component="fieldset" key={field?.name}>
                <StyledLabel>{field?.label}
                    {field?.required && <StarIcon style={{ color: 'red', fontSize: '8px', verticalAlign: 'middle', marginLeft: '5px' }} />}
                </StyledLabel>
                <RadioGroup
                    name={field?.name}
                    value={field?.value}
                    onChange={field?.handleChange}
                    required={field?.required}
                    row>
                    {field?.options.map((option) => (
                        <FormControlLabel
                            key={option.value}
                            value={option.value}
                            control={<Radio />}
                            label={option.label}
                            data-testid={`radio-${option.label}`}
                        />
                    ))}
                </RadioGroup>
                {field?.error && <ErrorMessage>{field?.error}</ErrorMessage>}
            </FormControl>
        );
    }
}