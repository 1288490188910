import { useEffect } from "react";

const useDragAutoScroll = () => {
  useEffect(() => {
    let scrollInterval = null;

    const handleDragOver = (event) => {
      event.preventDefault();
      const scrollSpeed = 10; // Adjust for smooth scrolling
      const threshold = 50; // Pixels from top/bottom to trigger scroll

      const { clientY } = event;
      const windowHeight = window.innerHeight;

      if (clientY < threshold) {
        startScrolling(-scrollSpeed); // Scroll up
      } else if (windowHeight - clientY < threshold) {
        startScrolling(scrollSpeed); // Scroll down
      } else {
        stopScrolling();
      }
    };

    const startScrolling = (speed) => {
      if (!scrollInterval) {
        scrollInterval = requestAnimationFrame(() => smoothScroll(speed));
      }
    };

    const smoothScroll = (speed) => {
      window.scrollBy(0, speed);
      scrollInterval = requestAnimationFrame(() => smoothScroll(speed));
    };

    const stopScrolling = () => {
      if (scrollInterval) {
        cancelAnimationFrame(scrollInterval);
        scrollInterval = null;
      }
    };

    document.addEventListener("dragover", handleDragOver);
    document.addEventListener("drop", stopScrolling);
    document.addEventListener("dragend", stopScrolling);

    return () => {
      document.removeEventListener("dragover", handleDragOver);
      document.removeEventListener("drop", stopScrolling);
      document.removeEventListener("dragend", stopScrolling);
    };
  }, []);
};

export default useDragAutoScroll;
