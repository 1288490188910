import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from "react-router-dom";
import { Layout } from 'component/common/Layout/Layout';
import { fetchCustomersMSARate } from 'features/rates/rateAction'
import DataTable from 'component/dataTable/dataTable'
import { Loader } from 'component/common/Loader/Loader'
import { PrimaryButton } from 'component/common/Button/Button.jsx'
import { ResponseModal } from 'component/common/Modal/ResponseModal'
import '../userManagement/userAccount/UserAccount.scss'
import { Tooltip } from '@mui/material';
import { resetData } from 'features/rates/rateSlice'
import { Title } from 'component/common/commonStyles';
import { AddTier } from './AddTier';

 export const tierType = {
    VOLUME : 'VOLUME',
    DIMENTION: 'DIMENTION',
    WEIGHT: 'WEIGHT'
 }

export const CustomerMSADetail = () => {
    const dispatch = useDispatch();
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search);
    const customerName = queryParams.get('customerName');
    const customerId = queryParams.get('customerId');
    const clientMSAId = queryParams.get('clientMSAId');

    const { customersMSARate, error, loading, success } = useSelector(
        (state) => state?.customersRate
    )
    // const [sorteData, setSortedData] = useState(customersRate);
    const [modalProps, setModalProps] = useState(null)
    const [addModalOpen, setAddModalOpen] = useState(false)
    const [selectedRate, setSelectedRate] = useState(null)

    useEffect(() => {
        dispatch(fetchCustomersMSARate(customerId))
    }, [])

    useEffect(() => {
        if (error) {
            setModalProps(
                {
                    title: 'Error Occured!',
                    message: error,
                    open: true,
                    type: error
                }
            )

        }
    }, [error]);

    useEffect(() => {
        if (success) {
            setModalProps(
                {
                    title: 'Success',
                    message: 'Update Done Successfully!',
                    open: true,
                    type: 'success'
                }
            )
            const redirectTimer = setTimeout(() => {
                setModalProps(null)
                handleAddModalClick()
                dispatch(fetchCustomersMSARate(customerId))
                dispatch(resetData())
            }, 2000);
            return () => {
                clearTimeout(redirectTimer);
            }
        }
    }, [success]);

    const commonColumns = [
        {
            accessor: "lowerBound",
            title: "Lower Bound",
            width: "3rem",
            Cell: ({ cell: { row: { original } } }) => original?.lowerBound,
            render: (item) => item?.lowerBound
        },
        {
            accessor: "tierName",
            title: "Tier Name",
            width: "3rem",
            Cell: ({ cell: { row: { original } } }) => original?.tierName,
            render: (item) => item?.tierName
        },
        {
            accessor: "upperBound",
            title: "Upper Bound",
            width: "3rem",
            Cell: ({ cell: { row: { original } } }) => original?.upperBound,
            render: (item) => item?.upperBound
        }
    ]

    const dimentionRatesColumns = [
        ...commonColumns,
        {
            accessor: "dimSurcharge",
            title: "Dimension SurCharge(%)",
            width: "3rem",
            Cell: ({ cell: { row: { original } } }) => original?.dimSurcharge,
            render: (item) => item?.dimSurCharge
        },
        {
            accessor: "dimensionUnit",
            title: "Dimension Unit",
            width: "3rem",
            Cell: ({ cell: { row: { original } } }) => original?.dimensionUnit || '--' ,
            render: (item) => item?.dimensionUnit
        },
        {
            accessor: 'action',
            isSortable: false,
            title: "Action",
            width: "4rem",
            Cell: ({ cell: { row: { original } } }) => <div className='action' >
                <Tooltip title="Edit">
                    <img src="static/images/edit-icon.svg" alt='edit' onClick={() => handleAddModalClick({ data: original, tierType: tierType?.DIMENTION })} /></Tooltip>
            </div>
        }
    ]

    const weightRatesColumns = [
        ...commonColumns,
        {
            accessor: "weightSurcharge",
            title: "Weight Surcharge(%)",
            width: "3rem",
            Cell: ({ cell: { row: { original } } }) => original?.weightSurcharge,
            render: (item) => item?.weightSurcharge
        },
        {
            accessor: "weightUnit",
            title: "Weight Unit",
            width: "3rem",
            Cell: ({ cell: { row: { original } } }) => original?.weightUnit,
            render: (item) => item?.weightUnit
        },
        {
            accessor: 'action',
            isSortable: false,
            title: "Action",
            width: "4rem",
            Cell: ({ cell: { row: { original } } }) => <div className='action' >
                <Tooltip title="Edit">
                    <img src="static/images/edit-icon.svg" alt='edit' onClick={() => handleAddModalClick({ data: original, tierType: tierType?.WEIGHT })} /></Tooltip>
            </div>
        }
    ]

    const volumeColumns = [
        ...commonColumns,
        {
            accessor: "volDiscount",
            title: "Volume Discount(%)",
            width: "3rem",
            Cell: ({ cell: { row: { original } } }) => original?.volDiscount,
            render: (item) => item?.volDiscount
        },
        {
            accessor: "active",
            title: "Active",
            width: "3rem",
            Cell: ({ cell: { row: { original } } }) => original?.active ? "Yes" : "No",
            render: (item) => item?.active
        },
        {
            accessor: 'action',
            isSortable: false,
            title: "Action",
            width: "4rem",
            Cell: ({ cell: { row: { original } } }) => <div className='action' >
                <Tooltip title="Edit">
                    <img src="static/images/edit-icon.svg" alt='edit' onClick={() => handleAddModalClick({ data: original, tierType: tierType?.VOLUME })} /></Tooltip>
            </div>
        }
    ]

    const handleAddModalClick = ({ tierType, data } = {tierType: '', data : {}}) => {
        if(!addModalOpen){
            const item = { ...data }
            item['tierType'] = tierType    
            setSelectedRate(item)
        }
        else{
            setSelectedRate(null)
        }
        setAddModalOpen(!addModalOpen)
    }

    return (
        <Layout headerTitle={
            <div className='title-container'>
                {customerName} Master Agreement
            </div>}
            showBackArrow={true}        >
            <div className='container'>
                <div className='title-container'>
                    <Title>Dimension Tier Price</Title>
                    <div><PrimaryButton type="button" variant='primary' label={'Add Tier'} height={'30px'} onClick={() => handleAddModalClick({ tierType: tierType?.DIMENTION })} /></div>
                </div>
                <div className='content'>
                    <DataTable columns={dimentionRatesColumns} data={customersMSARate?.dimensionRates || []} isCollpsable={false} showPagination={false} />
                </div>
                <div className='content'>
                    <div className='title-container'>
                        <Title>Volume Tier Price</Title>
                        <div><PrimaryButton type="button" variant='primary' label={'Add Tier'} height={'30px'} onClick={() => handleAddModalClick({ tierType: tierType?.VOLUME })} /></div>
                    </div>
                </div>
                <DataTable columns={volumeColumns} data={customersMSARate?.volumeRates
                    || []} isCollpsable={false} showPagination={false} />

                <div className='title-container'>
                    <Title>Weight Tier Price</Title>
                    <div><PrimaryButton type="button" variant='primary' label={'Add Tier'} height={'30px'} onClick={() => handleAddModalClick({ tierType: tierType?.WEIGHT })} /></div>
                </div>
                <div className='content'>
                    <DataTable columns={weightRatesColumns} data={customersMSARate?.weightRates || []} isCollpsable={false} showPagination={false} />
                </div>    
            </div>
            {addModalOpen ?
                <AddTier customersMSARate={customersMSARate} isModelOpen={addModalOpen} handleClose={handleAddModalClick} selectedRate={selectedRate} clientMSAId={clientMSAId} /> : null}
            {modalProps ? <ResponseModal {...modalProps} handleClose={() => setModalProps(null)} /> : null}
            {loading ? <Loader isLoading={loading} /> : null}


        </Layout>
    )
}
