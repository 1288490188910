import { roleConstantEnum } from "constants/roleConstants";
import moment from "moment-timezone";
import dayjs from "dayjs";

export const getDataCentersList = (data, userRole, userDC) => {
    // If the userRole is ROLE_ADMIN, return the entire data list
    if (!userRole || userRole === roleConstantEnum?.ROLE_ADMIN) {
        const dataCenters = data?.map((item) => {
            return { label: item?.dcName, value: item?.dcName };
        });
        return dataCenters || [];
    }

    // For non-admin users, return only the userDC option
    return userDC ? [userDC] : [];
}

export const getTenantsList = (data) => {
    const tenants = data && data.map((item) => {
        return { label: item?.tenantName, value: item?.tenantName };
    })
    return tenants || []
}

export const getClientList = (data) => {
    const customers = data && data?.map((item) => {
        return { label: item?.businessName, value: item?.businessName, ...item };
    })
    return customers || []
}

export const convertToManifestCutoffTime = (isoString) => {
    const date = new Date(isoString);

    // Extract hours, minutes, and seconds in UTC
    const hour = date.getUTCHours().toString().padStart(2, '0');
    const minute = date.getUTCMinutes().toString().padStart(2, '0');
    const second = date.getUTCSeconds().toString().padStart(2, '0');

    return `${hour}:${minute}:${second}`;

}


export const convertToLocalDateTime = (timeString, userTimeZone) => {
    if (timeString) {
        const currentDate = moment().format("YYYY-MM-DD"); // Get today's date
        const utcDateTime = moment.utc(`${currentDate}T${timeString}`); // Create UTC moment object
        const localDateTime = utcDateTime.tz(userTimeZone); // Convert to user's time zone

        return dayjs(localDateTime.toDate()); // Convert to dayjs object for MUI
    }
    return null

};