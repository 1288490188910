import { urls } from 'utils/urls'
import { getUserRole } from 'utils/common';

export const parseNotificationBody = (originalJson, user) => {
  try {
    const { data, messageId, topic } = originalJson
    const { title, body } = data;
    const notificationId = data?.messageId || messageId

    // Attempt to parse the body field, as it might be JSON inside a string
    let parsedBody;
    try {
      parsedBody = JSON.parse(body);
    } catch {
      // If parsing fails, assume it's plain text
      parsedBody = { message: body };
    }
    const { message, id, isSOS } = parsedBody;
    // Replace the id in the message with a link
    const messageWithLink = id ? `${message} <a href='#' id="${id}">${id}</a>` : message
    const readStatus = originalJson?.readBy?.includes(user?.login) ? true : false;

    // Return the modified JSON object
    return {
      title,
      body: {
        message: messageWithLink,
        id,
        url: parsedBody.url,
        isSOS,
        type: parsedBody?.type,
        acceptedBy:originalJson?.acceptedBy,
        deniedBy: originalJson?.deniedBy
      },
      readStatus,
      notificationId,
      topic
    };

  } catch (error) {
    console.error('Error parsing notification body:', error);
    return null;
  }
};


export const getRedirectURL = (data) => {
  let body = ''
  try {
    body = JSON.parse(data)
  }
  catch {
    body = data
  }
  const url = urls[body?.url]
  if (url) {
    const redirectUrl = `${url}?${body?.type}=${body?.id}`
    return redirectUrl;
  } else {
    return null;
  }
}

export const getUserTopics = (user) => {
  const role = getUserRole(user)?.toLowerCase().replace("role_", "");

  const roleTopics = {
    driver: ["package", "route", "general"],
    supervisor: ["package", "route", "manifest"],
    csr: ["package", "manifest"],
    admin: ["package", "route", "manifest"],
  };
  const entities = roleTopics[role] || [];
  const baseTopic = `dc-${user?.distributionCenterResponseVm?.dcName}-tenant-${user?.tenantName}-entity-`;
  let topics = entities.map(entity => `${baseTopic}${entity}`);

  if (role === "driver" || role === "csr") {
    topics.push(`dc-${user?.distributionCenterResponseVm?.dcName}-tenant-${user?.tenantName}-general`);
  }

  return topics;

}
