import { Tabs, Box, Tab as MUITab } from "@mui/material";
import styled from "styled-components";
import { useSelector } from "react-redux";

export const TabWrapper = styled.div`
  display: flex;
  background-color: white;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
`;

const Wrapper = styled(Box)({
  backgroundColor: "white",
  borderBottom: 1,
  fontSize: "14px",
  fontFamily: "Degular, sans-serif",
  display: "flex",
  flexGrow: 0.95,
  "@media (min-width: 320px) and (max-width: 480px)": {
    border: "1px solid #E4E4E4",
  },
});

const CustomTab = styled(MUITab)`
  &.MuiButtonBase-root {
    font-size: 14px;
    font-family: "Degular", sans-serif;
    color: #000000;
    font-weight: 600;
    @media (min-width: 320px) and (max-width: 480px) {
      font-size: 15px;
    }
  }
`;

const LableWrapper = styled.div`
display: flex;
align-items: center;
gap: 0.2rem;
flex-direction:column;
`;

const PreserveTag = styled.div`
  fontfamily: "Degular, sans-serif";
`;

const CountBadge = styled.span`
  font-weight: 700;
`;

export const TabLabel = ({ label, count = null, time = null }) => {
  return (
    <LableWrapper>
      <PreserveTag>
        <div>
          {label}{" "}
          {count ?
            <>
              <span> : </span>
              <CountBadge>{count}</CountBadge>
            </> : null}
        </div>
        <div>
          {time ? (
            <>
              <span>Drive Time - </span>
              <span>{time}</span>
            </>
          ) : null}
        </div>
      </PreserveTag>
    </LableWrapper>
  );
};

export const Tab = ({ handleChange, tabs, value }) => {
  const { isMobile } = useSelector((state) => state?.viewport);

  return (
    <Wrapper>
      <Tabs
        value={value}
        onChange={handleChange}
        TabIndicatorProps={isMobile ? { sx: { display: "none" } } : {}}
        sx={{
          "& .MuiTabs-flexContainer": {
            flexWrap: "wrap",
          },
        }}
      >
        {tabs?.map((tab) => {
          return (
            <CustomTab
              key={tab?.label}
              data-testid={tab?.value}
              disableRipple={true}
              sx={{
                ...(isMobile
                  ? {
                    "&.Mui-selected": {
                      color: "#1976d2",
                      fontWeight: "bold",
                      textDecoration: " underline",
                      textUnderlineOffset: "10px",
                      textDecorationThickness: "2px"
                    },
                  }
                  : {}),
              }}
              label={<TabLabel label={tab?.label} count={tab?.count} />}
              value={tab?.value}
            ></CustomTab>
          );
        })}
      </Tabs>
    </Wrapper>
  );
};
