import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import Popover from '@mui/material/Popover';
import Badge from '@mui/material/Badge';
import Typography from '@mui/material/Typography';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import { TextArea } from 'component/common/Input/TextArea'
import { updatePackage } from 'features/packages/packagesAction'

export const SpecialInstrucionsCellRenderer = ({ data, allowEdit, type }) => {
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const [comment, setComment] = useState(null);

    useEffect(() => {
        setComment(type === 'internalComments' ? data?.internalComment ?? null : data?.specialInstructions ?? null);
    }, [type, data]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        if (allowEdit && comment !== data?.internalComment) {
            const params = {
                trackingNumber: data?.trackingNumber,
            }
            if (type === 'internalComments' && comment !== data?.internalComment) {
                params['internalComment'] = comment
                dispatch(updatePackage(params))
            }
            if (type === 'specialInstructions' && comment !== data?.specialInstructions) {
                params['specialInstructions'] = comment
                dispatch(updatePackage(params))
            }
        }
    };


    const handleChange = (e) => {
        setComment(e?.target?.value);
    };


    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div>
            <Badge
                color="primary"
                variant={comment ? 'dot' : 'standard'} // Show a dot only if a comment exists
            >
                <ReadMoreIcon sx={{ cursor: 'pointer' }} onClick={handleClick} />
            </Badge>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                {allowEdit ? <TextArea key={'InternalComments'} value={comment} maxRows={3}
                    minRows={3} placeholder={'Add Comments'} autoFocus handleFieldChange={handleChange} />
                    : <Typography sx={{ p: 2 }}>{comment}</Typography>
                }
            </Popover>
        </div>
    );
};