import React, { useState } from "react"
import { useDispatch } from 'react-redux';
import { Paper, List, ListItem, Popover, Divider, Link } from '@mui/material';
import { getDateWithTimeStamp } from 'utils/common'
import DataTable from 'component/dataTable/dataTable'
import { PackageDetails } from './PackageDetails';
import PanoramaIcon from '@mui/icons-material/Panorama';
import { PopupWithImage } from './PopUpWithImage'
import { routeStopStatusEnum } from 'constants/routeStatus'
import { Dot } from 'component/common/Dot/dot'
import { getStopColor } from 'utils/liveTrackingHelper'
import { resetStopDetails } from 'features/route/routeSlice'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button } from "@mui/material";
import './liveTracking.scss';
import 'component/dataTable/dataTable.scss'


export const RouteStopTable = ({ routeStops, user }) => {
    const dispatch = useDispatch()
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedStop, setSelectedStop] = useState(null);
    const [popOverData, setPopOverData] = useState(null)
    const [anchorEl, setAnchorEl] = useState(null);

    const handleImageClick = (item) => {
        dispatch(resetStopDetails())
        setOpenDialog(true);
        setSelectedStop(item)
    }

    const handlePopupClose = () => {
        setOpenDialog(false)
        setSelectedStop(null)
    }


    const sortedStops = routeStops.slice().sort((a, b) => {
        return a.routeStopSequence - b.routeStopSequence;
    });

    const handleColumnClick = (event, data) => {
        setPopOverData(data)
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const columns = [{
        accessor: "routeStopSequence",
        title: "SQ No",
        Cell: ({ cell: { row: { original } } }) => <><Dot color={getStopColor(original?.routeStopStatus)} /> {original?.routeStopSequence || '--'}</>,
        render: (item) => item?.routeStopSequence
    },
    {
        accessor: "routeStopAddress",
        title: "Address",
        width: "4rem",
        Cell: ({ cell: { value } }) => value,
        render: (item) => item?.routeStopAddress
    },
    {
        accessor: "estimatedArrival",
        title: "ET",
        width: "4rem",
        Cell: ({ cell: { row: { original } } }) => getDateWithTimeStamp(original?.estimatedArrival, user, true),
        render: (item) => getDateWithTimeStamp(item?.estimatedArrival, user, true)
    },
    {
        accessor: "actualArrivalTime",
        title: "AT",
        width: "4rem",
        Cell: ({ cell: { row: { original } } }) => getDateWithTimeStamp(original?.actualArrivalTime, user, true),
        render: (item) => getDateWithTimeStamp(item?.actualArrivalTime, user, true)
    },
    {
        accessor: "stopPhoto",
        title: "Stop Photo",
        width: "4rem",
        Cell: ({ cell: { row: { original } } }) => original?.routeStopStatus === routeStopStatusEnum?.ROUTE_STOP_SERVED && original?.packageDetails ? <PanoramaIcon sx={{ cursor: 'pointer', height: 15, width: 15, marginRight: '10px' }} onClick={() => handleImageClick(original)} /> : '--'
        ,
        render: (item) => '--'
    },
    {
        accessor: "actualServiceTime",
        title: "Service Time",
        width: "4rem",
        // Cell: ({ cell: { row: { original } } }) => original?.historicStopDetailsVMs?. ? <Link id='serviceTime' style={{ cursor: 'pointer' }} sx={{ textDecoration: 'none' }} onClick={(event) => handleColumnClick(event, original)}>{original?.stopServiceTime}</Link> : '--',
        Cell: ({ cell: { row: { original } } }) => <Link id='serviceTime' style={{ cursor: 'pointer' }} sx={{ textDecoration: 'none' }} onClick={(event) => handleColumnClick(event, original?.historicStopDetailsVMs)}>{original?.actualServiceTime}</Link>,
        render: (item) => item?.actualServiceTime || '--'
    }
    ]

    return (
        <>
            <DataTable childComponent={PackageDetails} isCollpsable={true} columns={columns} allowSorting={false} maxHeight={'inherit'} data={sortedStops} rowKey={'routeStopId'} showPagination={false} />
            {openDialog ? <PopupWithImage routeStop={selectedStop} open={openDialog} onClose={handlePopupClose} /> : null}
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Paper sx={{
                    maxWidth: '100%'
                }}>
                    <TableContainer component={Paper} className="dataTable">
                        <Table size="small" style={{ maxWidth: 500 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell className="table-head-cell">Number of Parcels</TableCell>
                                    <TableCell className="table-head-cell">Average Service Time</TableCell>
                                    <TableCell className="table-head-cell">Total Service Time</TableCell>
                                    <TableCell className="table-head-cell" >Create Date</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {popOverData?.map((item, index) => (
                                    <TableRow key={index}>
                                        <TableCell className="table-row-cell" align="center">{item?.numberOfParcels}</TableCell>
                                        <TableCell className="table-row-cell" align="center">{item?.averageServiceTime}</TableCell>
                                        <TableCell className="table-row-cell" align="center">{item?.totalServiceTime}</TableCell>
                                        <TableCell className="table-row-cell" align="center">{getDateWithTimeStamp(item?.createDate, user)}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Popover>
        </>
    )
}
