import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { validateForm } from 'utils/formValidator'
import { addRate, updateRate } from 'features/rates/rateAction'
import { CustomForm } from 'component/common/Form/CustomForm'
import { CustomModal } from 'component/common/Modal/CustomModal'
import useFieldChange from 'hooks/useFieldChange';
import 'component/dailyRoaster/AddDuty.scss'
import { getClientList } from 'utils/userAccountHelper'

const formInitialState = {
    businessName: '',
    baseRate: '',
    baseRateUnit: 'CAD',
    dimFactor: '',
    clientSignatoryEmail: '',
    clientSignatoryDesignation: '',
    clientSignatoryName: '',
    msaSignedDate: '',
    msaExpiryDate: '',
    activeTierName: '',

}

export const AddMSA = ({ isModelOpen, handleClose, selectedRate }) => {
    const dispatch = useDispatch();
    const [formValues, setFormValues, handleFieldChange] = useFieldChange(formInitialState);
    const [formError, setFormErrors] = useState({});

    const { clients, loading } = useSelector(
        (state) => state?.clientManagement
    )

    useEffect(() => {
        if (selectedRate) {
            const client = clients?.find(client => client?.businessName === selectedRate?.businessName);
            setFormValues({
                businessName: client ? { label: client?.businessName, value: client?.businessName, ...client } : null,
                baseRate: selectedRate?.baseRate,
                baseRateUnit: selectedRate?.baseRateUnit,
                dimFactor: selectedRate?.dimFactor,
                clientSignatoryEmail: selectedRate?.clientSignatoryEmail,
                clientSignatoryDesignation: selectedRate?.clientSignatoryDesignation,
                clientSignatoryName: selectedRate?.clientSignatoryName,
                msaSignedDate: selectedRate?.msaSignedDate,
                msaExpiryDate: selectedRate?.msaExpiryDate,
                activeTierName: selectedRate?.activeTier,
            })
        }
    }, [selectedRate])

    const handleFormSubmit = () => {
        const errors = validateForm(formValues);
        if (Object.keys(errors).length) {
            setFormErrors(errors)
        }
        else {
            setFormErrors({})
            const data = { ...formValues }
            data['businessName'] = formValues?.businessName?.value
            data['clientId'] = formValues?.businessName?.id
            selectedRate ? dispatch(updateRate(data)) : dispatch(addRate(data))
        }
    }

    const handleCancel = () => {
        setFormValues(formInitialState)
        handleClose()
    }

    const formProps = {
        fields: [
            {
                label: 'Business Name',
                value: formValues?.businessName,
                handleFieldChange: handleFieldChange,
                type: 'select',
                width: '19rem',
                options: getClientList(clients),
                name: 'businessName',
                disabled : selectedRate ? true : false,
                required: true,
            },
            {
                label: 'Base Rate',
                name: 'baseRate',
                required: true,
                type: 'text',
                value: formValues?.baseRate || '',
                handleFieldChange: handleFieldChange,
                width: '19rem',
                error: formError?.baseRate,
            },
            {
                label: 'Base Rate Unit',
                name: 'baseRateUnit',
                required: true,
                type: 'text',
                value: formValues?.baseRateUnit || '',
                handleFieldChange: handleFieldChange,
                width: '19rem',
                error: formError?.baseRateUnit,
                disabled: true
            },
            {
                label: 'Dim Factor',
                name: 'dimFactor',
                required: true,
                type: 'text',
                value: formValues?.dimFactor || '',
                handleFieldChange: handleFieldChange,
                width: '19rem',
                error: formError?.dimFactor,
            },
            selectedRate && {
                label: 'Active Tier(Volume)',
                name: 'activeTierName',
                required: true,
                type: 'text',
                value: formValues?.activeTierName || '',
                handleFieldChange: handleFieldChange,
                width: '19rem',
                error: formError?.activeTierName,
            },
            {
                label: 'Client Signatory Email',
                name: 'clientSignatoryEmail',
                required: true,
                type: 'text',
                value: formValues?.clientSignatoryEmail || '',
                handleFieldChange: handleFieldChange,
                width: '19rem',
                error: formError?.clientSignatoryEmail,
            },
            {
                label: 'Client Signatory Designation',
                name: 'clientSignatoryDesignation',
                required: true,
                type: 'text',
                value: formValues?.clientSignatoryDesignation || '',
                handleFieldChange: handleFieldChange,
                width: '19rem',
                error: formError?.clientSignatoryDesignation,
            },
            {
                label: 'Client Signatory Name',
                name: 'clientSignatoryName',
                required: true,
                type: 'text',
                value: formValues?.clientSignatoryName || '',
                handleFieldChange: handleFieldChange,
                width: '19rem',
                error: formError?.clientSignatoryName,
            },
            {
                label: 'MSA Signed Date',
                name: 'msaSignedDate',
                required: true,
                type: 'date',
                value: formValues?.msaSignedDate || '',
                handleFieldChange: handleFieldChange,
                width: '19rem',
                error: formError?.msaSignedDate
            },
            {
                label: 'MSA Expiry Date',
                name: 'msaExpiryDate',
                required: true,
                type: 'date',
                value: formValues?.msaExpiryDate || '',
                handleFieldChange: handleFieldChange,
                width: '19rem',
                error: formError?.msaExpiryDate
            }
        ]?.filter(Boolean),
        buttons: [
            {
                label: 'submit',
                onClick: handleFormSubmit
            },
            {
                label: 'Cancel',
                onClick: handleClose,
                color: 'error'
            }
        ],
        loading: loading,
        fieldGap: '0px',
        margin: '0px',
        padding: '0rem 1rem',
        fieldAlignment: 'center',
        allowOverflow: 'auto'
    }

    return (
        <>
            <CustomModal
                open={isModelOpen}
                onClose={handleCancel}
            >
                <div className='add-duty-modal'>
                    <span className='title'>{selectedRate ? 'Update MSA': 'Add MSA'}</span>
                    <CustomForm {...formProps} />
                </div>
            </CustomModal>
        </>

    )

}