import React, { useState } from 'react';
import styled from 'styled-components';
import { StyledLabel, ErrorMessage, FieldWrapper } from './SelectField'
import StarIcon from '@mui/icons-material/Star';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const StyledTextFieldContainer = styled.div`
  position: relative;
  width: ${(props) => props.width || "14rem"};
`;

const TogglePasswordButton = styled.button`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  color: #888888;
`;

const StyledTextField = styled.input`
  width: ${props => props.width || '14rem'};
  height: 40px;
  border: 1px solid #E4E4E4;
  border-radius: 7px;
  padding: 10px;
  color: ${props => props.color || '#000000'};
  cursor: pointer;
  font-size: 14px;
  font-family: 'Degular', sans-serif;

  &::placeholder {
    color: #999;
    font-size: 14px;
  }

  &:focus {
    outline: none;
    border-color: #aaa;
    box-shadow: none;
    cursor: pointer;
  }
  ${props => props.disabled && `
  background-color: hsl(0, 0%, 95%);
  color: hsl(0, 0%, 60%)

   &[type="date"] {
    color: black;
    
    &::-webkit-datetime-edit-year-field,
    &::-webkit-datetime-edit-month-field,
    &::-webkit-datetime-edit-day-field {
      color: black;
    }
  }
`}
  
`;

export const iconStyle = (color, fontSize, cursor) => {
  return { color: color, fontSize: fontSize, verticalAlign: 'middle', marginLeft: '5px', cursor: cursor }
}

export const TextField = ({ label, dataTestId, lableColor, error, handleFieldChange, placeholder, onKeyDown, required, width, info, type, ...rest }) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


  const disableDate = (event) => {
    if (event.target.type === 'date') {
      event.preventDefault(); // Prevent the default action of the event
      return false; // Return false to prevent typing
    }
  };

  return (
    <FieldWrapper width={width}>
      <StyledLabel lablecolor={lableColor} data-testid={`label-${dataTestId || rest?.name}`}>{label}
        {required && <StarIcon style={iconStyle('red', '8px', 'none')} />}
        {info && (
          <Tooltip title={info} placement="top">
            <InfoIcon style={iconStyle('blue', '16px', 'pointer')} />
          </Tooltip>
        )}
      </StyledLabel>

      <StyledTextFieldContainer width={width}>
        <StyledTextField data-testid={`input-${dataTestId || rest?.name}`} onKeyDown={onKeyDown || disableDate}
          type={type !== 'password' ? (type || 'text') : (showPassword ? 'text' : 'password')}
          onChange={handleFieldChange} placeholder={placeholder} {...rest} width={width} />
        {type === "password" && (
          <TogglePasswordButton
            type="button"
            onClick={handleTogglePasswordVisibility}
            aria-label="toggle password visibility">
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </TogglePasswordButton>

        )}
      </StyledTextFieldContainer>
      {error && <ErrorMessage width={width}>{error}</ErrorMessage>}
    </FieldWrapper>

  );
};