import React from "react";
import { useDrag } from "react-dnd";
import { ItemTypes } from "./ItemTypes";
import { BoxLabel } from "./RollerCageContainer";
import { styled } from "styled-components";
import { getToteColor } from 'utils/warehouseOperationHelper'
import Tooltip from "@mui/material/Tooltip";

const SmallBoxContainer = styled.div`
background-color: ${({ status }) => getToteColor(status)};
margin: 2px;
display: flex;
align-items: center;
justify-content: center;
padding: 5px;
font-family: "degular", sans-serif;
letter-spacing: 0px;
font-size: 10px;
font-weight: 700;
`

const Tote = ({ tote, currentCage }) => {
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.TOTE,
    item: { toteNumber: tote?.toteNumber, currentCage, type: ItemTypes.TOTE },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  return (
    <Tooltip title={`${tote?.totalPackages} Packages`}>
      <SmallBoxContainer key={tote?.toteNumber} ref={drag} status={tote?.toteStatus} data-test-id={`tote-${tote?.toteNumber}`} style={{ opacity: isDragging ? 0.5 : 1 }}>
        <BoxLabel>{tote?.toteNumber}</BoxLabel>
      </SmallBoxContainer>
    </Tooltip>
  );
}

export default Tote;
