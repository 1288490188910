import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';


// Function to register service worker and ensure it's ready
const registerServiceWorker = async () => {
  if ("serviceWorker" in navigator) {
    try {
      const registration = await navigator.serviceWorker.register("/firebase-messaging-sw.js");
      console.log("Service Worker Registered:", registration);
      await navigator.serviceWorker.ready;
      console.log("Service Worker Ready");
      return registration;
    } catch (error) {
      console.error("Service Worker Registration Failed:", error);
    }
  }
  return null;
};

const root = ReactDOM.createRoot(document.getElementById('root'));

// Wait for service worker before rendering app
registerServiceWorker().then(() => {
  root.render(
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  );

});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

